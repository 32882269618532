<template>
  <div class="search-form">

    <search-form-header-component
        :search-category="searchCategory"
        :search-button-text="searchButtonText"
        @switcher-state-changed="filterSwitcherIsActive = $event"
        @search="handleSearchClick"
        @filters-changed="handleTopBlockFiltersChange"
    ></search-form-header-component>

    <search-form-header-bottom-component
        show-clear-filters-button
        show-search-button
        :search-category="searchCategory"
        :search-button-text="searchButtonText"
        :filter-switcher-is-active="filterSwitcherIsActive"
        class="search-header-block-bottom"
        @search="handleSearchClick"
        @filters-changed="handleBottomBlockFiltersChange"
    ></search-form-header-bottom-component>

    <search-form-content-component
        :search-category="searchCategory"
        :search="search"
        :filters-data="filtersData"
        @search-finished="search = false"
    ></search-form-content-component>

  </div>
</template>

<script>


import SearchFormHeaderComponent from '@/components/forms/search/SearchFormHeaderComponent.vue'
import SearchFormHeaderBottomComponent from '@/components/forms/search/SearchFormHeaderBottomComponent.vue'
import SearchFormContentComponent from '@/components/forms/search/SearchFormContentComponent.vue'
import {ref} from 'vue'

export default {
  name: 'SearchFormComponent',
  props: {
    searchCategory: String,
    searchButtonText: String
  },
  components: {
    SearchFormContentComponent,
    SearchFormHeaderBottomComponent,
    SearchFormHeaderComponent
  },
  setup() {
    const search = ref(false)
    const filtersData = ref({
      topBlockFilters: null,
      bottomBlockFilters: null
    })
    const filterSwitcherIsActive = ref(false)

    const handleSearchClick = () => search.value = true
    const handleTopBlockFiltersChange = data => {
      if (
          (!filtersData.value.topBlockFilters && !filtersData.value.bottomBlockFilters)
          || (!filtersData.value.topBlockFilters && filtersData.value.bottomBlockFilters)
      ) {
        search.value = true
      }
      filtersData.value.topBlockFilters = data
    }
    const handleBottomBlockFiltersChange = data => filtersData.value.bottomBlockFilters = data

    return {
      filterSwitcherIsActive,
      search,
      filtersData,
      handleSearchClick,
      handleTopBlockFiltersChange,
      handleBottomBlockFiltersChange
    }
  },
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.search-form {
  display: grid;
  grid-template-columns: 345px auto;
  column-gap: 30px;
  margin: 0 120px;

  &--sidebar {
    &.search-header-block-bottom {
      grid-auto-rows: max-content;
      margin-top: 0;
      padding: 20px;
      background: #F8F8F8;
      border-radius: 16px;

      .field-wrapper {
        position: relative;
        margin-left: 0 !important;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &.checkbox-wrapper {
          margin: 0 0 40px 0;
          padding: 20px;
          background: $base-white;
          border: 1px solid #EAEAEA;
          border-radius: 6px;
        }

        &.clear-filters-wrapper {
          margin: 0 0 20px 20px !important;
        }

        &.search-button-wrapper {
          margin-bottom: 50px;
        }
      }

      @media screen and (max-width: 1679px) {
        display: none;

        &.active {
          display: grid;

          .field-wrapper {
            position: relative;

            &.checkbox-wrapper {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }

          @media screen and (max-width: 767px) {
            padding: 0 10px;
          }
        }
      }
    }

    @media screen and (max-width: 1679px) {
      grid-column: span 2;
    }
  }

  @media (min-width: 1678px) {
    width: calc(100% - 240px);
  }
}

</style>
