<template>
  <div class="tour-detailed-card--form--body">
    <div class="section first">
      <rating-star
        :rating="hotelRating"
        class="rating"
      ></rating-star>
      <div class="name text-left">{{ hotelName }}</div>
      <div class="location text-left">{{ placement }}</div>
      <div class="main-description flex-row">
        <div class="features flex-column-start">
          <tour-detailed-card-body-conditions
            :info="shortInfo"
          ></tour-detailed-card-body-conditions>
          <div class="routes w-100">
            <div
              v-for="(route, index) in routes"
              :key="index"
              class="route"
            >
              <div class="separator w-100"></div>
              <div class="info">
                <div class="left flex-row-center">
                  <circle-icon
                    :src="require(`@/assets/images/common/airplane-gray.svg`)"
                    :rotate-plus180="index % 2 !== 0"
                    icon-size="22px"
                    background-variant="beige"
                  ></circle-icon>
                  <div class="text-wrapper">
                    <div class="title text-left">{{ route.date }}</div>
                    <div class="note text-left">{{ route.time }}</div>
                  </div>
                </div>
                <div class="center flex-column-start-center">
                  <div class="title text-left">{{ route.direction }}</div>
                  <div class="note text-left">{{ route.flightDuration }}</div>
                </div>
                <div class="right flex-row-center">
                  <img
                    :src="route.companyLogo"
                    :alt="route.companyName"
                  />
                  <div class="text-wrapper">
                    <div class="title text-left">{{ route.companyName }}</div>
                    <div class="note text-left"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="price">
          <div class="text-wrapper flex-row">
            <div class="title text-left">{{ formattedPrice }}</div>
            <div class="note text-left">
              {{ currencyLabel }}<br>за {{ entityPaymentCategory === 'HOTEL' ? 'номер' : 'тур' }}
            </div>
          </div>
          <div class="buttons">
            <div
              v-if="showButtonContinue"
              class="button next cursor-pointer flex-row-center-center"
              @click="onNextClick('usual-payment')"
            >Купить</div>
            <div
              v-if="showButtonContinue"
              class="button tinkoff-button cursor-pointer flex-row-center-center"
              @click="onNextClick('credit-payment')"
            >Купить в кредит</div>
            <div
              v-if="showButtonAddToFavorites"
              class="button add-to-favorites cursor-pointer flex-row-center-center"
              @click="addToFavorites"
            >Добавить в подборку</div>
            <div
              v-if="tourIsFavorite"
              class="button favorite flex-row-center-center"
            >
              <img
                :src="require('@/assets/images/common/check-green.svg')"
                alt="Добавлено в подборку"
              />
              <span>Добавлено в подборку</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section second">
      <tour-detailed-card-body-tabs
        v-if="activeTab !== 'pay'"
        :active-tab="activeTab"
        @changed="onChangeTab"
      ></tour-detailed-card-body-tabs>
      <tour-detailed-card-body-tab-hotel
        v-if="activeTab === 'hotel'"
        :info="hotelInfo"
        class="tab-content"
        @open-feedbacks-tab="activeTab = 'feedback'"
      ></tour-detailed-card-body-tab-hotel>
      <tour-detailed-card-body-tab-photo
        v-if="activeTab === 'photo'"
        :photos="photos"
        class="tab-content"
      ></tour-detailed-card-body-tab-photo>
      <tour-detailed-card-body-tab-feedback
        v-if="activeTab === 'feedback'"
        :feedbacks="feedbacks"
        class="tab-content"
      ></tour-detailed-card-body-tab-feedback>
      <tour-detailed-card-body-tab-map
        v-if="activeTab === 'map'"
        :latitude="coords.latitude"
        :longitude="coords.longitude"
        class="tab-content"
      ></tour-detailed-card-body-tab-map>
      <tour-detailed-card-body-tab-pay
        v-if="activeTab === 'pay'"
        class="tab-content"
        :payment-type="paymentType"
        :amount="price"
        :meal="meal"
        :fuel-charge="fuelCharge"
        :currency="currency"
        :tour-id="tourId"
        :adults-count="shortInfo.countAdult ? parseInt(shortInfo.countAdult) : null"
        :children-count="shortInfo.countChild ? parseInt(shortInfo.countChild) : null"
        :entity-payment-category="entityPaymentCategory"
        :placement="placement"
        @go-back="onClickGoBack"
      ></tour-detailed-card-body-tab-pay>
    </div>
    <component
      v-if="formComponent"
      :is="formComponent"
      :custom-class="'position-absolute'"
      :redirect-to-account-page-after-success-sing-in="false"
      @open-form="openFormEvent"
      @close-form="closeFormEvent"
    ></component>
  </div>
</template>

<script>

import RatingStar from '@/components/RatingStar.vue'
import { computed, ref, watch } from 'vue'
import CircleIcon from '@/components/icon/CircleIcon.vue'
import TourDetailedCardBodyTabs from '@/components/tour/tour-detailed-card/tabs/TourDetailedCardBodyTabs.vue'
import TourDetailedCardBodyTabHotel from '@/components/tour/tour-detailed-card/tabs/hotel/TourDetailedCardBodyTabHotel.vue'
import TourDetailedCardBodyTabPhoto from '@/components/tour/tour-detailed-card/tabs/TourDetailedCardBodyTabPhoto.vue'
import TourDetailedCardBodyTabFeedback from
    '@/components/tour/tour-detailed-card/tabs/TourDetailedCardBodyTabFeedback.vue'
import TourDetailedCardBodyTabMap from
    '@/components/tour/tour-detailed-card/tabs/TourDetailedCardBodyTabMap.vue'
import TourDetailedCardBodyTabPay
  from '@/components/tour/tour-detailed-card/tabs/pay/TourDetailedCardBodyTabPay.vue'
import { formatAmount } from '@/composables/priceFormatter'
import TourDetailedCardBodyConditions from '@/components/tour/tour-detailed-card/TourDetailedCardBodyConditions.vue'
import { favoritesStore } from '@/stores/favoritesStore'
import { authStore } from '@/stores/authStore'
import LoginFormComponent from '@/components/forms/sign-in/LoginFormComponent.vue'
import RegistrationFormComponent from '@/components/forms/sign-in/RegistrationFormComponent.vue'
import { setItemToLocalStorage } from '@/composables/localStorage'
import { commonStore } from '@/stores/commonStore'

export default {
  name: 'TourDetailedCardBody',
  props: {
    logo: String,
    tourId: String,
    hotelRating: Number,
    hotelName: String,
    placement: String,
    price: Number,
    fuelCharge: Number,
    currency: String,
    hotelInfo: Object,
    photos: Array,
    feedbacks: Array,
    shortInfo: Object,
    routes: Array,
    coords: Object,
    isFavorite: {
      type: Boolean,
      default: false
    },
    entityPaymentCategory: String,
    meal: String,
  },
  components: {
    TourDetailedCardBodyConditions,
    TourDetailedCardBodyTabPay,
    TourDetailedCardBodyTabHotel,
    TourDetailedCardBodyTabFeedback,
    TourDetailedCardBodyTabMap,
    TourDetailedCardBodyTabPhoto,
    TourDetailedCardBodyTabs,
    CircleIcon,
    RatingStar,
    LoginFormComponent,
    RegistrationFormComponent
  },
  setup(props) {
    const activeTab = ref('hotel')
    const formattedPrice = computed(() => formatAmount(props.price))
    const currencyLabel = computed(() => {
      let label = null
      switch (props.currency) {
        case 'RUB':
          label = 'руб'
          break;
      }
      return label
    })
    const authStoreInstance = authStore()
    const favoritesStoreInstance = favoritesStore()
    const commonStoreInstance = commonStore()
    const tourIsFavorite = ref(!!props.isFavorite)
    const userIsAuthorized = computed(() => authStoreInstance.userIsAuthorized)
    const formClosed = computed(() => authStoreInstance.formIsClosed)
    const formComponent = ref(null)
    const showButtonAddToFavorites = computed(
      () => userIsAuthorized.value && props.tourId && !tourIsFavorite.value
    )
    const showButtonContinue = ref(true)
    const paymentType = ref(null)

    const onChangeTab = type => activeTab.value = type
    const onNextClick = type => {
      paymentType.value = type
      if (userIsAuthorized.value) {
        goToPayTab()
      } else {
        const currentDate = new Date()
        setItemToLocalStorage('payment-entity', JSON.stringify({
          logo: props.logo,
          tourId: props.tourId,
          entityPaymentCategory: props.entityPaymentCategory,
          time: currentDate.getTime()
        }))
        formComponent.value = 'LoginFormComponent'
        authStoreInstance.changeFormClosedState(true)
      }
    }
    const goToPayTab = () => {
      activeTab.value = 'pay'
      showButtonContinue.value = false
    }
    const onClickGoBack = () => {
      activeTab.value = 'hotel'
      showButtonContinue.value = true
    }
    const addToFavorites = () => {
      commonStoreInstance.changeLoaderOverlayVisibility(true)
      favoritesStoreInstance.addToFavorites(props.tourId)
        .then(() => tourIsFavorite.value = true)
        .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
    }
    const openFormEvent = value =>
      formComponent.value = value === 'login' ? 'LoginFormComponent' : 'RegistrationFormComponent'
    const closeFormEvent = () => {
      authStoreInstance.changeFormClosedState(false)
      if (userIsAuthorized.value) {
        goToPayTab()
      }
    }

    watch(() => props.isFavorite, () => tourIsFavorite.value = props.isFavorite)
    watch(formClosed, () => formComponent.value = formClosed.value ? null : formComponent.value)

    return {
      activeTab,
      formattedPrice,
      currencyLabel,
      tourIsFavorite,
      showButtonAddToFavorites,
      showButtonContinue,
      formComponent,
      paymentType,

      onChangeTab,
      onNextClick,
      onClickGoBack,
      addToFavorites,
      openFormEvent,
      closeFormEvent
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tour-detailed-card--form--body {
  background: #F5F5F5;
  overflow-x: unset;
  overflow-y: auto;
  max-height: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  .section {
    padding: 30px;

    &.first {
      .rating {
        margin-bottom: 20px;
      }

      .name {
        font-family: GilroyBold;
        font-size: 44px;
        line-height: 44px;
        margin-bottom: 10px;
        color: $base-black;
      }

      .location {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 21px;
        color: #888888;
        margin-bottom: 20px;
      }

      .main-description {
        border-radius: 16px;

        .features {
          background: $base-white;
          width: calc(100% - 60px);
          padding: 30px;

          .routes {
            .route {
              &:not(:first-child) {
                margin-top: 30px;
              }

              .separator {
                background-image: url('@/assets/images/common/dashed-line.svg');
                height: 1px;
                margin-bottom: 30px;
              }

              .info {
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .left {
                  .text-wrapper {
                    margin-left: 10px;

                    .title {
                      font-family: GilroySemibold;
                      font-size: 16px;
                      line-height: 20px;
                      color: $base-black;
                      margin-bottom: 3px;
                    }

                    .note {
                      font-family: GilroyMedium;
                      font-size: 12px;
                      line-height: 15px;
                      color: #888888;
                    }
                  }
                }

                .center {
                  color: #888888;

                  .title {
                    font-family: GilroyMedium;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 4px;
                  }

                  .note {
                    font-family: GilroyRegular;
                    font-size: 14px;
                    line-height: 17px;
                  }
                }

                .right {
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                  }

                  .text-wrapper {
                    margin-left: 10px;
                    color: #888888;

                    .title {
                      font-family: GilroyMedium;
                      font-size: 16px;
                      line-height: 19px;
                      margin-bottom: 4px;
                    }

                    .note {
                      font-family: GilroyRegular;
                      font-size: 14px;
                      line-height: 17px;
                    }
                  }
                }
              }
            }
          }
        }

        .price {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          width: calc(100% - 60px);
          background: #FFF5EB;
          padding: 34px 30px;
          max-width: 280px;

          .text-wrapper {
            margin-bottom: 20px;

            .title {
              font-family: GilroyBold;
              font-size: 36px;
              line-height: 36px;
              color: $base-black;
            }

            .note {
              min-width: 59px;
              margin-left: 10px;
              font-family: GilroyMedium;
              font-size: 12px;
              line-height: 14px;
              color: #888888;
              text-transform: uppercase;
            }
          }

          .buttons {
            .button {
              max-width: 280px;
              border-radius: 50px;
              font-family: GilroyMedium;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              padding: 15px 0 14px 0;

              &.next {
                background: #FF8500;
                color: $base-white;
                margin-bottom: 15px;
              }

              &.add-to-favorites {
                background: $base-white;
                color: #666666;
              }

              &.favorite {
                cursor: default;
                background: $base-white;
                color: $base-black;

                img {
                  width: 17px;
                  height: 17px;
                  margin-right: 5px;
                }
              }

              &.tinkoff-button {
                border: 0;
                margin-bottom: 15px;
                min-height: unset;
                width: 100%;
                background-color: #ffdd2d;
              }
            }
          }
        }
      }
    }

    &.second {
      background: $base-white;
    }
  }

  @media (max-width: 1679px) {
    .section {
      &.first {
        .main-description {
          flex-direction: column;

          .features {
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 16px;
            width: calc(100% - 40px);

            .routes {
              .route {
                .info {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  row-gap: 20px;

                  >div:nth-child(-1n + 3) {
                    grid-column: span 1;
                  }

                  >div:nth-last-child(1) {
                    grid-row-start: 2;
                    grid-column: 2 / span 1;
                  }
                }
              }
            }
          }
          .price {
            padding: 30px 20px;
            max-width: unset;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 40px);

            .text-wrapper {
              margin: 0;
            }

            .buttons {
              display: flex;

              .button {
                width: 210px;

                &.next {
                  margin: 0;
                }

                &.add-to-favorites, &.favorite {
                  margin: 0 0 0 10px;
                }

                &.favorite {
                  padding: 15px 0 14px;
                }

                &.tinkoff-button {
                  margin-left: 10px;
                  margin-bottom: 0;
                  width: 210px;
                }
              }
            }
          }
        }
      }

      &.second {
        padding: 30px 20px;
      }
    }
  }

  @media (max-width: 1280px) {
    .section {
      &.first {
        .main-description {
          .price {
            flex-direction: column;

            .text-wrapper {
              margin-top: 7px;
              margin-bottom: 30px;
            }

            .buttons {
              flex-direction: column;

              .button {
                &.next {
                  margin: 0 0 10px 0;
                }

                &.add-to-favorites, &.favorite {
                  margin: 0;
                }

                &.tinkoff-button {
                  margin-bottom: 10px;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section {
      &.first {
        padding: 30px 20px 20px 20px;

        .main-description {
          .features {
            .routes {
              .route {
                &:not(:first-child) {
                  margin-top: 20px;
                }

                .separator {
                  margin-bottom: 20px;
                }

                .info {
                  grid-template-columns: auto;
                  row-gap: 15px;

                  >div:nth-child(-1n + 3) {
                    grid-column: unset;
                  }

                  >div:nth-last-child(1) {
                    grid-row-start: unset;
                    grid-column: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

</style>
