<template>
  <div
    class="logo-wrapper flex-row cursor-pointer"
    :class="{
      'burger-active': burgerActive,
      'small-header-active': smallHeader
    }"
    @click="onClickLogo"
  >
    <div class="logo-wrapper__logo"></div>
    <div class="logo-wrapper__separator"></div>
    <div class="logo-wrapper__title flex-row-center">Купи тур онлайн</div>
  </div>
</template>

<script>

import { goToMainPage } from '@/composables/router'

export default {
  name: 'HeaderLogoComponent',
  components: {},
  props: {
    burgerActive: Boolean,
    smallHeader: Boolean
  },
  setup() {
    const onClickLogo = () => goToMainPage()

    return {
      onClickLogo
    }
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.logo-wrapper {
  &__logo {
    height: 50px;
    min-width: 120px;
    background-image: url('@/assets/images/common/logo.svg');
    margin-top: -1px;
  }

  &__separator {
    margin: 0 25px 0 20px;
    width: 1px;
    height: 100%;
    background-color: $base-white;
    opacity: .5;
  }

  &__title {
    width: 109px;
    font-family: GilroyRegular;
    font-size: 14px;
    line-height: 20px;
    color: $base-white;
  }

  &.small-header-active {
    .logo-wrapper__logo {
      background-image: url('@/assets/images/common/logo-black.svg');
    }

    .logo-wrapper__separator {
      background-color: $base-black;
    }

    .logo-wrapper__title {
      color: $base-black;
    }
  }

  @media (max-width: 1679px) {
    &__separator {
      margin: 0 15px;
    }
  }

  @media (max-width: 767px) {
    &.burger-active {
      z-index: 4;

      .logo-wrapper__logo {
        background-image: url('@/assets/images/common/logo-black.svg');
      }
    }

    &__separator, &__title {
      display: none;
    }
  }
}

</style>
