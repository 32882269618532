<template>
  <div class="conditions w-100">
    <div
      v-for="(condition, index) in conditions"
      :key="index"
      class="item flex-row-center"
    >
      <circle-icon
        :src="require(`@/assets/images/common/${condition.icon}`)"
        :icon-size="condition.iconSize"
        background-variant="beige"
      ></circle-icon>
      <div class="text-wrapper">
        <div
          class="title text-left"
          :class="{'selected': condition.selected}"
        >{{ condition.title }}</div>
        <div class="note text-left">{{ condition.note }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref, watch } from 'vue'
import CircleIcon from '@/components/icon/CircleIcon.vue'
import moment from 'moment/moment'
import plural from 'plural-ru'

export default {
  name: 'TourDetailedCardBodyConditions',
  components: {
    CircleIcon
  },
  props: {
    info: Object
  },
  setup(props) {
    const conditions = ref([])

    const loadConditions = () => {
      conditions.value.push({
        title: props.info.departureCountryName ?? null,
        note: props.info.departureCityName ?? null,
        icon: 'point-gray.svg',
        iconSize: '22px'
      })
      conditions.value.push({
        title: props.info.roomType ?? null,
        note: `${plural.noun(props.info.countAdult, '%d взрослый', '%d взрослых', '%d взрослых')}`,
        icon: 'bed-gray.svg',
        selected: true,
        iconSize: '24px'
      })
      if (props.info.countNight) {
        conditions.value.push({
          title: props.info.flyDate ? moment(props.info.flyDate, 'DD.MM.YYYY').format('D MMMM') : null,
          note: `${props.info.countNight} ночей`,
          icon: 'calendar-gray.svg',
          iconSize: '20px'
        })
      }
      conditions.value.push({
        title: 'Услуги',
        note: props.info.services && props.info.services.length ? props.info.services.join(', ') : '-',
        icon: 'layers-gray.svg',
        iconSize: '20px'
      })
      conditions.value.push({
        title: 'Питание',
        note: props.info.meals,
        icon: 'eat-gray.svg',
        iconSize: '22px'
      })
    }

    watch(() => props.info , () => loadConditions())

    return {
      conditions
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tour-detailed-card--form--body {
  .section {
    &.first {
      .main-description {
        .features {
          .conditions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 15px;
            column-gap: 10px;
            margin-bottom: 30px;

            .item {
              .text-wrapper {
                margin-left: 10px;

                .title {
                  font-family: GilroySemibold;
                  font-size: 16px;
                  line-height: 20px;
                  color: $base-black;
                  margin-bottom: 3px;

                  &.selected {
                    color: #FF8500;
                    text-decoration: underline;
                    text-underline-position: under;
                  }
                }

                .note {
                  font-family: GilroyMedium;
                  font-size: 14px;
                  line-height: 17px;
                  color: #888888;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: auto;
    margin-bottom: 20px;

    .section {
      &.first {
        .main-description {
          .features {
            .conditions {
              grid-template-columns: repeat(1, 1fr);
            }
          }
        }
      }
    }
  }
}

</style>
