import { defineStore } from 'pinia'

const moduleName = 'common'

export const commonStore = defineStore(moduleName, {
  state: () => ({
    showLoaderOverlay: false,
    showModalOverlay: false,
    darkModalOverlay: false,
    loaderOverlayText: null,
    shareLinkHash: null,
    phoneMasks: ['+7 (###) ###-##-##'],
  }),
  getters: {
    currentShareLinkHash: (state) => state.shareLinkHash,
    loaderOverlayIsVisible: state => state.showLoaderOverlay,
    modalOverlayIsVisible: (state) => state.showModalOverlay,
    modalOverlayIsDark: (state) => state.darkModalOverlay,
    currentLoaderOverlayText: (state) => state.loaderOverlayText,
    getPhoneMasks: (state) => state.phoneMasks,
  },
  actions: {
    setShareLinkHash(hash) {
      this.shareLinkHash = hash
    },
    changeModalOverlayVisibility(value, darkOverlay = false) {
      this.showModalOverlay = value
      this.darkModalOverlay = darkOverlay && value
    },
    changeLoaderOverlayVisibility(value, showText = false) {
      this.showLoaderOverlay = value
      this.loaderOverlayText = value && showText ? 'Идет поиск... Займет не больше минуты' : null
    },
  },
})
