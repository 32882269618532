<template>
  <div
    class="tour-card"
    :class="{
      'more': !!activeTab
    }"
  >
    <div style="position: relative"
         :class="{
      'block-canceled': isCanceled
    }"
    >
      <img
          class="tour-card--image cursor-pointer"
          :src="photo"
          :alt="name"
          @click="handleClickByTour"
      />
      <div class="tour-card--title-container">
        <div
            class="tour-card--title"
            @click="handleClickByTour"
        >
          <span>{{ name }}</span>
        </div>
        <div class="tour-card--address">{{ address }}</div>
        <rating-star
            class="tour-card--rating"
            :rating="rating ? parseFloat(rating) : 0"
        ></rating-star>
        <img
            v-if="isFavorite"
            :src="require(`@/assets/images/common/trash-${trashHovered ? 'orange' : 'gray'}.svg`)"
            alt="remove"
            class="tour-card--trash"
            @click="handleClickTrash"
            @mouseover="trashHovered = true"
            @mouseout="trashHovered = false"
        />
        <div
            class="tour-card--tags"
            :class="{
            'no-documents': isFavorite
          }"
        >
          <div class="tour-card--tags--rating">{{ rating ? parseFloat(rating).toFixed(1) : 0.0 }}</div>
          <div
              class="tour-card--tags--link"
              :class="{
              'active': activeTab === 'about-hotel'
            }"
              @click="handleTabClick('about-hotel')"
          >
            <span>Об отеле</span>
          </div>
          <div
              class="tour-card--tags--link"
              :class="{
              'active': activeTab === 'feedbacks'
            }"
              @click="handleTabClick('feedbacks')"
          >
            <span>Отзывы</span>
          </div>
          <div
              class="tour-card--tags--link"
              :class="{
              'active': activeTab === 'map'
            }"
              @click="handleTabClick('map')"
          >
            <span>На карте</span>
          </div>
        </div>
        <div
            class="tour-card--tags--link-content"
            :class="{
            'd-none': !activeTab
          }"
        >
          <about-hotel-tab-component
              v-if="activeTab === 'about-hotel'"
              :hotel="hotelInfo"
              is-user-tour
              class="favorites tab-favorites-content"
          ></about-hotel-tab-component>
          <hotel-feedbacks-tab-component
              v-if="activeTab === 'feedbacks'"
              :hotel="hotelInfo"
              class="tab-feedbacks-content"
          ></hotel-feedbacks-tab-component>
          <hotel-map-tab-component
              v-if="activeTab === 'map'"
              :hotel="hotelInfo"
              class="tab-map-content"
          ></hotel-map-tab-component>
        </div>
<!--        <div-->
<!--            v-if="!isFavorite && !activeTab"-->
<!--            class="tour-card&#45;&#45;file flex-row-center"-->
<!--            @click.stop.prevent="handleClickShowDocuments"-->
<!--        >-->
<!--          <img-->
<!--              :src="require('@/assets/images/common/document.svg')"-->
<!--              alt="document"-->
<!--          />-->
<!--          <div-->
<!--              v-if="showDocumentNotice"-->
<!--              class="tour-card&#45;&#45;file&#45;&#45;notice"-->
<!--          >-->
<!--            <span>Документы появятся</span>-->
<!--            <span>в течение 24 часов</span>-->
<!--          </div>-->
<!--          <span-->
<!--              class="tour-card&#45;&#45;file&#45;&#45;text"-->
<!--              @click="downloadDocuments"-->
<!--          >Документы тура</span>-->
<!--        </div>-->
        <preview-card-price
            class="tour-card--price"
            :price="price"
            :currency="currency"
        ></preview-card-price>
      </div>
<!--      <div-->
<!--          v-if="isCanceled"-->
<!--          class="tour-card&#45;&#45;canceled"-->
<!--      >Тур отменен</div>-->
    </div>
    <div class="state-card">
      <template v-if="!isFavorite && !activeTab && !isCanceled && documents.length">
        <img
            style="width: 92px"
            :src="require('@/assets/images/common/document.svg')"
            alt="document"
        />
        <span class="title">
          Ваши документы готовы! Вы можете скачать их
          <span @click="downloadDocuments" style="font-weight: 600; cursor: pointer; text-decoration: underline; color: #FF8500;">
            здесь
          </span>, а так же найти их на вашей
          <span style="font-weight: 600">
          электронной почте
          </span>
        </span>
      </template>
      <template v-else-if="!documents.length">
        <img
            style="width: 92px"
            :src="require('@/assets/images/common/clock-clockwise.svg')"
            alt="document"
        />
        <span class="title">
          Документы тура появятся здесь, а также будут высланы на вашу электронную почту в <span style="font-weight: 600">течении 24 часов</span>
        </span>
      </template>
      <template v-else-if="isCanceled">
        <img
            style="width: 92px"
            :src="require('@/assets/images/common/warning-circle.svg')"
            alt="document"
        />
        <div class="title">
          <div>К сожалению данный тур не актуален.</div>
          Денежные средства возвращены на карту В среднем это занимает 24 часа, но зависит от вашего банка
          <div style="font-size: 14px; font-weight: 600; cursor: pointer; text-decoration: underline; color: #FF8500;" @click="goToMainPage">
            Поиск нового тура
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import RatingStar from '@/components/RatingStar.vue'
import PreviewCardPrice from '@/components/PreviewCardPrice.vue'
import { computed, ref } from 'vue'
import { commonStore } from '@/stores/commonStore'
import { favoritesStore } from '@/stores/favoritesStore'
import { filesStore } from '@/stores/filesStore'
import { saveByteArray } from '@/composables/file'
import { goToSearchTourPage } from '@/composables/router'
import { tourStore } from '@/stores/tourStore'
import AboutHotelTabComponent from '@/components/tour/tabs/AboutHotelTabComponent.vue'
import HotelFeedbacksTabComponent from '@/components/tour/tabs/HotelFeedbacksTabComponent.vue'
import HotelMapTabComponent from '@/components/tour/tabs/HotelMapTabComponent.vue'
import router from "@/router";

export default {
  name: 'TourCard',
  components: {
    HotelMapTabComponent, HotelFeedbacksTabComponent, AboutHotelTabComponent, PreviewCardPrice,
    RatingStar
  },
  emits: ['remove-from-favorites'],
  props: {
    tourId: String,
    name: String,
    photos: Array,
    address: String,
    rating: Number,
    price: Number,
    currency: String,
    isFavorite: Boolean,
    isCanceled: Boolean,
    documents: Array,
    country: Number,
    hotelCode: String
  },
  setup (props, { emit }) {
    const commonStoreInstance = commonStore()
    const trashHovered = ref(false)
    const showDocumentNotice = ref(false)
    const photo = computed(() => props.photos && props.photos.length ? props.photos[0] : null)
    const activeTab = ref(null)
    const hotelInfo = ref(null)

    const handleHideModal = () => {
      commonStoreInstance.changeModalOverlayVisibility(false, true)
    }

    const goToMainPage = () => {
      router.push({name: 'main'})
    }

    const handleClickByTour = () => {
      if (props.country) {
        tourStore().setSelectedCountryIdForSearch(props.country)
        goToSearchTourPage()
      }
    }
    const handleClickTrash = async () => await favoritesStore().removeFromFavorites(props.tourId).then(
      () => emit('remove-from-favorites')
    )
    const handleClickShowDocuments = () => {
      if (!props.documents.length) {
        showDocumentNotice.value = true
        setTimeout(() => showDocumentNotice.value = false, 3000)
      }
    }
    const downloadDocuments = () => {
      if (props.documents.length) {
        commonStoreInstance.changeLoaderOverlayVisibility(true)
        let successCounter = 0
        props.documents.map((id, idx) =>
          filesStore().getFile(id)
            .then(async ({ data }) => {
              await saveByteArray(`File ${idx + 1}`, data)
              successCounter += 1
              if (successCounter === props.documents.length) {
                commonStoreInstance.changeLoaderOverlayVisibility(false)
              }
            })
            .catch(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
        )
      }
    }
    const handleTabClick = tab => {
      if (props.hotelCode) {
        activeTab.value = activeTab.value === tab ? null : tab
        if (activeTab.value && !hotelInfo.value) {
          getHotelInfo()
        }
      }
    }
    const getHotelInfo = async () => {
      const response = await tourStore().searchTourHotel(props.hotelCode).then(({ data }) => data)
      hotelInfo.value = response && response.data && response.data.hotel ? response.data.hotel : null
    }

    return {
      trashHovered,
      showDocumentNotice,
      photo,
      activeTab,
      hotelInfo,
      goToMainPage,
      handleHideModal,
      handleClickByTour,
      handleClickTrash,
      handleClickShowDocuments,
      downloadDocuments,
      handleTabClick
    }
  }
}
</script>

<style scoped lang='scss'>

@import '@/assets/css/variables';

.state-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: white;
}

.title {
  font-family: GilroyMedium;
  font-size: 12px;
  color: #222222;
}

.tour-card {
  min-width: 300px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-sizing: border-box;
  overflow: hidden;
  height: 419px;
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 1px;
  background-color: #e0e0e0;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: max-content;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &--image {
    display: block;
    width: 100%;
    height: 214px;
    object-fit: cover;
  }

  &--title-container {
    position: relative;
    padding: 20px 20px 15px 20px;
    text-align: left;
    background: #ffffff;
    height: 168px;
  }

  &--title {
    font-family: GilroySemibold;
    font-size: 16px;
    color: #222222;
    line-height: 1;
    padding-right: 40px;

    span:hover {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  &--address {
    margin-top: 7px;
    font-family: GilroyMedium;
    font-size: 14px;
    color: #888888;
    line-height: 1;
  }

  &--rating {
    margin-top: 10px;
  }

  &--tags {
    margin-top: 15px;
    margin-bottom: 26px;
    display: flex;
    flex-flow: row nowrap;
    min-width: 271px;

    &.no-documents {
      margin-bottom: 46px;
    }

    &--rating {
      padding: 0 10px;
      font-family: GilroySemibold;
      font-size: 12px;
      color: #ffffff;
      line-height: 28px;
      text-transform: uppercase;
      background: #50d465;
      border-radius: 30px;
    }

    &--link {
      cursor: pointer;
      margin-left: 5px;
      padding: 0 10px;
      display: block;
      font-family: GilroyMedium;
      font-size: 12px;
      color: #666666;
      line-height: 28px;
      text-transform: uppercase;
      text-decoration: none;
      background: #fff5eb;
      border-radius: 30px;

      &.active {
        background: #777777;
        color: $base-white;
      }
    }

    &--link-content {
      margin-bottom: 40px;

      .tab-feedbacks-content {
        margin-right: 0;
        margin-left: 0;
      }

      .tab-map-content {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &--file {

    &--text {
      margin-left: 4px;
      font-family: GilroySemibold;
      font-size: 14px;
      line-height: 14px;
      color: $base-black;

      &:hover {
        color: $base-orange;
        cursor: pointer;
      }
    }

    &--notice {
      top: -55px;
      position: absolute !important;
      padding: 17px 21px 16px 15px;
      border-radius: 6px;
      background: $base-white;
      box-shadow: 0 0 16px 0 #0000001f;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: GilroyRegular;
        font-size: 13px;
        line-height: 13px;
        color: $base-black;

        &:last-child {
          margin-left: 5px;
          font-family: GilroySemibold;
          font-size: 13px;
          line-height: 13px;
        }
      }
    }
  }

  &--trash {
    position: absolute !important;
    cursor: pointer;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 1679px) {

    &--title {
      padding-right: 35px;
    }

    &--trash {
      right: 15px;
    }
  }
}

.tour-card.more {
  height: fit-content;

  .tour-card--title-container {
    height: fit-content;
  }

  .tour-card--tags {
    &.no-documents {
      margin-bottom: 15px;
    }
  }
}

.tour-card.canceled {
  &:after {
    content: '';
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    opacity: .6;
    z-index: 11;
  }

  .tour-card--file {
    visibility: hidden !important;
  }

  .tour-card--canceled {
    position: absolute !important;
    left: 20px;
    bottom: 15px;
    font-family: GilroyMedium;
    color: #ff4800;
    font-size: 14px;
    z-index: 12;
  }
}

.block-canceled {
  &:after {
    content: '';
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    opacity: .6;
    z-index: 11;
  }
}
</style>
