<template>
  <div class="share-link-page"></div>
</template>

<script>

import { onMounted } from 'vue'
import router from '@/router'
import { commonStore } from '@/stores/commonStore'
import { goToMainPage } from '@/composables/router'

export default {
  name: 'SharePage',
  setup() {

    const processShareLink = () => {
      let hash = null
      if (router.currentRoute.value.query) {
        hash = router.currentRoute.value.query.hash
      }
      if (hash) {
        commonStore().setShareLinkHash(hash)
      }
      router.replace({query: null})
      goToMainPage()
    }

    onMounted(() => processShareLink())
  }
}

</script>
