<template>
  <div class="preview-image w-100">
    <viewer :images="[photoSrc]">
      <img
        :src="photoSrc"
        alt="preview-image"
        class="cursor-pointer"
      />
    </viewer>
    <circle-icon
      :src="prevImgSrc"
      icon-size="18px"
      background-variant="white"
      rotate-plus90
      class="button prev"
      @click="onPrevImageClick"
      @mouseover="setActivePrevImgSrc"
      @mouseout="setDefaultPrevImgSrc"
    ></circle-icon>
    <circle-icon
      :src="nextImgSrc"
      icon-size="18px"
      rotate-minus90
      background-variant="white"
      class="button next"
      @click="onNextImageClick"
      @mouseover="setActiveNextImgSrc"
      @mouseout="setDefaultNextImgSrc"
    ></circle-icon>
    <div class="image-counter-wrapper">
      <span class="current">{{ photoIndex + 1 }}</span>
      <span>&nbsp;/&nbsp;</span>
      <span class="total">{{ photosCount }}</span>
    </div>
  </div>
</template>

<script>

import CircleIcon from '@/components/icon/CircleIcon.vue'
import { ref } from 'vue'

export default {
  name: 'TourDetailedCardBodyTabHotelPreviewImage',
  components: {
    CircleIcon
  },
  emits: ['next-image-click', 'prev-image-click'],
  props: {
    photoSrc: String,
    photoIndex: Number,
    photosCount: Number
  },
  setup(props, { emit }) {
    const activeImgName = 'arrow-down.svg'
    const defaultImgName = 'arrow-down-gray.svg'
    const prevImgSrc = ref(require(`@/assets/images/common/${defaultImgName}`))
    const nextImgSrc = ref(require(`@/assets/images/common/${defaultImgName}`))

    const setActivePrevImgSrc = () => prevImgSrc.value = getActiveImgSrc()
    const setActiveNextImgSrc = () => nextImgSrc.value = getActiveImgSrc()
    const setDefaultPrevImgSrc = () => prevImgSrc.value = getDefaultImgSrc()
    const setDefaultNextImgSrc = () => nextImgSrc.value = getDefaultImgSrc()
    const getDefaultImgSrc = () => require(`@/assets/images/common/${defaultImgName}`)
    const getActiveImgSrc = () => require(`@/assets/images/common/${activeImgName}`)
    const onPrevImageClick = () => emit('prev-image-click')
    const onNextImageClick = () => emit('next-image-click')

    return {
      prevImgSrc,
      nextImgSrc,

      setActivePrevImgSrc,
      setActiveNextImgSrc,
      setDefaultPrevImgSrc,
      setDefaultNextImgSrc,
      onPrevImageClick,
      onNextImageClick,
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tab-content.hotel {
  .preview-image {
    height: 550px;
    width: 100%;

    img {
      border-radius: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 550px;
      width: 100%;
      object-fit: cover;
    }

    .button {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;

      &.prev {
        left: 30px;
      }

      &.next {
        right: 30px;
      }
    }

    .image-counter-wrapper {
      position: absolute !important;
      padding: 16px 23px 14px 24px;
      background: #00000080;
      border-radius: 6px;
      bottom: 20px;
      right: 30px;

      span {
        cursor: default;
        color: $base-white;
        font-family: GilroyRegular;
        font-size: 16px;
        line-height: 16px;

        &.current {
          font-family: GilroyMedium;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }

    @media (max-width: 1679px) {
      grid-row-start: 2;
      width: 100%;

      img {
        width: 100%;
        min-height: 550px;
      }
    }

    @media (max-width: 767px) {
      height: 243px;

      img {
        height: 243px;
        min-height: 243px;
      }

      .button {
        width: 40px !important;
        height: 40px !important;
        min-width: 40px !important;

        &.prev {
          left: 20px;
        }

        &.next {
          right: 20px;
        }
      }

      .image-counter-wrapper {
        padding: 12px 19px 10px 20px;
        bottom: 10px;
        right: 20px;

        span {
          font-size: 12px;
          line-height: 12px;

          &.current {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

</style>
