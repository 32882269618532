<template>
  <div class="choose-block">
    <h4>Туристы</h4>

    <div class="choose-block--passengers">
      <template
          v-for="(value, key) in items"
          :key="key"
      >
        <template v-if="key === '0'">
          <div class="choose-block--passengers--item">
            <span>{{ value }}</span>
            {{ plural.noun(value, 'взрослый', 'взрослых') }}

            <div
                class="choose-block--passengers--item--low"
                @click="handleClickAdultLow"
            ></div>
            <div
                class="choose-block--passengers--item--high"
                @click="handleClickAdultHigh"
            ></div>
          </div>
        </template>
        <template v-else>
          <div
              v-for="i in value"
              :key="i"
              class="choose-block--passengers--item"
          >
            Ребенок
            <span v-if="key === '1'">до 2 лет</span>
            <span v-else>{{ plural.noun(key, '%d год', '%d года', '%d лет') }}</span>

            <div
                class="choose-block--passengers--item--low"
                @click="handleClickChildRemove(key)"
            ></div>
          </div>
        </template>
      </template>

      <div
          v-show="!visibleChildTable && itemsLength < 4"
          class="choose-block--passengers--item item--add"
          @click="handleClickShowChildTable"
      >Добавить ребенка
      </div>

      <div
          v-show="visibleChildTable"
          class="choose-block--passengers--child"
      >
        <h6>Выберите возраст ребенка</h6>

        <div class="choose-block--passengers--child--table">
          <div
              v-for="year in 15"
              :key="year"
              class="choose-block--passengers--child--table--item"
              @click="handleClickChildAdd(year)"
          >
            <template v-if="year === 1">
              <span>до 2</span>
              лет
            </template>
            <template v-else>
              <span>{{ year }}</span>
              {{ plural.noun(year, 'год', 'года', 'лет') }}
            </template>
          </div>
        </div>
      </div>

      <div
          v-if="!visibleChildTable"
          class="choose-block--passengers--footer"
      >
        <div class="field-wrapper">
          <input
              type="checkbox"
              id="saveResult"
              v-model="saveResult"
          />
          <label for="saveResult">Запомнить выбор</label>
        </div>

        <button
            :class="{ 'active': isChanged }"
            @click="handleClickChoose"
        >
          Выбрать
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import plural from 'plural-ru'

export default {
  name: 'MainPageSearchBlockPassengers',
  props: {
    passengers: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const items = ref(Object.assign({}, props.passengers))
    const isChanged = ref(false)
    const visibleChildTable = ref(false)
    const saveResult = ref(false)
    const itemsLength = computed(() => Object.keys(items.value).length)

    const handleClickAdultHigh = () => {
      items.value[0]++
      isChanged.value = true
    }

    const handleClickAdultLow = () => {
      if (items.value[0] > 1) {
        items.value[0]--
        isChanged.value = true
      }
    }

    const handleClickChildAdd = (key) => {
      visibleChildTable.value = false

      if (items.value[key] !== undefined) {
        items.value[key]++
      } else {
        items.value[key] = 1
      }

      isChanged.value = true
    }

    const handleClickChildRemove = (key) => {
      if (items.value[key] > 1) {
        items.value[key]--
      } else {
        delete items.value[key]
      }

      isChanged.value = true
    }

    const handleClickShowChildTable = () => {
      visibleChildTable.value = true
    }

    const handleClickChoose = () => {
      emit('change-passengers', items.value)
    }

    return {
      plural,
      items,
      isChanged,
      visibleChildTable,
      saveResult,
      itemsLength,
      handleClickAdultHigh,
      handleClickAdultLow,
      handleClickChildAdd,
      handleClickChildRemove,
      handleClickShowChildTable,
      handleClickChoose,
    }
  },
}
</script>

<style scoped lang="scss">
.field-wrapper {
  position: relative;
  margin: 30px 0;
  text-align: center;
}

input[type="checkbox"] {
  display: none;

  + label {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: GilroyRegular;
    font-size: 14px;
    color: #888888;
    line-height: 1;
    cursor: pointer;

    &:before {
      content: '';
      margin-right: 10px;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }

  &:checked {
    + label {
      &:after {
        content: '';
        position: absolute !important;
        left: 4px;
        display: block;
        width: 12px;
        height: 10px;
        background: url('@/assets/images/common/checked.svg');
      }
    }
  }
}

.choose-block {
  position: absolute !important;
  top: 75px;
  right: -20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  @media screen and (max-width: 767px) {
    top: 70px;
    left: 0;
    right: unset;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (max-width: 380px) {
    width: calc(100% - 30px);
  }

  h4 {
    margin: 10px 0 25px;
    text-align: left;
    font-family: GilroyBold;
    font-size: 16px;
    font-weight: normal;
    color: #222222;
    line-height: 1;
    text-transform: uppercase;
  }

  &--passengers {
    &--item {
      position: relative;
      margin-top: 20px;
      padding: 13px 0;
      width: 344px;
      text-align: center;
      font-family: GilroyRegular;
      font-size: 14px;
      color: #222222;
      line-height: 1;
      background: #f5f5f5;
      border-radius: 40px;

      @media screen and (max-width: 767px) {
        width: 300px;
      }

      @media screen and (max-width: 380px) {
        width: 100%;
      }

      span {
        font-family: GilroyBold;
      }

      &--low, &--high {
        content: '';
        position: absolute !important;
        top: 0;
        width: 60px;
        height: 40px;
        cursor: pointer;
      }

      &--low {
        left: 0;
        background-image: url('@/assets/images/common/small-button-low.svg');
      }

      &--high {
        right: 0;
        background-image: url('@/assets/images/common/small-button-high.svg');
      }

      &.item--add {
        cursor: pointer;
      }
    }

    &--child {
      h6 {
        margin: 34px 0 32px;
        text-align: center;
        font-family: GilroyRegular;
        font-size: 14px;
        font-weight: normal;
        color: #888888;
        line-height: 1;
      }

      &--table {
        display: grid;
        grid-template-columns: repeat(3, 110px);
        grid-template-rows: repeat(5, 40px);
        grid-auto-flow: column;
        grid-gap: 7px;

        @media screen and (max-width: 767px) {
          grid-template-columns: repeat(3, 96px);
          grid-gap: 6px;
        }

        &--item {
          font-family: GilroyRegular;
          font-size: 14px;
          color: #222222;
          line-height: 40px;
          background: #f5f5f5;
          border-radius: 40px;
          cursor: pointer;

          span {
            font-family: GilroyBold;
          }
        }
      }
    }

    &--footer {
      button {
        margin: 0 auto 10px;
        padding: 0;
        display: block;
        width: 200px;
        text-align: center;
        font-family: GilroyRegular;
        font-size: 14px;
        color: #222222;
        line-height: 40px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 40px;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
          color: #ffffff;
          background: linear-gradient(225deg, #FF8500 0%, #FF4800 100%);
          border-color: transparent;
        }
      }
    }
  }
}
</style>
