<template>
  <div
    class="contact-us flex-row-center-space-between cursor-pointer"
    :class="{
      'burger-active': burgerActive,
      'small-header-active': smallHeader,
    }"
    @click.stop.prevent="active = !active"
    v-click-outside="hide"
  >
    <div
      class="image flex-row-center-center"
      :class="{
        'active': active
      }"
    >
      <div class="image flex-row-center-center">
        <div class="image"></div>
      </div>
      <div
        v-if="active"
        class="buttons flex-row"
      >
        <circle-icon
          v-if="!isDesktop"
          size="36px"
          icon-size="20px"
          :src="require('@/assets/images/common/phone-orange-bordered.svg')"
          class="phone button"
          @click.stop.prevent="onPhoneClick"
        ></circle-icon>
        <template v-else>
          <div class="phone-desktop">
            <img :src="require('@/assets/images/common/phone-orange-bordered.svg')">
            <div style="color: #FF8500; cursor: pointer; font-weight: 600; white-space: nowrap">8-(800)-777-01-13</div>
          </div>
        </template>
        <circle-icon
          size="36px"
          icon-size="22px"
          :src="require('@/assets/images/common/tg-orange-bordered.svg')"
          class="tg button"
          @click.stop.prevent="onTelegramClick"
        ></circle-icon>
      </div>
    </div>
    <div class="text">Связаться с нами</div>
  </div>
</template>

<script>


import {computed, ref} from 'vue'
import CircleIcon from '@/components/icon/CircleIcon.vue'
import { getTelegram } from '@/composables/contacts'
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'HeaderContactUsComponent',
  components: {
    CircleIcon
  },
  props: {
    burgerActive: Boolean,
    smallHeader: Boolean
  },
  setup() {
    const active = ref(false)
    const isDesktop = computed(() => window.innerWidth >= 768)
    const onPhoneClick = () => location.href = 'tel:88007770113'
    const onTelegramClick = () => window.open(getTelegram(), '_blank')
    const hide = () => active.value = false

    return {
      active,
      onPhoneClick,
      onTelegramClick,
      hide,
      isDesktop
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.phone-desktop {
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.6980392157);
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  margin-right: 8px;
}

.contact-us {
  width: 181px;
  height: 50px;

  .image {
    width: 50px;
    height: 50px;
    background-image: url('@/assets/images/common/ellipse-11.svg');

    .image {
      width: 40px;
      height: 40px;
      background-image: url('@/assets/images/common/ellipse-10.svg');

      .image {
        width: 20px;
        height: 20px;
        background-image: url('@/assets/images/common/call-center.svg');
      }
    }

    .buttons {
      position: absolute !important;
      top: 56px;

      .button {
        background-color: #FFFFFFB2;

        &.phone {
          margin-right: 10px;
        }
      }
    }

    &.active {
      background-image: url('@/assets/images/common/ellipse-11-white-opacity-50.svg');

      .image {
        background-image: url('@/assets/images/common/ellipse-11-white-opacity-50.svg');
        background-size: cover;

        .image {
          background-size: cover;
          width: 13px;
          height: 13px;
          background-image: url('@/assets/images/common/close-orange.svg');
        }
      }
    }
  }

  .text {
    font-family: GilroySemibold;
    font-size: 14px;
    line-height: 17px;
    color: $base-white;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &.small-header-active {
    .text {
      color: $base-black;
    }

    .image {
      background-image: url('@/assets/images/common/ellipse-11-white.svg');

      .image {
        background-image: url('@/assets/images/common/ellipse-light-gray.svg');

        .image {
          background-image: url('@/assets/images/common/call-center-black.svg');
        }
      }

      .buttons {
        .button {
          border: 1.5px solid $base-orange;

          &.phone {
            margin-right: 10px;
          }
        }
      }

      &.active {

        .image {

          .image {
            background-image: url('@/assets/images/common/close-orange.svg');
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    max-width: 50px;

    &.burger-active {
      .image {
        background-image: url('@/assets/images/common/ellipse-11-white.svg');

        .image {
          background-image: url('@/assets/images/common/ellipse-light-gray.svg');

          .image {
            background-image: url('@/assets/images/common/call-center-black.svg');
          }
        }
      }
    }
  }
}

</style>
