<template>
  <div class="menu panel flex-column-start">
    <personal-account-page-menu-item
      v-for="item in items"
      :key="item.type"
      :type="item.type"
      :label="item.label"
      :active="item.active"
      @click="onItemClick(item)"
    ></personal-account-page-menu-item>
  </div>
</template>

<script>

import {computed, onUnmounted, ref} from 'vue'
import { accountPageStore } from '@/stores/accountPageStore'

export default {
  name: 'PersonalAccountPageMenu',
  props: {
    selectedItemType: String
  },
  setup(props) {
    const activeItemType = ref(props.selectedItemType)
    const hoveredItemType = ref(null)
    const items = computed(() => accountPageStore().accountMenu)

    const onItemClick = item => accountPageStore().selectMenuItem(item)

    onUnmounted(() => accountPageStore().selectMenuItem(
      items.value.find(m => m.type === 'profile'), false)
    )

    return {
      items,
      activeItemType,
      hoveredItemType,

      onItemClick
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.panel.menu {
  width: 340px;
  height: 396px;
  margin-right: 50px;
  padding-top: 20px;

  @media (max-width: 1679px) {
    height: 240px;
    width: 100%;
    margin-right: 0;
    padding-top: 0;
    background: unset !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    height: 435px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: unset;
  }
}

</style>
