<template>
  <div class="field w-100">
    <span
        v-if="customLabel"
        class="custom-label"
    >
      {{ customLabel }}
      <span
          v-if="required"
          class="required"
      >*</span>
    </span>
    <input
        v-if="type === 'text'"
        type="text"
        :autocomplete="`field-${index}`"
        v-maska
        :data-maska="dataMaska"
        :name="`field-${index}`"
        :placeholder="required && (placeholder && !customLabel) ? `${placeholder}&nbsp;*` : placeholder"
        :value="value"
        :disabled="disabled"
        @input="changeFieldValue($event.target.value)"
        @keydown="onKeyDownField"
        @blur="onBlurField"
        @keyup="onKeyUpField($event.target.value)"
        :class="isInvalid ? 'invalid' : null"
        :data-key="fieldKey"
    >
    <div
        v-if="fieldKey === 'promocode' && !isEmpty"
        class="apply-link"
        @click="$emit('click', fieldKey)"
    >
      Применить
    </div>
    <input
        v-if="type === 'phone'"
        type="text"
        :autocomplete="`field-${index}`"
        :name="`field-${index}`"
        :placeholder="required && (placeholder && !customLabel) ? `${placeholder}&nbsp;*` : placeholder"
        :value="value"
        :disabled="disabled"
        @input="changeFieldValue($event.target.value)"
        @keydown="onKeyDownField"
        @keyup="onKeyUpField($event.target.value)"
        :class="isInvalid ? 'invalid' : null"
        v-maska
        :data-maska="phoneMasks"
    >
    <input
        v-if="type === 'date'"
        type="date"
        class="date"
        :autocomplete="`field-${index}`"
        :name="`field-${index}`"
        :placeholder="required && (placeholder && !customLabel) ? `${placeholder}&nbsp;*` : placeholder"
        :value="value"
        :disabled="disabled"
        :class="isInvalid ? 'invalid' : null"
        @change="changeFieldValue($event.target.value)"
        @keydown="onKeyDownField"
        @keyup="onKeyUpField($event.target.value)"
    >
    <input
        v-if="type === 'password'"
        :type="showPass ? 'text' : 'password'"
        :autocomplete="`field-${index}`"
        :name="`field-${index}`"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="changeFieldValue($event.target.value)"
        @keydown="onKeyDownField"
        @keyup="onKeyUpField($event.target.value)"
        :class="isInvalid ? 'invalid' : null"
    >
    <span
        v-if="showPasswordButton"
        class="eye absolute"
        :class="{
        'visible': showPass,
        'invisible': !showPass,
        'disabled': disabled,
        'cursor-pointer': !disabled
      }"
        @click="onClickShowPass"
    ></span>
    <template v-if="type === 'checkbox'">
      <div @click="changeFieldValue(!value)">
        <input
            id="checkbox"
            type="checkbox"
            :value="value"
            :checked="value"
            :disabled="disabled"
        >
        <label for="checkbox" class="text-left flex-row-center-center">
          <span>{{ label }}</span>
        </label>
      </div>
    </template>
    <template v-if="type === 'required-fields-notice'">
      <div class="required-fields-notice-wrapper flex-row-start">
        <span>*</span>
        &nbsp;
        <span>{{ label }}</span>
      </div>
    </template>
    <template v-if="fieldKey !== 'promocode'">
      <div
          v-if="errorMessages && errorMessages.length"
          class="error description flex-column-start text-left"
      >
        <span
            v-for="(error, index) in errorMessages"
            :key="index"
        >{{ error }}</span>
      </div>
      <div
          v-else
          class="description flex-column-start text-left"
      >
        <span>{{ description }}</span>
      </div>
    </template>
    <div
        v-if="notice"
        class="notice"
    >{{ notice }}
    </div>
    <div
        v-if="type === 'button'"
        class="form-button"
        :class="{'disabled': disabled}"
        @click="$emit('click', fieldKey)"
    >{{ label }}
    </div>
  </div>
</template>

<script>

import {computed, ref} from 'vue'
import {commonStore} from '@/stores/commonStore'

export default {
  name: 'BaseFormFieldComponent',
  emits: ['update:value', 'keydown', 'blur', 'click'],
  props: {
    fieldKey: String,
    type: String,
    label: String,
    customLabel: String,
    placeholder: String,
    value: {},
    description: String,
    showPasswordButton: Boolean,
    index: String,
    errorMessages: Array,
    required: Boolean,
    disabled: Boolean,
    notice: String,
    dataMaska: Array
  },
  setup(props, {emit}) {
    const showPass = ref(false)
    const isInvalid = computed(() => props.errorMessages && props.errorMessages.length)
    const isEmpty = ref(true)
    const phoneMasks = computed(() => commonStore().getPhoneMasks)

    const changeFieldValue = value => emit('update:value', value)
    const onKeyDownField = () => emit('keydown')
    const onBlurField = () => emit('blur')
    const onKeyUpField = value => isEmpty.value = value === null || value.length === 0
    const onClickShowPass = () => {
      if (!props.disabled) {
        showPass.value = !showPass.value
      }
    }

    return {
      showPass,
      isInvalid,
      isEmpty,
      phoneMasks,

      changeFieldValue,
      onKeyDownField,
      onBlurField,
      onKeyUpField,
      onClickShowPass
    }
  },
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.sign-in-form {
  position: relative;

  .fields-wrapper {
    .field {
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      input {
        width: calc(100% - 15px);
        font-family: 'GilroyRegular';
        line-height: 14px;
        font-size: 14px;
        padding: 0 0 0 15px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid $border-white;
        background: $input-bg-white;
        color: $black-text-color;

        &:disabled {
          background: $base-beige;
        }
      }

      .eye {
        top: 37px;
        right: 16px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;

        &.visible {
          background-image: url('@/assets/images/common/eye-visible.svg');
        }

        &.invisible {
          background-image: url('@/assets/images/common/eye-invisible.svg');
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      .custom-label {
        display: block;
        width: 100%;
        text-align: left;
        color: $base-black;
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 7px;

        .required {
          color: $base-red;
        }
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label {
        float: left;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        font-family: GilroyRegular;
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
      }

      input[type="checkbox"] + label::before {
        float: left;
        content: "";
        vertical-align: -25%;
        height: 18px;
        width: 18px;
        border: 1px solid $border-white;
        border-radius: 3px;
        padding: 0;
        margin-right: 5px;
      }

      input[type="checkbox"]:checked + label::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url('@/assets/images/common/checked.svg');
        background-position: center;
        background-repeat: no-repeat;
        top: 0;
        left: 0;
      }

      .required-fields-notice-wrapper {
        padding: 8px 0 8px 0;
        height: 14px;

        span {
          font-family: GilroyRegular;
          font-size: 12px;
          line-height: 14.4px;
          color: #888888;
        }
      }

      .apply-link {
        position: absolute;
        top: 27px;
        right: 20px;
        color: #FF8500;
        font-size: 16px;
        opacity: .4;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      .notice {
        text-align: left;
        margin-top: 20px;
        font-family: GilroyRegular;
        font-size: 13px;
        line-height: 19.5px;
        color: $base-black;
      }
    }
  }
}

form, .form {
  .field {
    input[type="date"] {
      cursor: text;
    }

    .invalid {
      border: 1px solid $base-red !important;
    }

    .description {
      span {
        margin: 11px 0 0 4px;
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 14px;
        color: #888888;
      }

      &.error {
        span {
          color: $base-red;
        }
      }
    }
  }
}

</style>
