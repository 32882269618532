<template>
  <div class="list--item--preview--more">
    <div class="list--item--preview--more--table">
      <div class="item columns">
        <div class="cell"></div>
        <div class="cell text-left">РЕЙС / ПРОГРАММА</div>
        <div class="cell text-left">ДАТА / НЧ</div>
        <div class="cell text-left">НОМЕР / ПИТАНИЕ</div>
        <div class="cell text-left"></div>
      </div>
      <div class="separator w-100"></div>
      <template v-for="(tour, tourIndex) in tours">
        <search-form-content-list-item-more-item-component
          v-if="tourIndex <= maxTourIndexToShow"
          :key="tourIndex"
          :nights="tour.nights"
          :price="tour.price"
          :tour-id="tour.tourid"
          :tour-name="tour.tourname"
          :adults="tour.adults"
          :fly-date="tour.flydate"
          :room="tour.room"
          :meal-russian="tour.mealrussian"
          :operator-image="tour.operatorImage"
          class="item"
        ></search-form-content-list-item-more-item-component>
      </template>
      <div class="separator w-100"></div>
    </div>
    <div class="list--item--preview--more--buttons flex-row-center-center">
      <div
        v-if="firstTour"
        class="button share flex-row-center-center cursor-pointer"
        @click="handleClickShare"
      >
        <img
          :src="require('@/assets/images/common/share.svg')"
          alt="share-icon"
        />
        <span>Поделиться</span>
      </div>
      <share-modal
        v-if="showShareModal"
        :tour-id="firstTour.tourid.toString()"
        :currency="firstTour.currency"
        :price="firstTour.price"
        :logo="firstTour.operatorImage"
        :entity-payment-category="$router.currentRoute.value.name === 'search-tour' ? 'TOUR' : 'HOTEL'"
        @close="handleHideShareModal"
      ></share-modal>
      <div
        v-if="showMoreToursButton"
        class="button flex-row-center-center cursor-pointer"
        @click="handleClickMoreTours"
      >Показать ещё</div>
<!--      <div class="button flex-row-center-center cursor-pointer">Группировать по номерам</div>-->
    </div>
  </div>
</template>

<script>

import SearchFormContentListItemMoreItemComponent
  from '@/components/forms/search/SearchFormContentListItemMoreItemComponent.vue'
import { ref } from 'vue'
import ShareModal from '@/components/ShareModal.vue'
import { commonStore } from '@/stores/commonStore'

export default {
  name: 'SearchFormContentListItemMoreComponent',
  props: {
    tours: Array,
  },
  components: {
    ShareModal,
    SearchFormContentListItemMoreItemComponent
  },
  setup(props) {
    const step = 10
    const maxTourIndexToShow = ref(step - 1)
    const showMoreToursButton = ref(props.tours.length >= step)
    const showShareModal = ref(false)
    const commonStoreInstance = commonStore()
    const firstTour = ref(props.tours.length ? props.tours[0] : null)

    const handleClickMoreTours = () => {
      maxTourIndexToShow.value += step
      showMoreToursButton.value = props.tours.length > maxTourIndexToShow.value
    }
    const handleClickShare = () => {
      commonStoreInstance.changeModalOverlayVisibility(true, true)
      showShareModal.value = true
    }
    const handleHideShareModal = () => {
      showShareModal.value = false
      commonStoreInstance.changeModalOverlayVisibility(false, true)
    }

    return {
      maxTourIndexToShow,
      showMoreToursButton,
      showShareModal,
      firstTour,

      handleClickMoreTours,
      handleClickShare,
      handleHideShareModal
    }
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.list--item--preview--more {
  margin-top: 30px;
  grid-column: span 2;

  &--table {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .item {
      display: grid;
      grid-template-columns: 144px auto 100px 300px 200px;

      &.columns {
        margin-bottom: 14px;

        .cell {
          font-family: GilroySemibold;
          font-size: 12px;
          line-height: 12px;
          color: $base-black;

          @media screen and (max-width: 1679px) {
            &:last-child {
              display: none;
            }
          }
        }

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      @media screen and (max-width: 1679px) {
        grid-template-columns: 144px auto 100px 300px;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: 144px auto;
        row-gap: 10px;
      }
    }

    .separator {
      height: 1px;
      background: #E0E0E0;
    }
  }

  &--buttons {
    margin-top: 30px;

    .button {
      background: #F5F5F5;
      border-radius: 50px;
      width: 210px;
      font-family: GilroyMedium;
      font-size: 14px;
      line-height: 21px;
      color: $base-black;
      height: 44px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 7px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 767px) {
    grid-column: span 1;
    margin-top: 0;
    padding: 0 20px 10px;
    border: 1px solid #E0E0E0;
    border-top: 0;
    border-bottom: 0;
  }
}

</style>
