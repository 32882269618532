<template>
  <teleport to="#app-overlay">
    <base-sign-in-form-component
      title="Войти в аккаунт"
      form-type="login"
      :form-class="customClass ? `${customClass} login` : 'login'"
      :fields="fields"
      :action-button-text="'Войти'"
      :max-height="'554px'"
      :redirect-to-account-page-after-success-sing-in="redirectToAccountPageAfterSuccessSingIn"
      @open-registration-form="$emit('open-form', 'registration')"
      @open-password-recovery-form="$emit('open-form', 'password-recovery')"
      @close-form="$emit('close-form')"
    ></base-sign-in-form-component>
  </teleport>
</template>

<script>

import BaseSignInFormComponent from '@/components/forms/sign-in/BaseFormComponent.vue'
import { ref } from 'vue'

export default {
  name: 'LoginFormComponent',
  emits: ['open-form', 'close-form'],
  components: {
    BaseSignInFormComponent
  },
  props: {
    redirectToAccountPageAfterSuccessSingIn: {
      type: Boolean,
      default: () => true
    },
    customClass: String
  },
  setup() {
    const fields = ref([
      {
        key: 'email',
        placeholder: 'Укажите электронную почту',
        customLabel: 'Электронная почта',
        type: 'text',
        required: true,
      },
      {
        key: 'password',
        placeholder: 'Введите пароль',
        customLabel: 'Пароль',
        type: 'password',
        showPasswordButton: true,
        required: true,
      },
    ])

    return {
      fields
    }
  },
}
</script>
