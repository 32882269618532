<template>
  <div
    class="rating flex-row"
    :class="{ 'hoverable': hoverable }"
  >
    <div
      v-for="(value, key) in [.5, 1.5, 2.5, 3.5, 4.5]"
      :key="value"
      class="rating--star cursor-pointer"
      :class="{
        'filled': rating >= value,
        'not-filled-white': notFilledWhiteStars
      }"
      :style="{width: starSize, height: starSize}"
      @click="$emit('star-click', key + 1)"
    ></div>
  </div>
</template>

<script>


export default {
  name: 'RatingStar',
  props: {
    rating: {
      type: Number,
      default: () => 0,
      required: false
    },
    hoverable: {
      type: Boolean,
      default: () => false,
      required: false
    },
    starSize: {
      type: String,
      default: () => '16px',
      required: false
    },
    notFilledWhiteStars: Boolean
  }
}
</script>

<style lang='scss' scoped>

.rating {
  &.hoverable:hover {
    .rating--star {
      background-image: url('@/assets/images/common/star-rating-filled.svg');

      &:hover ~ .rating--star {
        background-image: url('@/assets/images/common/star-rating.svg');

        @media screen and (max-width: 1679px) {
          &.not-filled-white {
            background-image: url('@/assets/images/common/star-rating-white.svg');
          }
        }
      }
    }
  }
}
.rating--star {
  background-image: url('@/assets/images/common/star-rating.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.filled {
    background-image: url('@/assets/images/common/star-rating-filled.svg');
  }

  &:not(:last-child) {
    margin-right: 3px;
  }

  @media screen and (max-width: 1679px) {
    &.not-filled-white {
      background-image: url('@/assets/images/common/star-rating-white.svg');
    }
  }
}

</style>
