<template>
  <vue-awesome-paginate
    :total-items="itemsCount"
    :items-per-page="perPageCount"
    :max-pages-shown="2"
    v-model="page"
    prev-button-content=""
    next-button-content=""
    disabled-back-button-class="d-none"
    disabled-next-button-class="d-none"
    :paginate-buttons-class="paginateButtonColor"
    :hide-prev-next-when-ends="true"
    :on-click="$emit('page-changed', page)"
  ></vue-awesome-paginate>
</template>

<script>


import { VueAwesomePaginate } from 'vue-awesome-paginate'
import { ref, watch } from 'vue'

export default {
  name: 'ListPaginator',
  components: {
    VueAwesomePaginate
  },
  emits: ['page-changed'],
  props: {
    paginateButtonColor: {
      required: false,
      type: String,
      default: () => 'white'
    },
    itemsCount: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const page = ref(props.currentPage)
    const perPageCount = ref(props.itemsPerPage)

    watch(() => props.currentPage, () => page.value = props.currentPage)
    watch(() => props.itemsPerPage, () => perPageCount.value = props.itemsPerPage)

    return {
      page,
      perPageCount
    }
  }
}
</script>

<style lang='scss'>

.pagination-container {
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 428px) {
    display: none !important;
  }

  li {
    &:not(:last-child) {
      margin-right: 10px;
    }
    button {
      width: 50px;
      height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 0;
      background-color: unset;
      font-family: GilroyMedium;
      font-size: 16px;
      line-height: 16px;
      color: #888888;
      cursor: pointer;
      &.white {
        background-image: url('@/assets/images/common/ellipse-11-white.svg');
      }
      &.dark-gray {
        background-image: url('@/assets/images/common/ellipse-dark-gray.svg');
      }
      &.active-page {
        background-image: url('@/assets/images/common/ellipse-29-white.svg');
        color: #222222;
      }
      &.ending-breakpoint-button {
        padding-bottom: 9px;
      }
      &.back-button {
        background-image: url('@/assets/images/common/circle-arrow-prev-bold.svg');
      }
      &.next-button {
        background-image: url('@/assets/images/common/circle-arrow-next-bold.svg');
      }
    }
  }
}

</style>
