<template>
  <div
    v-if="!list && !multiple"
    class="choose-block-list-item"
  >
    <input
      type="radio"
      :id="`${id}-any`"
      v-model="value"
      :value="null"
    />
    <label :for="`${id}-any`">
      {{ optionAny }}
    </label>
  </div>

  <template v-if="list">
    <template v-if="multiple">
      <div
        v-for="option in options"
        :key="option.id"
        class="choose-block-list-item text-left"
        :class="{'active': selectedOptions.find(so => so.id === option.id)}"
        @click.prevent.stop="handleOptionSelection(option)"
      >
        <span>{{ option.name }}</span>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(option, idx) in options"
        :key="idx"
        class="choose-block-list-item text-left"
        :class="{'active': value && option.id === value.id}"
        @click.prevent.stop="value = option"
      >
        <span>{{ option.name }}</span>
      </div>
    </template>
  </template>
  <div
    v-else
    v-for="[key, v] of options"
    :key="key"
    class="choose-block-list-item"
  >
    <input
      :type="multiple ? 'checkbox' : 'radio'"
      :id="`${id}-${key}`"
      v-model="value"
      :value="key"
    />
    <label
      :for="`${id}-${key}`"
      v-html="optionValue ? v[optionValue] : v"
    ></label>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
  name: 'MainPageSearchBlockListItems',
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    options: {
      required: true,
    },
    optionValue: {
      type: String,
      default: () => null,
      required: false,
    },
    optionAny: {
      type: String,
      default: () => 'Any',
      required: false,
    },
    list: Boolean
  },
  setup(props, { emit }) {
    const selectedOptions = ref([])
    const handleOptionSelection = option => {
      if (selectedOptions.value.length) {
        const idx = selectedOptions.value.findIndex(so => so.id === option.id)
        if (idx > -1) {
          selectedOptions.value.splice(idx, 1)
        } else {
          selectedOptions.value.push(option)
        }
      } else {
        selectedOptions.value.push(option)
      }
      if (selectedOptions.value.length > 1) {
        selectedOptions.value.sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      emit('update:modelValue', selectedOptions.value)
    }

    onMounted(() => {
      if (props.list && props.multiple && props.modelValue && props.modelValue.length) {
        selectedOptions.value = props.modelValue
      }
    })

    return {
      selectedOptions,

      handleOptionSelection
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
}
</script>

<style scoped lang="scss">

@import '@/assets/css/variables';

input[type="radio"], input[type="checkbox"] {
  display: none;

  + label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      margin-right: 10px;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      box-sizing: border-box;
    }

    :deep(span) {
      font-family: GilroyBold;
    }
  }

  &:checked {
    + label {
      &:after {
        content: '';
        position: absolute !important;
        left: 4px;
        display: block;
        width: 12px;
        height: 10px;
        background: url('@/assets/images/common/checked.svg');
      }
    }
  }
}

.choose-block-list-item {
  margin-top: 10px;

  span {
    font-family: GilroyRegular;
    font-size: 14px;
    line-height: 17px;
    color: $base-black;
  }

  span:hover {
    color: $base-orange;
    text-decoration: underline;
    text-underline-position: under;
  }

  &.active {
    span {
      color: $base-orange;
    }
  }
}
</style>
