import {getItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage} from '@/composables/localStorage'

export const setAuthTokenToLocalStorage = token => {
  setItemToLocalStorage('authToken', token)
}

export const getAuthTokenFromLocalStorage = () => {
  return getItemFromLocalStorage('authToken')
}

export const removeAuthTokenFromLocalStorage = () => {
  removeItemFromLocalStorage('authToken')
}
