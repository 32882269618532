<template>
  <div class="choose-block">
    <h4>Ночей от:</h4>
    <div class="choose-block--nights">
      <div
        v-for="k in 28"
        :key="k"
        class="choose-block--nights--item"
        :class="{
          'in-range': fromNights < k && toNights > k,
          'choosen': fromNights === k || toNights === k,
        }"
        @click="handleSelectCount(k)"
      >
        <span>{{ k }}</span>
        ночей
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'MainPageSearchBlockNights',
  props: {
    from: {
      type: Number,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
  },
  setup (props, { emit }) {
    const fromNights = ref(props.from)
    const toNights = ref(props.to)

    const handleSelectCount = count => {
      if (fromNights.value && toNights.value) {
        if (count === fromNights.value) {
          toNights.value = null
        } else {
          fromNights.value = count
          toNights.value = null
        }
      } else if (fromNights.value && !toNights.value) {
        if (fromNights.value > count) {
          toNights.value = fromNights.value
          fromNights.value = count
        } else {
          toNights.value = count
        }
        emit('change-count', fromNights.value, toNights.value)
      }
    }

    return {
      fromNights,
      toNights,
      handleSelectCount,
    }
  },
}
</script>

<style scoped lang="scss">
.choose-block {
  position: absolute !important;
  top: 75px;
  left: 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  h4 {
    margin: 10px 0 25px;
    text-align: left;
    font-family: GilroyBold;
    font-size: 16px;
    font-weight: normal;
    color: #222222;
    line-height: 1;
    text-transform: uppercase;
  }

  &--nights {
    display: grid;
    grid-template-columns: repeat(7, 45px);
    grid-template-rows: repeat(4, 45px);
    grid-gap: 5px;

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(7, 42px);
      grid-template-rows: repeat(4, 42px);
    }

    &--item {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      text-align: center;
      font-family: GilroyMedium;
      font-size: 10px;
      color: transparent;
      line-height: 1.1;
      text-transform: uppercase;
      background: #fff5eb;
      border-radius: 5px;
      cursor: pointer;

      span {
        font-family: GilroySemibold;
        font-size: 14px;
        color: #222222;
      }

      &.in-range {
        background: #ffeedb;
      }

      &:hover {
        color: #ffffff;
        background: #ff8500;

        span {
          color: #ffffff;
        }
      }

      &.choosen {
        color: #ffffff;
        background: #ff8500;

        span {
          color: #ffffff;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1679px) {
    left: unset;
    right: 0;
  }

  @media screen and (max-width: 767px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media screen and (max-width: 380px) {
    right: 1px;

    &--nights {
      grid-template-columns: repeat(7, 36px);
      grid-template-rows: repeat(4, 36px);

      &--item {
        font-size: 9px;
      }
    }
  }
}
</style>
