<template>
  <div class="flex-column-start">
    <div class="panel-title">Мои туры</div>
    <div
      v-if="items.length"
      class="tours-wrapper"
    >
      <tour-card
        v-for="item in items"
        :key="item.id"
        :tour-id="item.id.toString()"
        :name="item.shortInfo && item.shortInfo.hotelName ? item.shortInfo.hotelName : null"
        :photos="item.hotel && item.hotel.photos ? item.hotel.photos : []"
        :address="item.shortInfo && item.shortInfo.placement ? item.shortInfo.placement : null"
        :rating="item.hotel && item.hotel.rating ? item.hotel.rating : 0"
        :price="item.shortInfo && item.shortInfo.price ? item.shortInfo.price : 0"
        :currency="item.shortInfo && item.shortInfo.currency ? item.shortInfo.currency : null"
        :is-favorite="false"
        :is-canceled="item.isCanceled"
        :documents="item.documents"
        :country="item.shortInfo && item.shortInfo.countryCode ? item.shortInfo.countryCode : null"
        :hotel-code="item.shortInfo && item.shortInfo.hotelCode ? item.shortInfo.hotelCode : null"
      ></tour-card>
    </div>
    <template v-else>
      <div class="no-data">У Вас еще нет туров</div>
      <button
        class="select-tour cursor-pointer"
        @click="goToSearchTourPage()"
      >Выбрать тур</button>
    </template>
  </div>
</template>

<script>

import { onMounted, ref } from 'vue'
import { commonStore } from '@/stores/commonStore'
import { favoritesStore } from '@/stores/favoritesStore'
import TourCard from '@/components/tour/TourCard.vue'
import { goToSearchTourPage } from '@/composables/router'

export default {
  name: 'PersonalAccountPageContentTour',
  methods: {
    goToSearchTourPage
  },
  components: { TourCard },
  setup () {
    const commonStoreInstance = commonStore()
    const favoritesStoreInstance = favoritesStore()
    const items = ref([])

    const getTours = async () => {
      commonStoreInstance.changeLoaderOverlayVisibility(true, true)
      await favoritesStoreInstance.getTours()
        .then(({ data }) => items.value = data.filter(i => !!i))
        .catch(() => items.value = [])
        .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
    }

    onMounted(() => getTours())

    return {
      items,
      getTours,
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.no-data {
  font-family: GilroyRegular;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 30px;
}

button.select-tour {
  border-radius: 6px;
  width: 235px;
  height: 60px;
  font-family: GilroySemibold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  color: $base-white;
  border: unset;
  background: linear-gradient(225deg, #ff8500 0%, #ff4800 100%);
}

.tours-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 15px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

</style>
