<template>
  <div
    id="app-overlay"
    class="overlay"
    :class="{
      'active': modalOverlayIsVisible,
      'd-none': !modalOverlayIsVisible,
      'dark': modalOverlayIsDark
    }"
  ></div>
  <app-loader :is-visible="loaderOverlayIsVisible"></app-loader>
  <header-component></header-component>
  <div class="body">
    <router-view></router-view>
  </div>
  <footer-component></footer-component>
</template>

<script>

import HeaderComponent from '@/layouts/components/header/HeaderComponent.vue'
import FooterComponent from '@/layouts/components/footer/FooterComponent.vue'
import { computed } from 'vue'
import { commonStore } from '@/stores/commonStore'
import AppLoader from '@/layouts/components/AppLoader.vue'


export default {
  name: 'MainLayout',
  components: {
    AppLoader,
    HeaderComponent,
    FooterComponent
  },
  setup() {
    const commonStoreInstance = commonStore()
    const modalOverlayIsVisible = computed(() => commonStoreInstance.modalOverlayIsVisible)
    const modalOverlayIsDark = computed(() => commonStoreInstance.modalOverlayIsDark)
    const loaderOverlayIsVisible = computed(() => commonStoreInstance.loaderOverlayIsVisible)

    return {
      modalOverlayIsVisible,
      modalOverlayIsDark,
      loaderOverlayIsVisible,
    }
  },
}
</script>

<style lang='scss' scoped>

.overlay {
  top:0;
  position: fixed !important;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 238, 238, .9);
  z-index: 2;

  &.active {
    display: block;
  }

  &.dark {
    background: #000000E5;
  }

  &.loader-overlay {
    background-color: rgba(238, 238, 238, .7);
    z-index: 2;
  }
}

</style>
