<template>
  <div class="feedbacks">
    <div class="buttons flex-row">
      <div class="button">
        <span class="text">Читать на</span>
        <span class="text bold"> TopHotels</span>
      </div>
      <div class="button">
        <span class="text">Читать на</span>
        <span class="text bold"> TripAdvisor</span>
      </div>
    </div>
    <div class="list">
      <div
        v-for="(feedback, index) in feedbacks"
        :key="index"
        class="feedback flex-column-start"
      >
        <div class="name">{{ feedback.name }}</div>
        <div class="rating flex-row-center ">
          <div
            class="color"
            :class="getRatingColorClass(feedback.rate)"
          ></div>
          <div class="value">{{ feedback.rate }}</div>
        </div>
        <div class="date">{{ feedback.travelTime }}</div>
        <div class="text text-left">{{ feedback.content }}</div>
        <div class="from">
          <span class="title text-left">Отзыв с портала</span>
          {{ feedback.sourceLink }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { determineRatingColorClass } from '@/composables/rating'

export default {
  name: 'TourDetailedCardBodyTabFeedback',
  props: {
    feedbacks: Array
  },
  setup() {
    const getRatingColorClass = rating => determineRatingColorClass(rating)

    return {
      getRatingColorClass
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tab-content.feedbacks {
  .buttons {
    .button {
      width: 240px;
      border-radius: 50px;
      background: #FFF5EB;
      font-family: GilroyMedium;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      padding: 15px 0 14px 0;

      &:not(:last-child) {
        margin-right: 15px;
      }

      .text {
        &.bold {
          font-family: GilroyBold;
        }
      }
    }
  }

  .list {
    margin-top: 50px;
    max-width: 1045px;
    margin-bottom: 10px;

    .feedback {
      &:not(:first-child) {
        margin-top: 30px;
      }

      &:not(:last-child) {
        padding-bottom: 30px;
        border-bottom: 1px solid #E0E0E0;
      }

      .name {
        font-family: GilroyBold;
        font-size: 16px;
        line-height: 24px;
        color: $base-black;
        margin-bottom: 2px;
      }

      .rating {
        margin-bottom: 7px;

        .color {
          width: 21px;
          height: 4px;
          border-radius: 4px;

          &.green {
            background: $rating-green;
          }

          &.yellow {
            background: $rating-yellow;
          }

          &.orange {
            background: $rating-orange;
          }

          &.red {
            background: $rating-red;
          }
        }

        .value {
          margin-left: 5px;
          font-family: GilroySemibold;
          font-size: 13px;
          line-height: 20px;
          color: #888888;
        }
      }

      .date {
        font-family: GilroyRegular;
        font-size: 13px;
        line-height: 20px;
        color: $base-black;
        margin-bottom: 15px;
      }

      .text {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
        margin-bottom: 30px;
      }

      .from {
        .title {
          font-family: GilroyRegular;
          font-size: 14px;
          line-height: 21px;
          color: $base-black;
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    .buttons {
      .button {
        width: 220px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .list {
      margin-top: 40px;
    }
  }

  @media (max-width: 767px) {
    .buttons {
      flex-direction: column;

      .button {
        width: 100%;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    .list {
      margin-top: 30px;
    }
  }
}

</style>
