<template>
  <div
    v-if="showPersonalAccountLink"
    class="personal-account toolbar-item flex-row-center"
    :class="{
      'burger-active': burgerActive,
      'small-header-active': smallHeader
    }"
    @click="signInClick"
  >
    <div class="sign-in-button"></div>
    <div class="sign-in-button-circle d-none">
      <div class="sign-in-button-circle-small flex-row-center-center">
        <div class="inner-circle-sign-in-button"></div>
      </div>
    </div>
    <div class="text">Личный кабинет</div>
  </div>
  <div
    v-else
    class="user-info-wrapper toolbar-item flex-row-center-center"
    :class="{
      'burger-active': burgerActive,
      'small-header-active': smallHeader
    }"
  >
    <div class="name-wrapper item flex-column-center-center">
      <div class="text w-100">Добро пожаловать</div>
<!--      <div class="text name w-100">John Dow</div>-->
    </div>
    <div class="avatar-wrapper item flex-row-center-center">
      <div
        class="avatar"
        :class="{'has-avatar': hasAvatar}"
      ></div>
    </div>
    <personal-account-more-component></personal-account-more-component>
  </div>
  <component
    v-if="formComponent"
    :is="formComponent"
    @open-form="openFormEvent"
    @close-form="closeFormEvent"
  ></component>
</template>

<script>

import LoginFormComponent from '@/components/forms/sign-in/LoginFormComponent.vue'
import RegistrationFormComponent from '@/components/forms/sign-in/RegistrationFormComponent.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { authStore } from '@/stores/authStore'
import router from '@/router'
import PersonalAccountMoreComponent
  from '@/layouts/components/header/personal-account/PersonalAccountMoreComponent.vue'
import { commonStore } from '@/stores/commonStore'
import { goToAccountPage } from '@/composables/router'
import PasswordRecoveryFormComponent from '@/components/forms/sign-in/PasswordRecoveryFormComponent.vue'

export default {
  name: 'PersonalAccountComponent',
  components: {
    PersonalAccountMoreComponent, RegistrationFormComponent, LoginFormComponent, PasswordRecoveryFormComponent
  },
  props: {
    burgerActive: Boolean,
    smallHeader: Boolean
  },
  emits: ['sign-in-click'],
  setup(props, { emit }) {
    const formComponent = ref(null)
    const authStoreInstance = authStore()
    const commonStoreInstance = commonStore()
    const formClosed = computed(() => authStoreInstance.formIsClosed)
    const userIsAuthorized = computed(() => authStoreInstance.userIsAuthorized)
    const showPersonalAccountLink = computed(
      () => !authStoreInstance.userIsAuthorized || router.currentRoute.value.name === 'main'
    )
    const hasAvatar = false
    const currentVKAuthCode = computed(() => authStoreInstance.currentVKAuthCode)
    const currentGoogleAuthCode = computed(() => authStoreInstance.currentGoogleAuthCode)

    const signInClick = () => {
      emit('sign-in-click')
      if (userIsAuthorized.value) {
        goToAccountPage()
      } else {
        formComponent.value = 'LoginFormComponent'
        authStoreInstance.changeFormClosedState(true)
        commonStoreInstance.changeModalOverlayVisibility(true)
      }
    }
    const openFormEvent = value => {
      const formComponents = {
        login: 'LoginFormComponent',
        registration: 'RegistrationFormComponent',
        'password-recovery': 'PasswordRecoveryFormComponent',
      }
      formComponent.value = formComponents[value] ?? null
    }
    const closeFormEvent = () => {
      authStoreInstance.changeFormClosedState(false)
      commonStoreInstance.changeModalOverlayVisibility(false)
    }

    watch(formClosed, () => formComponent.value = formClosed.value ? null : formComponent.value)

    onMounted(() => {
      if (currentVKAuthCode.value || currentGoogleAuthCode.value) {
        signInClick()
      }
    })

    return {
      formComponent,
      showPersonalAccountLink,
      hasAvatar,

      signInClick,
      openFormEvent,
      closeFormEvent
    }
  },
}
</script>

<style scoped lang='scss'>

@import '@/assets/css/variables';

.toolbar-item.personal-account {
  border: 1px solid rgba(255, 255, 255, .6);
  border-radius: 10px;
  padding: 13px 21px 13px 17px;
  height: 24px;
  margin-right: 50px;

  .sign-in-button {
    width: 18px;
    height: 22px;
    background-size: cover;
    background-image: url('@/assets/images/common/sign-in-user.svg');
  }

  .text {
    font-family: GilroySemibold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    margin-left: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;

    .text {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  &.small-header-active {
    border: 1px solid rgba(34, 34, 34, 0.5);

    .text {
      color: $base-black;
    }

    .sign-in-button {
      width: 18px;
      height: 22px;
      background-size: cover;
      background-image: url('@/assets/images/common/sign-in-user-orange.svg');
    }
  }

  @media (max-width: 1679px) {
    margin-right: 25px;
  }

  @media (max-width: 767px) {
    border: unset !important;
    padding: 0;

    &.burger-active {
      z-index: 4;
    }

    &.burger-active, &.small-header-active {
      left: 16px;

      .sign-in-button {
        display: none;
        background-image: url('@/assets/images/common/sign-in-user-orange.svg');
      }

      .sign-in-button-circle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-image: url('@/assets/images/common/ellipse-11-white.svg');

        .sign-in-button-circle-small {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-image: url('@/assets/images/common/ellipse-light-gray.svg');

          .inner-circle-sign-in-button {
            width: 18px;
            height: 24px;
            background-repeat: no-repeat;
            background-image: url('@/assets/images/common/sign-in-user-black.svg');
          }
        }
      }
    }
  }
}

.toolbar-item.user-info-wrapper {
  &.small-header-active {
    width: 203px;

    .item:not(:last-child):not(.more) {
      margin-right: 12px;
    }

    .name-wrapper {
      .text {
        color: $base-black;
        text-align: right;
        font-size: 12px;
        line-height: 16.8px;
        font-family: GilroyRegular;

        &.name {
          font-size: 14px;
          line-height: 19.6px;
          font-family: GilroySemibold;
        }
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .avatar-wrapper {
      width: 40px;
      height: 40px;
      background-image: url('@/assets/images/common/ellipse-gray.svg');

      .avatar {
        width: 20px;
        height: 20px;
        background-image: url('@/assets/images/common/user.svg');

        &.has-avatar {
          width: 40px;
          height: 40px;
          background-image: url('@/assets/images/common/avatar.svg');
        }
      }
    }

    .context-menu {
      @media (max-width: 767px) {
        right: 62px;
      }
    }
  }
}

</style>
