export const openDocument = type => {
  let docName = 'Пользовательское соглашение термины и определения'
  if (type === 'privacy-policy') {
    docName = 'Соглашение о конфиденциальности'
  } else if (type === 'legal-information') {
    docName = 'Договор оферты'
  }

  const link = document.createElement('a')
  link.href = require(`@/assets/docs/${docName}.pdf`).default
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
