<template>
  <div class="map flex-column-start">
    <google-map-component
      v-if="lat || lng"
      :lat="lat"
      :lng="lng"
      class="map__google-map"
    ></google-map-component>

    <div class="map__buttons flex-row-center-center w-100">
      <div
        class="map__button cursor-pointer text-center"
        @click="openInGoogleMap"
      >Открыть в Google Maps</div>
    </div>
  </div>
</template>

<script>

import { openInGoogleMapByCoords } from '@/composables/map'
import GoogleMapComponent from '@/components/GoogleMapComponent.vue'
import { computed } from 'vue'

export default {
  name: 'SearchFormContentListItemMapTabComponent',
  components: {
    GoogleMapComponent
  },
  props: {
    hotel: Object
  },
  setup(props) {
    const lat = computed(() => props.hotel && props.hotel.coord1 ? parseFloat(props.hotel.coord1) : 0)
    const lng = computed(() => props.hotel && props.hotel.coord2 ? parseFloat(props.hotel.coord2) : 0)

    const openInGoogleMap = () => {
      if (lat.value || lng.value) {
        openInGoogleMapByCoords(lat.value, lng.value)
      }
    }

    return {
      lat,
      lng,

      openInGoogleMap
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.map {
  width: 100%;

  &__buttons {
    margin-top: 30px;
  }

  &__button {
    background: $base-gray;
    border-radius: 50px;
    width: 200px;
    padding: 12px 0 11px 0;
    font-family: GilroyMedium;
    font-size: 14px;
    line-height: 21px;
    color: $base-black;
  }

  &__google-map {
    width: 100%;
    height: 430px;
  }

  @media screen and (max-width: 1679px) {
    &__google-map {
      height: 291px;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0 20px 10px;

    &__buttons {
      display: none;
    }
  }
}

</style>
