<template>
  <div
      class="list"
      :class="searchCategory"
  >
    <template
        v-for="(item, index) in items"
        :key="index"
    >
      <search-form-content-list-item-component
          :picture-link="item.picturelink"
          :region-name="item.regionname"
          :hotel-code="item.hotelcode"
          :hotel-description="item.hoteldescription"
          :hotel-name="item.hotelname"
          :hotel-rating="item.hotelrating.toString()"
          :hotel-stars="item.hotelstars.toString()"
          :currency="item.currency"
          :price="item.price"
          :tours="item.tours.tour"
          :search-category="searchCategory"
          :tour-id="item.tourId ? item.tourId : null"
          :operator-image="item.operatorImage ? item.operatorImage : null"
      ></search-form-content-list-item-component>
    </template>
    <template v-if="itemsCount">
      <template v-if="searchCategory === 'hot-tours'">
        <show-more-button
            v-if="itemsPerPage <= itemsCount"
            class="list--item"
            show-lines
            is-gray
            @click="handleMoreButtonClick"
        ></show-more-button>
        <div v-else style="{'height': '30px'}">&nbsp;</div>
      </template>
      <template v-else>
        <list-paginator
            :items-count="itemsCount"
            :current-page="currentPage"
            :items-per-page="itemsPerPage"
            paginate-button-color="dark-gray"
            class="list--item pagination flex-row-center-center"
            @page-changed="handlePageChanged"
        ></list-paginator>
        <show-more-button
            v-if="itemsPerPage < itemsCount"
            class="list--item d-none"
            show-lines
            is-gray
            @click="handleMoreButtonClick"
        ></show-more-button>
        <div v-else style="{'height': '30px'}">&nbsp;</div>
      </template>
    </template>
    <div
        v-if="showEmpty"
        class="list--item empty"
    >Ничего не найдено
    </div>
  </div>
</template>

<script>


import {ref, watch} from 'vue'
import ListPaginator from '@/components/ListPaginator.vue'
import ShowMoreButton from '@/components/ShowMoreButton.vue'
import {formatAmount} from '@/composables/priceFormatter'
import SearchFormContentListItemComponent from '@/components/forms/search/SearchFormContentListItemComponent.vue'

export default {
  name: 'SearchFormContentListComponent',
  methods: {
    formatAmount
  },
  props: {
    items: Array,
    itemsCount: Number,
    page: Number,
    showEmpty: Boolean,
    searchCategory: String,
  },
  emits: ['page-changed', 'per-page-count-changed'],
  components: {
    SearchFormContentListItemComponent,
    ShowMoreButton,
    ListPaginator
  },
  setup(props, {emit}) {
    const currentPage = ref(1)
    const itemsPerPageStep = 5
    const itemsPerPage = ref(5)

    const handlePageChanged = event => {
      if (props.page !== event) {
        emit('page-changed', event)
      }
    }

    const handleMoreButtonClick = () => {
      itemsPerPage.value += itemsPerPageStep
      emit('per-page-count-changed', itemsPerPage.value)
    }

    watch(() => props.page, () => currentPage.value = props.page)

    return {
      currentPage,
      itemsPerPage,

      handlePageChanged,
      handleMoreButtonClick
    }
  },
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.list {
  display: grid;

  &--item {
    display: grid;

    &.pagination {
      height: 50px;
      margin: 40px 0;

      @media screen and (max-width: 767px) {
        display: none !important;
      }
    }

    &.show-more {
      margin-top: 40px;

      hr {
        @media screen and (max-width: 767px) {
          display: block;
        }
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &.empty {
      font-family: GilroyRegular;
      font-size: 16px;
      line-height: 24px;
      margin: 40px 0;
      color: $base-black;
    }
  }

  &.hot-tours {
    .list--item {
      &.show-more {
        display: flex;
      }
    }
  }
}

</style>
