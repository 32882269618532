import { defineStore } from 'pinia'

const moduleName = 'promocode'

export const promocodeStore = defineStore(moduleName, {
  state: () => ({}),
  getters: {},
  actions: {
    checkPromocode(promocode) {
      return this.$http.get(`${moduleName}/value?promocode=${promocode}`)
    },
  },
})
