import {defineStore} from 'pinia'

const moduleName = 'tour'

export const tourStore = defineStore(moduleName, {
    state: () => ({
        searchFilters: null,
        clearFiltersActive: false,
        countryIdForSearch: null
    }),
    getters: {
        selectedCountryIdForSearch: state => state.countryIdForSearch,
        currentSearchFilters: state => state.searchFilters,
        clearFiltersIsActive: state => state.clearFiltersActive,
    },
    actions: {
        setSelectedCountryIdForSearch(value) {
            this.countryIdForSearch = value
        },
        setClearFiltersActiveValue(value) {
            this.clearFiltersActive = value
        },
        setCurrentSearchFilters(filters) {
            this.searchFilters = filters
        },
        clearCurrentSearchFilters(blockType) {
            if (this.searchFilters) {
                if (blockType === 'top') {
                    delete this.searchFilters.topBlockFilters
                } else {
                    delete this.searchFilters.bottomBlockFilters
                }
                this.searchFilters = this.searchFilters && !Object.keys(this.searchFilters).length ? null : this.searchFilters
            }
        },
        getHotTours(cityId) {
            console.log('cityId', cityId)
            return this.$http.get(`tours/get/hot?${new URLSearchParams({cityId}).toString()}`)
        },
        searchHotTours(cityId, items) {
            return this.$http.get(`tours/search/hot?${new URLSearchParams({cityId, items}).toString()}`)
        },
        searchHotToursExtended(cityId, items, meal, stars, rating, datefrom, dateto, regions, countries) {
            let params = {cityId, items, meal, stars, rating, datefrom, dateto, regions, countries}
            return this.$http.get(
                `tours/search/hot?${new URLSearchParams(
                    Object.fromEntries(Object.entries(params).filter(item => !!item[1]))
                ).toString()}`
            )
        },
        getShortHotelInfo(id) {
            return this.$http.get(`tours/short-info/${id}`)
        },
        getTour(id) {
            return this.$http.get(`tours/${id}`)
        },
        getSearchRequestId(departure, country, {
            stars,
            rating,
            services,
            pricefrom,
            priceto,
            currency,
            datefrom,
            dateto,
            meal,
            adults,
            child,
            childAge1,
            childAge2,
            childAge3,
            regions,
            nightsfrom,
            nightsto
        } = {}) {
            return this.$http.get(`tours/search?` + new URLSearchParams(
                Object.fromEntries(
                    Object.entries({
                        departure,
                        country,
                        stars,
                        rating,
                        services,
                        pricefrom,
                        priceto,
                        currency,
                        datefrom,
                        dateto,
                        meal,
                        adults,
                        child,
                        childAge1,
                        childAge2,
                        childAge3,
                        regions,
                        nightsfrom,
                        nightsto
                    }).filter(item => !!item[1])
                )
            ).toString())
        },
        getSearchRequestResult(requestId, page = 1, onPage = 5) {
            return this.$http.get(`tours/search/results?requestid=${requestId}&page=${page}&onpage=${onPage}`)
        },
        searchTourHotel(hotelId) {
            return this.$http.get(`tours/hotel/${hotelId}`)
        },
    },
})
