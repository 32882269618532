<template>
  <div class="account-page">
    <div class="title w-100 text-left">Личный кабинет</div>
    <div class="content-wrapper flex-row">

      <personal-account-page-menu
        :selected-item-type="selectedMenuItemType"
        @item-click="onMenuItemClick"
      ></personal-account-page-menu>

      <personal-account-page-content
        :selected-item-type="selectedMenuItemType"
      ></personal-account-page-content>

    </div>
  </div>
</template>

<script>

import { computed, onMounted, ref, watch } from 'vue'
import PersonalAccountPageMenu from '@/pages/personal-account-page/PersonalAccountPageMenu.vue'
import PersonalAccountPageContent from '@/pages/personal-account-page/PersonalAccountPageContent.vue'
import { accountPageStore } from '@/stores/accountPageStore'

export default {
  name: 'PersonalAccountPage',
  components: [
    PersonalAccountPageMenu, PersonalAccountPageContent
  ],
  setup() {
    const selectedMenuItemType = ref('profile')
    const accountPageStoreInstance = accountPageStore()
    const accountMenu = computed(() => accountPageStoreInstance.accountMenu)

    const onMenuItemClick = type => selectedMenuItemType.value = type

    watch(accountMenu.value, () => selectedMenuItemType.value = accountMenu.value.find(i => i.active).type)

    onMounted(() => selectedMenuItemType.value = accountMenu.value.find(m => m.active).type)

    return {
      selectedMenuItemType,

      onMenuItemClick
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.account-page {
  padding: 50px 120px 0 120px;

  .title {
    font-family: GilroyBold;
    font-size: 44px;
    line-height: 66px;
    margin-bottom: 50px;
  }

  .panel {
    border-radius: 16px;

    &:not(.background-unset) {
      background: $wrapper-bg-white;
    }

    &.background-unset {
      padding: 0;
    }
  }

  @media (max-width: 1679px) {
    padding-left: 24px;
    padding-right: 24px;

    .content-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;

    .title {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 30px;
    }
  }
}
</style>
