<template>
  <div class="map flex-column-start">
    <div class="buttons flex-row-center-end w-100">
      <div
        class="button cursor-pointer"
        @click="openInYandexMap"
      >
        <span class="text">Открыть на</span>
        <span class="text bold"> Яндекс Картах</span>
      </div>
      <div
        class="button cursor-pointer"
        @click="openInGoogleMap"
      >
        <span class="text">Открыть на</span>
        <span class="text bold"> Google Maps</span>
      </div>
    </div>
    <google-map-component
      v-if="latitude || longitude"
      :lat="latitude"
      :lng="longitude"
      class="google-map"
    ></google-map-component>
  </div>
</template>

<script>

import { computed } from 'vue'
import { openInGoogleMapByCoords } from '@/composables/map'
import GoogleMapComponent from '@/components/GoogleMapComponent.vue'

export default {
  name: 'TourDetailedCardBodyTabMap',
  components: {
    GoogleMapComponent
  },
  props: {
    latitude: Number,
    longitude: Number,
  },
  setup(props) {
    const yandexMapLink = computed(() => {
      if (props.longitude || props.latitude) {
        return `https://maps.yandex.ru/?pt=${props.longitude},${props.latitude}&ol=geo&z=16&l=map`
      }
      return null
    })

    const openInYandexMap = () => {
      if (yandexMapLink.value) {
        window.open(yandexMapLink.value, '_blank')
      }
    }
    const openInGoogleMap = () => {
      if (props.latitude || props.longitude) {
        openInGoogleMapByCoords(props.latitude, props.longitude)
      }
    }

    return {
      openInYandexMap,
      openInGoogleMap
    }
  }
}
</script>

<style lang='scss' scoped>

.tab-content.map {
  .buttons {
    margin-bottom: 20px;

    .button {
      background: #FFF5EB;
      border-radius: 50px;
      width: 240px;
      padding: 15px 0 14px 0;

      &:first-child {
        margin-right: 15px;
      }

      .text {
        font-family: GilroyMedium;
        font-size: 14px;
        line-height: 21px;

        &.bold {
          font-family: GilroyBold;
        }
      }
    }
  }

  .google-map {
    width: 100%;
    height: 557px;
  }

  @media (max-width: 1679px) {
    margin-bottom: -10px;

    .buttons {
      justify-content: center;
      margin-bottom: 30px;
    }

    .google-map {
      height: 470px;
    }
  }

  @media (max-width: 767px) {
    .buttons {
      flex-direction: column;
      margin-top: 10px;
      justify-content: center;
      margin-bottom: 40px;

      .button {
        width: 100%;

        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

</style>
