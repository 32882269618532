<template>
  <div class="about-project flex-row-start-space-between">
    <div class="block-left flex-column-start-start">
      <div class="title-wrapper flex-column-start">
        <span>О проекте</span>
        <hr class="one">
        <hr class="two">
      </div>
      <div class="text one text-left">
        Забудьте о бесконечных поисках по разным сайтам - у нас всё, что вам нужно, в одном месте!
      </div>
      <div class="text text-left">
        “Тур в кайф” - это удобный инструмент для всех, кто мечтает о незабываемых путешествиях. Мы предлагаем уникальную возможность сравнивать и выбирать из сотен туров, предоставленных проверенными агентствами, прямо на одной платформе.
      </div>
      <div class="text text-left">
        Наш интуитивно понятный интерфейс и мощный поиск позволяют путешественникам легко находить и покупать идеальные туры, учитывая их бюджет, предпочтения и желаемые даты.
      </div>
      <button
        class="button text-center text cursor-pointer"
        @click="goToSearchTourPage()"
      >Узнать больше</button>
    </div>
    <div class="block-right"></div>
  </div>
</template>

<script>

import { goToSearchTourPage } from '@/composables/router'

export default {
  name: 'MainPageAboutProject',
  methods: {
    goToSearchTourPage
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.about-project {
  .block-left {
    width: calc(50% - 110px);
    padding-right: 110px;

    .title-wrapper {
      padding-bottom: 28px;

      span {
        font-family: GilroyBold;
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 15px;

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 42px;
        }
      }

      hr {
        padding: 0;
        margin: 0;
        height: 1px;
        background: #FF8500;
        border: none;
        &.one {
          width: 50px;
          margin-bottom: 5px;
        }
        &.two {
          width: 30px;
        }
      }
    }

    .text {
      font-family: GilroyRegular;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;

      &.one {
        font-family: GilroyMedium;
        font-size: 20px;
        line-height: 30px;
      }
    }

    .button {
      margin-top: 22px;
      margin-bottom: 0;
      width: 224px;
      height: 60px;
      border: unset;
      border-radius: 6px;
      font-family: GilroySemibold;
      font-size: 16px;
      line-height: 16px;
      color: $base-white;
      background: linear-gradient(225deg, #FF8500 0%, #FF4800 100%);

      @media (max-width: 1679px) {
        display: none;
      }
    }

    @media (max-width: 1679px) {
      width: 100%;
      padding-right: 0;
    }
  }

  .block-right {
    max-width: 50%;
    min-width: 50%;
    width: 720px;
    height: 504px;
    background-image: url('@/assets/images/common/about-project.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;

    @media (max-width: 1679px) {
      margin-top: 20px;
      width: 100%;
      max-width: unset;
      min-width: unset;
    }

    @media (max-width: 767px) {
      margin-top: 0;
      background-image: url('@/assets/images/mobile/about-project.svg');
    }
  }

  @media (max-width: 1679px) {
    flex-direction: column;
  }
}

</style>
