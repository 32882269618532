<template>
  <div class="about-hotel">
    <div class="about-hotel--photo-album h-100">
      <template v-for="(image, index) in images">
        <img
          v-if="index < 6"
          :key="index"
          :src="image"
          alt="image"
        />
      </template>
    </div>
    <div class="about-hotel--info">
      <div class="about-hotel--info--description">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="about-hotel--info--description--item flex-row"
        >
          <span class="title text-right">{{ item.title }}</span>
          <span class="text text-left">{{ item.text }}</span>
        </div>
      </div>
      <div
        v-if="!isUserTour"
        class="about-hotel--info--actions text-left"
      >
        <div
          class="button text-center cursor-pointer"
          @click="$emit('open-hotel-detailed-card')"
        >Подробнее об отеле</div>
      </div>
    </div>
  </div>
</template>

<script>


import { computed, onMounted, ref, watch } from 'vue'

export default {
  name: 'AboutHotelTabComponent',
  props: {
    hotel: Object,
    isUserTour: Boolean
  },
  emits: ['open-hotel-detailed-card'],
  setup(props) {
    const data = ref([
      {
        title: 'Построен',
        text: null,
        type: 'build'
      },
      {
        title: 'РАСПОЛОЖЕН',
        text: null,
        type: 'placement'
      },
      {
        title: 'ТЕРРИТОРИЯ',
        text: null,
        type: 'territory'
      },
      {
        title: 'УСЛУГИ',
        text: null,
        type: 'servicepay'
      },
      {
        title: 'НОМЕР',
        text: null,
        type: 'inroom'
      },
    ])
    const images = computed(
      () => props.hotel && props.hotel.images && props.hotel.images.image ? props.hotel.images.image : []
    )

    const loadData = () => {
      if (props.hotel) {
        data.value.map(item => {
          if (item.type === 'build') {
            item.text = props.hotel && props.hotel.build ? `${props.hotel.build} г.` : null
          } else {
            item.text = props.hotel && props.hotel[item.type] ? props.hotel[item.type] : null
          }
        })
      }
    }

    onMounted(() => loadData())

    watch(() => props.hotel, () => loadData())

    return {
      images,
      data
    }
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.about-hotel {
  display: grid;
  grid-template-columns: 240px auto;
  column-gap: 30px;
  align-items: center;

  &--photo-album {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    column-gap: 15px;
    grid-auto-rows: max-content;

    img {
      width: 70px;
      height: 70px;
      border-radius: 6px;
      object-fit: cover;
    }

    @media screen and (max-width: 767px) {
      width: 240px;
      height: 155px;
      margin-bottom: 30px;
    }
  }

  &--info {
    &--description {
      margin-bottom: 30px;

      &--item {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .title {
          margin-right: 19px;
          width: 82px;
          min-width: 82px;
          font-family: GilroySemibold;
          font-size: 12px;
          line-height: 12px;
          color: $base-black;
          text-transform: uppercase;
        }

        .text {
          font-family: GilroyRegular;
          font-size: 14px;
          line-height: 14px;
          color: #666666;
        }
      }
    }

    &--actions {
      .button {
        border-radius: 30px;
        background: #F8F8F8;
        width: 149px;
        padding: 9px 0 7px;
        font-family: GilroySemibold;
        font-size: 12px;
        line-height: 12px;
        color: #666666;
        text-transform: uppercase;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }

  @media screen and (max-width: 1679px) {
    column-gap: 20px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.about-hotel.favorites {
  grid-template-columns: auto;

  .about-hotel--photo-album {
    img {
      width: 100%;
    }
  }

  .about-hotel--info {
    .about-hotel--info--description {
      margin-top: 20px;
    }
  }
}

</style>
