<template>
  <div class="content">
    <div class="cell">
      <img
        v-if="operatorImage"
        :src="operatorImage"
        alt="flight-program-icon"
      />
    </div>
    <div class="cell flex-row">
      <div class="description flex-column-start">
        <span class="title text-left">{{ currentRouteIsSearchHotels ? '' : 'Чартер' }}</span>
        <span class="note text-left">{{ tourName ?? '-' }}</span>
      </div>
    </div>
    <div class="cell">
      <div class="description flex-column-start">
        <span class="title text-left">{{ formattedFlyDate }}</span>
        <span class="note text-left">{{ nights }} нч</span>
      </div>
    </div>
    <div class="cell">
      <div class="description flex-column-start">
        <span class="title text-left">{{ formattedRoom }}: 1 комнатный / {{ adults }} взр</span>
        <span class="note text-left">{{ mealRussian ?? 'Без питания' }}</span>
      </div>
    </div>
    <div class="cell flex-row-center-end">
      <div class="actions flex-row-center-center">
        <!--            <circle-icon-->
        <!--              size="40px"-->
        <!--              icon-size="20px"-->
        <!--              :src="require('@/assets/images/common/shop-cart-black.svg')"-->
        <!--            ></circle-icon>-->
        <div class="price-wrapper text-left">
          <span class="price">{{ formatAmount(price) }}</span>
          <span class="currency">руб</span>
        </div>
        <div
          class="button-wrapper cursor-pointer"
          @click="onClickBuyItem"
        >
          <span class="button">Купить</span>
        </div>
      </div>
    </div>

    <tour-detailed-card
      v-if="tourIdCardToOpen"
      :tour-id="tourIdCardToOpen.id.toString()"
      :meal="tourIdCardToOpen.meal"
      :tour-logo="operatorImage"
      :entity-payment-category="$router.currentRoute.value.name === 'search-tour' ? 'TOUR' : 'HOTEL'"
      @hide-modal="handleHideModal"
    ></tour-detailed-card>
  </div>
</template>

<script>


import { formatAmount } from '@/composables/priceFormatter'
import moment from 'moment'
import { computed, ref } from 'vue'
import TourDetailedCard from '@/components/tour/tour-detailed-card/TourDetailedCard.vue'
import { commonStore } from '@/stores/commonStore'
import router from '@/router'

export default {
  name: 'SearchFormContentListItemMoreItemComponent',
  methods: {
    formatAmount
  },
  props: {
    price: Number,
    nights: Number,
    adults: Number,
    flyDate: String,
    tourId: String,
    tourName: String,
    room: String,
    mealRussian: String,
    operatorImage: String
  },
  components: {
    TourDetailedCard
    // CircleIcon
  },
  setup(props) {
    const formattedFlyDate = computed(() => {
      return props.flyDate
        ? moment(props.flyDate, 'DD.MM.YYYY').format('D MMM').replace('.', '')
        : '-'
    })
    const formattedRoom = computed(() => props.room && props.room === 'standard' ? 'Стандарт' : props.room)
    const tourIdCardToOpen = ref(null)
    const commonStoreInstance = commonStore()
    const currentRouteIsSearchHotels = computed(() => router.currentRoute.value.name === 'search-hotel')

    const handleHideModal = () => {
      tourIdCardToOpen.value = null
      commonStoreInstance.changeModalOverlayVisibility(false, true)
    }

    const onClickBuyItem = () => tourIdCardToOpen.value = { id: props.tourId, meal: props.mealRussian }

    return {
      formattedFlyDate,
      formattedRoom,
      tourIdCardToOpen,
      currentRouteIsSearchHotels,

      onClickBuyItem,
      handleHideModal
    }
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.content {
  margin: 20px 0;

  .cell {
    img {
      max-width: 130px;
      margin-right: 11px;
    }

    .description {
      span {
        font-size: 14px;
        line-height: 21px;

        &.title {
          font-family: GilroyBold;
          color: $base-black;
          margin-bottom: 5px;
        }

        &.note {
          font-family: GilroyRegular;
          color: #666666;
        }
      }
    }

    .actions {
      .circle {
        margin-right: 30px;

        @media screen and (max-width: 767px) {
          margin-right: 20px;
        }
      }

      .price-wrapper {
        width: 105px;
        padding: 7px 0 7px 15px;
        border-radius: 40px;
        background: $base-gray;

        span {
          color: $base-black;

          &.price {
            font-family: GilroyBold;
            font-size: 16px;
            line-height: 24px;
          }

          &.currency {
            margin-left: 5px;
            font-family: GilroyRegular;
            font-size: 13px;
            line-height: 19.5px;
          }
        }
      }

      .button-wrapper {
        margin-left: 10px;
        padding: 7px 15px;
        border-radius: 40px;
        background: $base-orange;

        span {
          font-family: GilroyMedium;
          font-size: 14px;
          line-height: 21px;
          color: $base-white;
        }
      }
    }

    @media screen and (max-width: 1679px) {
      &:last-child {
        grid-column: span 4;
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      &:last-child {
        grid-column: span 2;
        justify-content: flex-start;
        margin-top: 10px;
      }
    }
  }
}

</style>
