<template>
  <div class="footer">
    <div class="footer--toolbar flex-row-center-space-between">
      <div
        class="footer--toolbar--logo flex-row-center-space-between cursor-pointer"
        @click="goToMainPage('header')"
      >
        <div class="footer--toolbar--logo--image"></div>
        <div class="footer--toolbar--logo--separator"></div>
        <div class="footer--toolbar--logo--text">Купи тур онлайн</div>
      </div>
      <div class="footer--toolbar--menu flex-row-center-space-between">
        <footer-menu-item-component
          v-for="(item, index) in menuItems"
          :key="index"
          :text="item.text"
          :type="item.type"
        ></footer-menu-item-component>
      </div>

      <socials-component class="footer--toolbar--social flex-row-center-space-between"></socials-component>
    </div>
    <hr class="footer--line">
    <div class="footer--additional-info flex-row-center-space-between">
      <div class="footer--additional-info--wrapper flex-row-center-space-between w-100">
        <div
          v-for="(item, index) in additionalInfoItems"
          :key="index"
          class="footer--additional-info--wrapper--item cursor-pointer"
          @click="handFooterDocClick(item.type)"
        >{{ item.text }}</div>
      </div>
      <hr class="footer--additional-info--line-hidden d-none">
      <div class="footer--additional-info--item-hidden d-none">
        <div>
          {{ additionalInfoItems[0].text }}
        </div>
    </div>
      <div style="margin-top: 32px">
        <div class="info">
          <div>ООО “Кайф Тур”</div>
          <div>ОГРН: 1236900010027</div>
          <div>ИНН/КПП: 6900000212 690001001</div>
        </div>
        <div class="info">
          <div>Юридический адрес:</div>
          <div>170039, Тверская область, г.Тверь, ул. Хромова, д.25, помещ. 30</div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import FooterMenuItemComponent from '@/layouts/components/footer/FooterMenuItemComponent.vue'
import SocialsComponent from '@/components/SocialsComponent.vue'
import { goToMainPage } from '@/composables/router'
import { openDocument } from '@/composables/docs'

export default {
  name: 'HeaderComponent',
  methods: {
    goToMainPage
  },
  components: {
    SocialsComponent, FooterMenuItemComponent
  },
  setup() {
    const menuItems = ref([
      {text: 'Горящие туры', type: 'hot-tours'},
      {text: 'Поиск туров', type: 'search-tours'},
      // {text: 'Поиск отелей', type: 'search-hotels'},
      {text: 'О нас', type: 'about-us'},
    ])
    const additionalInfoItems = ref([
      {text: `© ${new Date().getFullYear()} “Кайф Тур”. Все права защищены.`},
      // {text: `ООО “Кайф Тур”. ОГРН: 1236900010027. ИНН/КПП: 6900000212 690001001. Юридический адрес: 170039, Тверская область, г.Тверь, ул. Хромова, д.25, помещ. 30`},
      {text: 'Пользовательское соглашение',  type: 'user-agreement'},
      {text: 'Политика конфиденциальности',  type: 'privacy-policy'},
      {text: 'Правовая информация',  type: 'legal-information'},
    ])

    const handFooterDocClick = type => openDocument(type)

    return {
      menuItems,
      additionalInfoItems,
      handFooterDocClick
    }
  }
}
</script>
<style lang='scss' scoped>

@import '@/assets/css/variables';

.info {
  display: flex;
  gap: 8px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.footer {
  width: calc(100% - 240px);
  background: $base-white;
  padding: 50px 120px 0 120px;

  &--toolbar {
    margin-bottom: 50px;

    &--logo {
      &--image {
        width: 120px;
        height: 50px;
        background-image: url('@/assets/images/common/logo-black.svg');
      }

      &--separator {
        width: 1px;
        height: 50px;
        background: #222222;
        margin-right: 15px;
        margin-left: 25px;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &--text {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 20px;
        color: #222222;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    &--menu {
      width: 100%;
      max-width: 450px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  &--line {
    background: #EEEEEE;
    height: 1px;
    border: none;
    margin: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &--additional-info {
    padding: 30px 0 28px 0;
    font-family: GilroyRegular;
    font-size: 14px;
    line-height: 16.8px;
    color: #888888;
    flex-direction: column;

    &--wrapper {
      &--item {
        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }

        &:first-child {
          @media screen and (max-width: 1679px) {
            display: none;
          }
        }
        &:not(:last-child) {
          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
      }

      @media screen and (max-width: 1679px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 50px;
        margin-top: 0;
      }
    }

    &--line-hidden {
      @media screen and (max-width: 1679px) {
        display: block;
        background: #EEEEEE;
        height: 1px;
        border: none;
        margin: 0;
        width: 100%;
      }
    }

    &--item-hidden {
      @media screen and (max-width: 1679px) {
        margin-top: 30px;
        display: block;
      }
    }

    @media screen and (max-width: 1679px) {
      padding: 0 0 26px 0;
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding: 0 0 32px 0;
    }
  }

  @media screen and (max-width: 1679px) {
    padding: 50px 24px 0;
    width: calc(100% - 48px);
  }

  @media screen and (max-width: 767px) {
    padding: 50px 10px 0;
    width: calc(100% - 20px);
  }
}
</style>
