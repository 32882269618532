<template>
  <div class="panel-title text-left">Поддержка</div>
  <div class="contacts-wrapper flex-row-center-space-between">
    <div
      v-for="contact in contacts"
      :key="contact.type"
      class="contact flex-row-center"
    >
      <div class="image flex-row-center-center">
        <div
          class="image"
          :class="contact.type"
        ></div>
      </div>
      <div class="text-wrapper flex-column-start">
        <div class="label">{{ contact.label }}</div>
        <div class="text">{{ contact.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import { getEmail } from '@/composables/contacts'

export default {
  name: 'PersonalAccountPageContentSupport',
  setup() {
    const contacts = ref([
      {type: 'phone', label: 'Звоните нам', value: '88007770113'},
      {type: 'email', label: 'Пишите нам', value: getEmail()},
      {type: 'telegram', label: 'Наш Telegram', value: '@kaiftour'},
    ])

    return {
      contacts
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.account-page .panel {
  background: unset;
}

.panel-title {
  @media (max-width: 767px) {
    margin-bottom: 20px !important;
  }
}

.contacts-wrapper {
  padding: 22px 40px;
  background: $wrapper-bg-white;
  border-radius: 16px;
  height: 56px;
  max-width: 1050px;

  .contact {
    .image {
      width: 54px;
      height: 54px;
      background-image: url('@/assets/images/common/ellipse-11-white.svg');
      background-position: center;
      background-repeat: no-repeat;

      .image {
        width: 20px;
        height: 20px;

        &.phone {
          background-image: url('@/assets/images/common/phone-orange.svg');
        }

        &.email {
          background-image: url('@/assets/images/common/mail-orange.svg');
        }

        &.telegram {
          background-image: url('@/assets/images/common/tg-orange.svg');
        }
      }
    }

    .text-wrapper {
      margin-left: 17px;

      .label {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 22px;
        color: #888888;
      }

      .text {
        font-family: GilroyMedium;
        font-size: 18px;
        line-height: 29px;
        color: #222222;
      }
    }
  }

  @media (max-width: 1679px) {
    max-width: unset;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;

    .contact {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

</style>
