<template>
  <div
    id="search-hotel-page"
    class="search-hotel-page"
  >
<!--    <div class="search-hotel-page&#45;&#45;title text-left">Поиск отелей</div>-->
    <search-form-component
      search-category="hotels"
      search-button-text="Найти отели"
      class="search-hotel-page--form"
    ></search-form-component>
<!--    <mobile-app class="search-hotel-page&#45;&#45;mobile-app"></mobile-app>-->
    <questions-answers class="search-hotel-page--questions-answers"></questions-answers>
  </div>
</template>

<script>

export default {
  name: 'SearchHotelPage'
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.search-hotel-page {
  display: grid;

  &--title {
    margin: 50px 120px;
    font-family: GilroyBold;
    font-size: 44px;
    line-height: 66px;
    color: $base-black;

    @media screen and (max-width: 1679px) {
      margin: 50px 0 30px 24px;
    }

    @media screen and (max-width: 767px) {
      margin: 30px 10px;
    }
  }

  &--form {
    display: grid;

    @media screen and (max-width: 1679px) {
      margin: 0;
    }
  }

  &--mobile-app {
    margin: 0 120px 150px 120px;

    @media (max-width: 1679px) {
      margin: 0 24px 70px;
    }

    @media (max-width: 767px) {
      margin: 0 10px 70px;
    }
  }

  @media (max-width: 360px) {
    &--questions-answers, &--mobile-app, &--title, &--form {
      width: calc(100% - 35px);
    }
  }
}

</style>
