<template>
  <div
      class="loader-container flex-column-start"
      :style="{
      top: containerTopPosition,
      left: containerLeftPosition,
      width: containerSize,
      height: containerSize
    }"
  >
    <div
        class="loader"
        :style="{
        width: loaderSize,
        height: loaderSize
      }"
    >
      <div class="line line--1"></div>
      <div class="line line--2"></div>
    </div>
    <div
        v-if="currentLoaderOverlayText"
        class="text"
    >{{ currentLoaderOverlayText }}
    </div>
  </div>
</template>

<script>


import {computed} from 'vue'
import {commonStore} from '@/stores/commonStore'

export default {
  name: 'AnimatedLoader',
  props: {
    containerTopPosition: {
      type: String,
      required: false,
      default: () => '50%'
    },
    containerLeftPosition: {
      type: String,
      required: false,
      default: () => '50%'
    },
    containerSize: {
      type: String,
      required: false,
      default: () => '200px'
    },
    loaderSize: {
      type: String,
      required: false,
      default: () => '50%'
    },
    text: String
  },
  setup() {
    const commonStoreInstance = commonStore()
    const currentLoaderOverlayText = computed(() => commonStoreInstance.currentLoaderOverlayText)

    return {
      currentLoaderOverlayText
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.loader-container {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px;
  }

  .loader .line {
    position: absolute !important;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border-top: 5px solid $base-orange;
  }

  .loader .line--1 {
    transform: rotateY(60deg) rotateX(30deg);
    animation: spin--1 800ms linear infinite;
  }

  .loader .line--2 {
    transform: rotateY(60deg) rotateX(-30deg);
    animation: spin--2 800ms linear infinite;
    animation-delay: 400ms;
  }

  @keyframes spin--1 {
    to {
      transform: rotateY(60deg) rotateX(30deg) rotate(360deg);
    }
  }

  @keyframes spin--2 {
    to {
      transform: rotateY(60deg) rotateX(-30deg) rotate(360deg);
    }
  }

  .text {
    margin-top: 20px;
    color: $base-black;
  }
}

</style>
