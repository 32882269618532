import { defineStore } from 'pinia'

const moduleName = 'dictionaries'

export const dictionariesStore = defineStore(moduleName, {
  state: () => ({
    showLoaderOverlay: false,
    regionsCountryId: null,
    countryRegions: [],
    allCountries: []
  }),
  getters: {
    allCountriesList: state => state.allCountries,
    currentRegionsCountryId: state => state.regionsCountryId,
    currentCountryRegions: state => state.countryRegions,
  },
  actions: {
    getDepartureCities () {
      return this.$http.get(`${moduleName}/departure-cities`)
    },
    getCountries (cityId) {
      return this.$http.get(`${moduleName}/countries?cityId=${cityId}`)
    },
    getAllCountries () {
      return this.$http
        .get(`${moduleName}/countries`)
        .then(({ data }) => this.allCountries = data)
    },
    getRegionsByCountry (countryId) {
      return this.$http
        .get(`${moduleName}/regions?countryId=${countryId}`)
        .then(({ data }) => {
          this.countryRegions = data
          this.regionsCountryId = countryId
        })
    },
    getResortsByCountry(countryId) {
      return this.$http.get(`${moduleName}/regions?countryId=${countryId}`)
    },
    getMeal () {
      return this.$http.get(`${moduleName}/meal`)
    },
    getStars () {
      return this.$http.get(`${moduleName}/stars`)
    },
    getHotel (hotCountryCode, {
      hotRegionCode,
      hotStars,
      hotRating,
      hotActive,
      hotRelax,
      hotFamily,
      hotHealth,
      hotCity,
      hotBeach,
      hotDeluxe
    } = {}) {
      return this.$http.get(`${moduleName}/hotel?` + new URLSearchParams(
        Object.fromEntries(
          Object.entries({
            hotCountryCode,
            hotRegionCode,
            hotStars,
            hotRating,
            hotActive,
            hotRelax,
            hotFamily,
            hotHealth,
            hotCity,
            hotBeach,
            hotDeluxe
          }).filter((item) => item[1] !== undefined)
        )
      ).toString())
    },
    getFlydate (countryCode, cityCode) {
      return this.$http.get(`${moduleName}/flydate?countryCode=${countryCode}&cityCode=${cityCode}`)
    },
    getServices () {
      return this.$http.get(`${moduleName}/services`)
    },
  },
})
