import Egypt from './1.jpg';
import Thailand from './2.jpg';
import India from './3.jpg';
import Turkiye from './4.jpg';
import UAE from './9.jpg';
import Cuba from './10.jpg';
import SriLanka from './12.jpg';
import Russia from './47.jpg';

const images = {
    "1": Egypt,
    "2": Thailand,
    "3": India,
    "4": Turkiye,
    "9": UAE,
    "10": Cuba,
    "12": SriLanka,
    "47": Russia,
}

export default images;
