<template>
  <div
    class="search-form--header"
    :class="{
      'active-switcher': switcherClicked
    }"
  >
    <div
      class="search-form--header--item switcher d-none cursor-pointer"
      :class="{
        'active': switcherClicked
      }"
      @click="onSwitcherClicked"
    >
      <div class="icon"></div>
      <span>Фильтры</span>
    </div>
    <div
      v-if="tourTypeFilters.length"
      class="search-form--header--item text-left flex-row"
      :class="{'show-item': switcherClicked}"
    >
      <check-box-field-component
        v-for="filter in tourTypeFilters"
        :key="filter.id"
        :label="filter.label"
        :id="filter.id"
        :value="filter.value"
        :disabled="filter.disabled"
      ></check-box-field-component>
    </div>
    <search-form-header-top-component
      :search-category="searchCategory"
      :show-search-button="!switcherClicked"
      :search-button-text="searchButtonText"
      class="search-form--header--item"
      :class="{'show-item': switcherClicked}"
      @filters-changed="$emit('filters-changed', $event)"
      @search="$emit('search')"
    ></search-form-header-top-component>
  </div>
</template>

<script>


import CheckBoxFieldComponent from '@/components/forms/CheckBoxFieldComponent.vue'
import { ref } from 'vue'
import SearchFormHeaderTopComponent from '@/components/forms/search/SearchFormHeaderTopComponent.vue'

export default {
  name: 'SearchFormHeaderComponent',
  props: {
    searchCategory: String,
    searchButtonText: String
  },
  emits: ['switcher-state-changed', 'filters-changed', 'search'],
  components: {
    SearchFormHeaderTopComponent,
    CheckBoxFieldComponent
  },
  setup(props, { emit }) {
    const switcherClicked = ref(false)
    // const tourTypeFilters = ref([
    //   {label: 'Туры с перелетом', id: 'tours-with-flights', value: true, disabled: false},
    //   {label: 'Горящие туры', id: 'hot-tours', value: false, disabled: false},
    //   {label: 'Отели', id: 'hotels', value: true, disabled: true},
    // ])
    const tourTypeFilters = ref([])

    const onSwitcherClicked = () => {
      switcherClicked.value = !switcherClicked.value
      emit('switcher-state-changed', switcherClicked.value)
    }

    return {
      switcherClicked,
      tourTypeFilters,

      onSwitcherClicked
    }
  },
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.search-form--header {
  background: #F8F8F8;
  grid-column: span 2;
  margin-bottom: 30px;
  border-radius: 16px;
  padding: 20px;

  &--item {

    &.switcher {
      padding: 19px 0 17px;

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 7px;
        background-image: url('@/assets/images/common/settings-black.svg');
        background-position: center;
        background-size: cover;
      }

      span {
        font-family: GilroyMedium;
        font-size: 14px;
        line-height: 14px;
        color: $base-black;
        text-transform: uppercase;
      }

      &.active {
        margin-bottom: 22px;

        .icon {
          width: 17px;
          height: 17px;
          margin-right: 10px;
          background-image: url('@/assets/images/common/close-black.svg');
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 11px;
        }
      }

      @media screen and (max-width: 1679px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media screen and (max-width: 1679px) {
      &:not(.switcher) {
        display: none;
      }

      &.show-item {
        display: flex;
      }

      &:nth-child(2) {
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 1679px) {
    background: #F8F8F8;
    border: 1px solid #E0E0E0;
    padding: 0 20px;
    margin: 0 24px 30px;
    border-radius: 6px;

    &.active-switcher {
      margin: 0;
      border-radius: 6px 6px 0 0;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;

      @media screen and (max-width: 767px) {
        padding: 0 10px;
        width: calc(100% - 20px);
      }
    }
  }

  @media screen and (max-width: 1679px) {
    margin: 0 24px 30px;
  }

  @media screen and (max-width: 767px) {
    margin: 0 10px 20px;
  }

  @media screen and (max-width: 360px) {
    width: calc(100% - 77px);
  }
}

</style>
