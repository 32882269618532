<template>
  <teleport to="#app-overlay">
    <div
      class="system-notify"
      :class="customClass"
    >
      <div
        class="system-notify--wrapper"
        v-click-outside="() => $emit('close')"
      >
        <div class="system-notify--wrapper--header flex-row-center-end">
          <img
            :src="require('@/assets/images/common/close-black.svg')"
            alt="close"
            class="close cursor-pointer"
            @click="$emit('close')"
          />
        </div>
        <div class="system-notify--wrapper--body flex-column-center-center">
          <div class="title">Ошибка!</div>
          <div class="text">{{ text }}</div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'SystemNotify',
  props: {
    text: {
      type: String,
      required: false,
      default: () => 'Неизвестная ошибка'
    },
    customClass: String
  },
  emits: ['close'],
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.system-notify {
  width: 100%;
  height: 100%;

  &.position-absolute {
    position: absolute !important;
    top: 0;
    background: #000000E5;
  }

  &--wrapper {
    margin: 120px 24px;
    padding: 20px;
    top: calc(50% - 120px);
    left: calc(50% - 24px);
    transform: translate(-50%,-50%);
    background: white;
    max-width: 700px;
    max-height: 340px;
    height: calc(100% - 240px);
    border-radius: 16px;

    &--header {
      .close {
        width: 16px;
        height: 16px;
      }
    }

    &--body {
      height: calc(100% - 16px);

      .title {
        font-family: GilroySemibold;
        font-size: 36px;
        line-height: 36px;
        color: $base-red;
        margin-bottom: 20px;
      }

      .text {
        font-family: GilroyRegular;
        font-size: 16px;
        line-height: 24px;
        color: $base-black;
      }
    }
  }
}

</style>
