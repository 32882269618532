<template>
  <div class="photo">
    <div class="wrapper w-100">
      <viewer :images="photos">
        <template
          v-for="(photoUrl, index) in photos"
          :key="index"
        >
          <img
            :src="photoUrl"
            alt="photo"
            class="image w-100 cursor-pointer"
            :class="{'d-none': index >= minPhotoCount}"
          />
        </template>
      </viewer>
    </div>
    <div
      class="more-photos flex-row-center"
      v-if="minPhotoCount < photosLength"
    >
      <show-more-button
        is-gray
        show-lines
        button-margin="20px"
        @click="minPhotoCount = minPhotoCount * 2"
      ></show-more-button>
    </div>
  </div>
</template>

<script>

import { computed, ref } from 'vue'
import ShowMoreButton from '@/components/ShowMoreButton.vue'

export default {
  name: 'TourDetailedCardBodyTabPhoto',
  components: {ShowMoreButton},
  props: {
    photos: Array
  },
  setup(props) {
    const minPhotoCount = ref(6)
    const photosLength = computed(() => props.photos.length)

    return {
      minPhotoCount,
      photosLength
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tab-content.photo {
  .wrapper {
    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
      column-gap: 20px;
      margin-bottom: 70px;

      .image {
        border-radius: 16px;
        height: 450px;
        object-fit: cover;
      }
    }
  }

  .more-photos {
    margin-bottom: 52px;
  }

  @media (max-width: 1679px) {
    .wrapper > div {
      grid-template-columns: auto;
      margin-bottom: 40px;
    }

    .more-photos {
      margin-bottom: 10px;
    }
  }
}

</style>
