<template>
  <div
    ref="hotel"
    class="hotel"
  >
    <div class="text text-left">{{ description }}</div>
    <div class="preview flex-column-start">
      <tour-detailed-card-body-tab-hotel-preview-image
        :photo-src="photos[currentPhotoIndex]"
        :photo-index="currentPhotoIndex"
        :photos-count="photos.length"
        @prev-image-click="onPrevImageClick"
        @next-image-click="onNextImageClick"
      ></tour-detailed-card-body-tab-hotel-preview-image>
      <div class="table w-100">
        <tour-detailed-card-body-tab-hotel-table-item
          v-for="(item, index) in tableItems"
          :key="index"
          :title="item.title"
          :description-items="item.descriptionItems"
          :is-note="!!item.isNote"
        ></tour-detailed-card-body-tab-hotel-table-item>
      </div>
      <div class="top-button-wrapper flex-row-center-center w-100">
        <div
          class="button top cursor-pointer"
          @click="scrollToTop"
        >Наверх</div>
      </div>
    </div>
    <div class="panel flex-column">
      <div class="rating flex-row">
        <div
          class="value"
          :class="getRatingColorClass()"
        >{{ formattedRating }}</div>
        <div class="flex-column-start-center">
          <div class="bold">{{ ratingText }}</div>
          <div class="rating-by-feedback">Оценка по
            <span
              class="text-underline cursor-pointer"
              @click="$emit('open-feedbacks-tab')"
            >отзывам</span></div>
        </div>
      </div>
      <tour-detailed-card-body-tab-hotel-photo-album
        :photos="photos"
        :current-photo-index="currentPhotoIndex"
        @image-clicked="currentPhotoIndex = $event"
      ></tour-detailed-card-body-tab-hotel-photo-album>
    </div>
  </div>
</template>

<script>

import {computed, onMounted, ref, watch} from 'vue'
import TourDetailedCardBodyTabHotelTableItem
  from '@/components/tour/tour-detailed-card/tabs/hotel/TourDetailedCardBodyTabHotelTableItem.vue'
import TourDetailedCardBodyTabHotelPhotoAlbum
  from '@/components/tour/tour-detailed-card/tabs/hotel/TourDetailedCardBodyTabHotelPhotoAlbum.vue'
import TourDetailedCardBodyTabHotelPreviewImage
  from '@/components/tour/tour-detailed-card/tabs/hotel/TourDetailedCardBodyTabHotelPreviewImage.vue'
import { determineRatingColorClass, determineRatingText } from '@/composables/rating'

export default {
  name: 'TourDetailedCardBodyTabHotel',
  emits: ['open-feedbacks-tab'],
  components: {
    TourDetailedCardBodyTabHotelPreviewImage,
    TourDetailedCardBodyTabHotelPhotoAlbum,
    TourDetailedCardBodyTabHotelTableItem
  },
  props: {
    info: Object
  },
  setup(props) {
    const description = ref(null)
    const formattedRating = ref(null)
    const placement = ref(null)
    const phone = ref(null)
    const site = ref(null)
    const territory = ref([])
    const freeServices = ref([])
    const payServices = ref([])
    const servicesForKids = ref([])
    const meal = ref([])
    const servicesInRoom = ref([])
    const roomTypes = ref([])
    const note = ref(null)
    const photos = ref([])
    const tableItems = ref([])
    const currentPhotoIndex = ref(0)
    const hotel = ref(null)
    const ratingText = ref(null)
    const rating = computed(() => props.info && props.info.rating ? props.info.rating : 0)

    onMounted(() => initData())

    const initData = () => {
      description.value = props.info && props.info.description ? props.info.description : null
      formattedRating.value = props.info && props.info.rating
        ? (Number.isInteger(props.info.rating) ? `${props.info.rating}.0` : props.info.rating)
        : 0.0
      placement.value = props.info && props.info.placement ? props.info.placement : null
      phone.value = props.info && props.info.phone ? props.info.phone : null
      site.value = props.info && props.info.site ? props.info.site : null
      territory.value = props.info && props.info.territory ? props.info.territory : []
      freeServices.value = props.info && props.info.freeServices ? props.info.freeServices : []
      payServices.value = props.info && props.info.payServices ? props.info.payServices : []
      servicesForKids.value = props.info && props.info.servicesForKids ? props.info.servicesForKids : []
      meal.value = props.info && props.info.meal ? props.info.meal : []
      servicesInRoom.value = props.info && props.info.servicesInRoom ? props.info.servicesInRoom : []
      roomTypes.value = props.info && props.info.roomTypes ? props.info.roomTypes : []
      note.value = props.info && props.info.note ? props.info.note : null
      photos.value = props.info && props.info.photos ? props.info.photos : []
      tableItems.value = [
        {
          title: 'Общая информация',
          descriptionItems: [
            {title: 'Расположение', text: placement.value},
            {title: 'Телефон', text: phone.value},
            {title: 'Сайт', text: site.value}
          ]
        },
        {title: 'Территория отеля', descriptionItems: territory.value.map(i => ({text: i}))},
        {title: 'Бесплатные услуги', descriptionItems: freeServices.value.map(i => ({text: i}))},
        {title: 'Платные услуги', descriptionItems: payServices.value.map(i => ({text: i}))},
        {title: 'Для детей', descriptionItems: servicesForKids.value.map(i => ({text: i}))},
        {title: 'Питание', descriptionItems: meal.value.map(i => ({text: i}))},
        {title: 'В номере', descriptionItems: servicesInRoom.value.map(i => ({text: i}))},
        {title: 'Типы номеров', descriptionItems: roomTypes.value.map(i => ({text: i}))},
        {title: 'Примечание', descriptionItems: [{text: note}], isNote: true},
      ]
      ratingText.value = getRatingText()
    }
    const onPrevImageClick = () => {
      if (currentPhotoIndex.value && currentPhotoIndex.value < photos.value.length) {
        currentPhotoIndex.value -= 1
      } else {
        currentPhotoIndex.value = photos.value.length - 1
      }
    }
    const onNextImageClick = () => {
      if (currentPhotoIndex.value < photos.value.length - 1) {
        currentPhotoIndex.value += 1
      } else {
        currentPhotoIndex.value = 0
      }
    }
    const scrollToTop = () => {
      const scrollingParent = hotel.value.closest('.tour-detailed-card--form--body')
      if (scrollingParent) {
        scrollingParent.scrollTo({behavior: 'smooth' , top: 0})
      }
    }
    const getRatingText = () => determineRatingText(rating.value)
    const getRatingColorClass = () => determineRatingColorClass(rating.value)

    watch(() => props.info, () => initData())

    return {
      hotel,
      description,
      formattedRating,
      ratingText,
      placement,
      phone,
      site,
      territory,
      freeServices,
      payServices,
      servicesForKids,
      meal,
      servicesInRoom,
      roomTypes,
      note,
      photos,
      tableItems,
      currentPhotoIndex,

      onPrevImageClick,
      onNextImageClick,
      scrollToTop,
      getRatingColorClass
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tab-content.hotel {
  display: grid;
  grid-template-columns: auto 335px;
  column-gap: 30px;

  .text {
    grid-column: 1 / span 2;
    font-family: GilroyRegular;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin-bottom: 30px;
  }

  .preview {
    .table {
      margin-top: 46px;
    }

    .top-button-wrapper {
      grid-column: 1 / span 2;

      .button.top {
        width: 220px;
        margin-top: 40px;
        border-radius: 50px;
        background: #FFF5EB;
        color: #666666;
        text-transform: uppercase;
        padding: 15px 0;
        font-family: GilroyMedium;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .panel {
    width: 335px;

    .rating {
      .value {
        width: 105px;
        padding: 22px 0 20px 0;
        border-radius: 16px;
        font-family: GilroyBold;
        font-size: 28px;
        line-height: 28px;
        color: $base-white;

        &.green {
          background: $rating-green;
        }

        &.yellow {
          background: $rating-yellow;
        }

        &.orange {
          background: $rating-orange;
        }

        &.red {
          background: $rating-red;
        }
      }

      >div:last-child {
        margin-left: 17px;

        .bold {
          font-family: GilroyBold;
          font-size: 20px;
          line-height: 20px;
          color: $base-black;
          margin-bottom: 5px;
        }

        .rating-by-feedback {
          font-family: GilroyRegular;
          font-size: 14px;
          line-height: 14px;
          color: #666666;

          span {
            color: #FF8500;
          }
        }
      }
    }
  }

  @media (max-width: 1679px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;

    .text {
      font-size: 14px;
      line-height: 21px;
    }

    .preview {
      .table {
        margin-top: 30px;
      }

      .button.top {
        margin-bottom: 20px;
      }
    }

    .panel {
      width: 100%;
      background: #F8F8F8;
      margin-left: 0;
      grid-row-start: 1;
      border-radius: 16px;
      margin-bottom: 30px;

      .rating {
        display: flex;
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;

    .text {
      display: none;
    }

    .preview {
      .table {
        margin-top: 40px;
      }

      .button.top {
        margin-top: 30px;
      }
    }

    .panel {
      margin-bottom: 50px;
      background: unset;

      .rating {
        background: #F8F8F8;
        border-radius: 16px;
      }
    }
  }
}

</style>
