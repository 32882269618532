<template>
  <div
      id="actual-tours"
      class="actual-tours--title flex-row-center"
      style="justify-content: center"
  >
    <div class="actual-tours--title--text">Популярные горящие туры</div>
  </div>
  <div class="actual-tours--list">
    <div
        v-for="(item, index) in hotTours"
        :key="index"
        class="actual-tours--item cursor-pointer"
        @click="handleClickHotTour(item)"
    >
      <img
          :src="getUrlImageByCountryCode(item.countryCode)"
          class="actual-tours--item--image"
          :alt="item.country"
      >
      <div class="actual-tours--item--country">
        {{ item.country }}
      </div>
      <div class="flex-row-center" style="padding-top: 16px">
        <div class="actual-tours--item--date">
          <div>{{ moment(item.date, 'DD.MM.YYYY').format('D MMMM') }}</div>
        </div>
        <div class="actual-tours--item--nights">
          <div>{{ plural.noun(item.nightsCount, '%d ночь', '%d ночи', '%d ночей') }}</div>
        </div>
        <div class="actual-tours--item--visa">
          <div>без визы</div>
        </div>
      </div>

      <div class="actual-tours--item--price flex-row-center">
        <img
            :src="require('@/assets/images/common/flame.svg')"
            style="padding-right: 8px"
            alt="flame"
        />
        {{ 'от ' + formatAmount(item.price) }}
        <span class="currency"
              style="padding-left: 4px; padding-top: 2px">
          ₽
        </span>
      </div>
    </div>

  </div>
  <div
      v-if="hotToursItemsCount === showHotToursItemsCount"
      class="actual-tours--divider"
  >
    <div
        class="actual-tours--divider--placeholder"
        @click="onClickShowMoreHotTours"
    >
      <div class="flex-row-center search-wrapper">
        <img
            :src="require('@/assets/images/common/search.svg')"
            alt="search"
        />
        <span class="actual-tours--divider--link cursor-pointer">Поиск горящих туров</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as plural from 'plural-ru'
import {computed, ref, watch} from 'vue'
import {tourStore} from '@/stores/tourStore'
import {formatAmount} from '@/composables/priceFormatter'
import router from '@/router';
import {scrollIntoView} from '@/composables/scroll'
import {goToSearchHotTourPage} from '@/composables/router'
import {default as imagesList} from '@/assets/tour-images'

export default {
  name: 'MainPageActualTours',
  components: {
    // TourDetailedCard
  },
  props: {
    departureCity: {
      type: Number,
      required: true,
    },
    departureCityFrom: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    // const commonStoreInstance = commonStore()
    const showHotToursItemsCountByDefault = 8
    const showHotToursItemsCount = ref(showHotToursItemsCountByDefault)
    const hotTours = ref([])
    const city = ref(null)
    const hotToursItemsCount = computed(() => hotTours.value.length)

    const getUrlImageByCountryCode = (code) => {
      if (code in imagesList) return imagesList[code]
      return null
    }

    const searchHotTours = () => {
      const queryParams = {...router.currentRoute.value.query}
      router.replace({query: null})
      tourStore().getHotTours(city.value)
          .then(({data}) => {
            hotTours.value = data
            if (queryParams) {
              if (queryParams.scrollToElementId) {
                setTimeout(() => scrollIntoView(queryParams.scrollToElementId), 200)
              }
            }
          })
          .catch(() => {
            if (queryParams) {
              if (queryParams.scrollToElementId) {
                scrollIntoView(queryParams.scrollToElementId)
              }
            }
          })
    }
    const onClickShowMoreHotTours = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      goToSearchHotTourPage()
    }
    // const onDetailedTourCardModalHide = () => {
    //   hotTourId.value = null
    //   hotTourOperatorImage.value = null
    //   commonStoreInstance.changeModalOverlayVisibility(false, true)
    // }
    const handleClickHotTour = item => {
      // hotTourId.value = item.tourId ?? null
      // hotTourOperatorImage.value = item.operatorImage ?? null
      tourStore().setSelectedCountryIdForSearch(item.countryCode)
      goToSearchHotTourPage(null, true)
    }

    watch(
        () => props.departureCity,
        departureCity => {
          city.value = departureCity
          searchHotTours()
        },
        {
          immediate: true,
        }
    )

    return {
      plural,
      hotTours,
      hotToursItemsCount,
      showHotToursItemsCount,
      getUrlImageByCountryCode,
      formatAmount,
      moment,
      onClickShowMoreHotTours,
      // onDetailedTourCardModalHide,
      handleClickHotTour
    }
  },
}
</script>

<style scoped lang="scss">

@import '@/assets/css/variables';

.search-wrapper {
  background-color: #FF8500;
  border-radius: 50px;
  gap: 16px;
  padding: 0 32px;
}

.actual-tours {

  &--title {
    margin-bottom: 30px;

    &--image {
      width: 30px;
      height: 36px;
      margin-right: 10px;
      background-image: url('@/assets/images/common/flame.svg');
      background-size: cover;
    }

    &--text {
      font-family: GilroyBold;
      font-size: 36px;
      line-height: 54px;
      color: $base-black;

      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 42px;
      }
    }
  }

  &--list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media screen and (max-width: 1679px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }

  &--item {
    position: relative;
    padding: 15px 15px 20px;
    text-align: left;
    border-radius: 16px;
    transition: 0.3s background-color ease-in-out;

    &:hover {
      background-color: #ffffff;
    }

    &--image {
      width: 100%;
      height: auto;
      border-radius: 16px;
    }

    &--country {
      margin-top: 20px;
      font-family: GilroyBold;
      font-size: 16px;
      color: #222222;
      line-height: 1;
    }

    &--city {
      margin-top: 7px;
      font-family: GilroyMedium;
      font-size: 14px;
      color: #888888;
      line-height: 1;
    }

    &--date {
      div {
        padding: 7px 7px 7px 28px;
        display: inline-block;
        font-family: GilroyMedium;
        font-size: 14px;
        color: #222222;
        line-height: 1;
        background: url('@/assets/images/common/icon-calendar.svg') 7px center no-repeat;
      }
    }

    &--visa {
      padding: 7px 7px 7px 28px;
      display: inline-block;
      font-family: GilroyMedium;
      font-size: 14px;
      color: #222222;
      line-height: 1;
      background: url('@/assets/images/common/card.svg') 7px center no-repeat;
    }

    &--nights {
      //margin-top: 6px;

      div {
        padding: 7px 7px 7px 28px;
        display: inline-block;
        font-family: GilroyMedium;
        font-size: 14px;
        color: #222222;
        line-height: 1;
        background: url('@/assets/images/common/icon-moon.svg') 7px center no-repeat;
      }
    }

    &--price {
      padding-top: 16px;
      font-family: GilroyBold;
      font-size: 16px;
      line-height: 1;

      .currency {
        font-family: GilroyMedium;
        font-size: 14px;
      }
    }

    //&:not(:first-child) {
    //  margin-top: 20px;
    //}
  }

  &--divider {
    margin: 62px 0 22px;
    position: relative;
    height: 0;
    text-align: center;
    border-top: 1px solid #e0e0e0;

    &--placeholder {
      position: relative;
      top: -22px;
      padding: 0 20px;
      display: inline-block;
      background: #f8f8f8;
    }

    &--link {
      padding: 15px 0;
      display: block;
      text-align: center;
      font-family: GilroyMedium;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 1;
      border-radius: 50px;
      box-sizing: border-box;
    }
  }
}
</style>
