<template>
  <div class="show-more flex-row-center-center w-100">
    <hr v-if="showLines">
    <div
      class="show-more--button cursor-pointer"
      :class="{
        'gray': isGray
      }"
      :style="{
        'margin-left': showLines ? buttonMargin : null,
        'margin-right': showLines ? buttonMargin : null
      }"
      @click.stop.prevent="$emit('click')"
    >Показать еще</div>
    <hr v-if="showLines">
  </div>
</template>

<script>


export default {
  name: 'ShowMoreButton',
  props: {
    isGray: Boolean,
    showLines: Boolean,
    buttonMargin: {
      type: String,
      required: false,
      default: () => '10px'
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.show-more {
  &--button {
    width: 160px;
    min-width: 160px;
    padding: 15px 0;
    border-radius: 50px;
    font-family: GilroySemibold;
    font-size: 14px;
    line-height: 14px;
    color: $base-black;
    background: $base-white;

    &.gray {
      background: $base-gray;
    }
  }

  hr {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 1px;
    border: 0;
    background: #E0E0E0;

    @media (max-width: 1679px) {
      display: flex;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    display: flex;
  }
}

</style>
