<template>
  <div
    class="table--item"
    :class="{'cursor-pointer': !isNote}"
    @click="handleClickShowMore"
  >
    <div class="table--item--column title">{{ title }}</div>
    <div
      class="table--item--column description"
      :class="{'active': showMoreInfo}"
      @click.stop.prevent
    >
      <template v-if="showMoreInfo">
        <div
          v-for="(item, index) in descriptionItems"
          :key="index"
          class="description-item"
        >
          <template v-if="item.title">
            <span class="title">{{ item.title }}: </span>
            <span class="text-left">{{ item.text }}</span>
          </template>
          <template v-else>
            <img
              v-if="!isNote"
              :src="require('@/assets/images/common/check-green.svg')"
              :alt="item.text"
            />
            <span
              class="text-left"
              :class="{'note': isNote}"
            >{{ item.text }}</span>
          </template>
        </div>
      </template>
      <div v-else>&nbsp;</div>
    </div>
    <div class="table--item--column toggle">
      <circle-icon
        v-if="!isNote"
        :src="require(`@/assets/images/common/${showMoreInfo ? 'arrow-up' : 'arrow-down-gray'}.svg`)"
        icon-size="14px"
        size="40px"
        background-variant="gray"
      ></circle-icon>
    </div>
  </div>
</template>

<script>

import CircleIcon from '@/components/icon/CircleIcon.vue'
import { ref } from 'vue'

export default {
  name: 'TourDetailedCardBodyTabHotelTableItem',
  components: {
    CircleIcon
  },
  props: {
    title: String,
    descriptionItems: Array,
    isNote: Boolean
  },
  setup(props) {
    const showMoreInfo = ref(false)

    const handleClickShowMore = () => {
      if (!props.isNote) {
        showMoreInfo.value = !showMoreInfo.value
      }
    }

    return {
      showMoreInfo,

      handleClickShowMore
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.table--item {
  border-top: 1px solid #E0E0E0;
  display: flex;

  &--column {
    width: 100%;
    margin-top: 28px;
    margin-bottom: 15px;

    &.title {
      max-width: 375px;
      text-align: left;
      font-family: GilroyBold;
      font-size: 16px;
      line-height: 16px;
      color: $base-black;
      text-transform: uppercase;
    }

    &.description {
      display: flex;
      flex-direction: column;
      cursor: default;

      &.active {
        padding-bottom: 13px;
      }

      .description-item {
        display: flex;
        align-items: flex-start;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        img {
          margin-right: 6px;
        }

        span {
          font-size: 14px;
          line-height: 18.5px;
          font-family: GilroyRegular;

          &.title {
            color: #666666;
            font-family: GilroyBold;
            margin-right: 5px;
          }

          &.text-left {
            padding-right: 10px;
          }
        }
      }

      .note {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }
    }

    &.toggle {
      width: 40px;
      margin-top: 15px;
    }
  }

  @media (max-width: 1679px) {
    display: grid;
    grid-template-columns: auto 40px;
    grid-template-rows: auto auto;

    &--column {
      margin-top: 28px;
      margin-bottom: 0;

      &.description {
        display: none;

        &.active {
          display: block;
          grid-row-start: 2;
          margin-top: 0;
          padding-bottom: 28px;
        }

        .note {
          margin-top: 11px;
        }
      }

      &.toggle {
        margin-top: 15px;
        margin-bottom: 15px;
        grid-row-start: 1;
        grid-column: 2 / span 1;
      }
    }
  }

  @media (max-width: 767px) {
    .text-left {
      padding-right: 0;
    }
  }
}

</style>
