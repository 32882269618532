import { defineStore } from 'pinia'
import { authStore } from '@/stores/authStore'
import router from '@/router'
import { goToAccountPage } from '@/composables/router'

const moduleName = 'account-page'

export const accountPageStore = defineStore(moduleName, {
  state: () => ({
    menu: [
      {label: 'Мой профиль', type: 'profile', active: true, hovered: false},
      {label: 'Мои туры', type: 'tours', active: false, hovered: false},
      {label: 'Мои подборки', type: 'favorites', active: false, hovered: false},
      {label: 'Уведомления', type: 'notifications', active: false, hovered: false},
      {label: 'Поддержка', type: 'support', active: false, hovered: false},
      {label: 'Выйти', type: 'logout', active: false, hovered: false},
    ],
  }),
  getters: {
    accountMenu: state => state.menu,
  },
  actions: {
    selectMenuItem(item, redirectToAccount = true) {
      const authStoreInstance = authStore()
      if (!item.active && item.type !== 'logout') {
        this.menu.map(i => i.active = i.type === item.type)
        if (router.currentRoute.value.name !== 'account' && redirectToAccount) {
          goToAccountPage()
        }
      } else if (item.type === 'logout') {
        authStoreInstance.logoutUser()
      }
    }
  },
})
