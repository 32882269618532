<template>
  <div class="google-map-wrapper">
    <GoogleMap
      :api-key="googleMapsApiKey"
      :center="center"
      :zoom="zoom"
      class="google-map-wrapper__map"
    >
      <Marker :options="{ position: center }"></Marker>
    </GoogleMap>
  </div>
</template>

<script>

import { GoogleMap, Marker } from 'vue3-google-map'

export default {
  name: 'GoogleMapComponent',
  components: {
    GoogleMap, Marker
  },
  props: {
    lat: Number,
    lng: Number,
  },
  // emits: ['close']
  setup(props) {
    const center = {
      lat: props.lat,
      lng: props.lng
    }
    const zoom = 19
    const googleMapsApiKey = 'AIzaSyBgS1U1HUzyLOnlbkNRmkpxEpOSv9VLjI0'

    return {
      center,
      zoom,
      googleMapsApiKey
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.google-map-wrapper {
  &__map {
    width: 100%;
    height: 100%;
  }
}

</style>
