<template>
  <div
      class="search-form--sidebar"
      :class="[
      {'active': filterSwitcherIsActive},
      {'located-on-main-page': locatedOnMainPage},
      searchCategory
    ]"
  >
    <div class="field-wrapper field-bordered stars-wrapper">
      Класс отеля
      <div class="stars">
        <rating-star
            :rating="fields.hotelStars"
            hoverable
            :not-filled-white-stars="locatedOnMainPage"
            @star-click="handleChangeHotelStars"
        ></rating-star>
      </div>
    </div>
    <div
        v-if="showHotelTypeFilter"
        class="field-wrapper field-bordered field-dropdown"
    >
      Тип отеля
      <button @click="handleClickBlockView('hotelTypes')"></button>
      <main-page-search-block-list
          v-if="visibleBlocks.hotelTypes"
          id="hotelType"
          :model="fields.hotelType"
          title="Тип отеля"
          :options="hotelTypes"
          @save="handleChangeHotelTypes"
          v-click-outside="handleClickOutside"
      ></main-page-search-block-list>
    </div>
    <div
        class="field-wrapper field-bordered field-dropdown meals-wrapper"
        :class="{'has-value': !!fields.meal}"
    >
      <div class="label-wrapper" @click="handleClickBlockView('meals')">
        <label class="text-left">Питание</label>
        <button class="text-left">{{ selectedMealLabel }}</button>
      </div>
      <circle-icon
          size="40px"
          icon-size="12px"
          :src="require('@/assets/images/common/close-black.svg')"
          class="clear button"
          @click="handleClickClearBlock('meal')"
      ></circle-icon>
      <main-page-search-block-list
          v-if="visibleBlocks.meals"
          id="meal"
          :model="fields.meal"
          title="Питание"
          :options="meals"
          option-value="russianFull"
          option-any="Любое"
          @save="handleChangeMeals"
          class="choose-block"
          :use-confirm-button="false"
          v-click-outside="handleClickOutside"
      ></main-page-search-block-list>
    </div>
    <div
        class="field-wrapper field-bordered field-dropdown rating-wrapper"
        :class="{'has-value': !!fields.rating}"
    >
      <div class="label-wrapper" @click="handleClickBlockView('ratings')">
        <label class="text-left">Средняя оценка</label>
        <button class="text-left">{{ selectedRatingLabel }}</button>
      </div>
      <circle-icon
          size="40px"
          icon-size="12px"
          :src="require('@/assets/images/common/close-black.svg')"
          class="clear button"
          @click="handleClickClearBlock('rating')"
      ></circle-icon>
      <main-page-search-block-list
          v-if="visibleBlocks.ratings"
          id="rating"
          :model="fields.rating"
          title="Средняя оценка"
          :options="ratings"
          option-any="Любой"
          :use-confirm-button="false"
          @save="handleChangeRatings"
          v-click-outside="handleClickOutside"
      ></main-page-search-block-list>
    </div>
    <div
        v-if="['hotels', 'tours'].includes(searchCategory)"
        class="field-wrapper field-bordered field-dropdown services-wrapper"
        :class="{'has-value': fields.services && fields.services.length}"
    >
      <div class="label-wrapper" @click="handleClickBlockView('services')">
        <label class="text-left">Услуги отеля</label>
        <button class="text-left">{{ selectedServicesLabel }}</button>
      </div>
      <circle-icon
          size="40px"
          icon-size="12px"
          :src="require('@/assets/images/common/close-black.svg')"
          class="clear button"
          @click="handleClickClearBlock('services')"
      ></circle-icon>
      <main-page-search-block-list
          v-if="visibleBlocks.services"
          id="service"
          :model="fields.services"
          multiple
          title="Услуги отеля"
          :options="services"
          groups
          option-value="name"
          @save="handleChangeServices"
          v-click-outside="handleClickOutside"
      ></main-page-search-block-list>
    </div>
    <div
        v-if="['hotels', 'tours'].includes(searchCategory)"
        class="field-wrapper field-bordered field-dropdown budget-wrapper"
        :class="{'has-value': fields.budget && (fields.budget.from || fields.budget.from === 0) && fields.budget.to}"
    >
      <div class="label-wrapper" @click="handleClickBlockView('budget')">
        <label class="text-left">Бюджет</label>
        <button class="text-left">
          {{
            fields.budget && (fields.budget.from || fields.budget.from === 0) && fields.budget.to
                ? `${fields.budget.from} - ${fields.budget.to} (RUB)`
                : '...'
          }}
        </button>
      </div>
      <circle-icon
          size="40px"
          icon-size="12px"
          :src="require('@/assets/images/common/close-black.svg')"
          class="clear button"
          @click="handleClickClearBlock('budget')"
      ></circle-icon>
      <main-page-search-block-budget
          v-if="visibleBlocks.budget"
          :budget="fields.budget"
          @change-budget="handleChangeBudget"
          v-click-outside="handleClickOutside"
      ></main-page-search-block-budget>
    </div>

    <check-box-field-component
        v-if="false"
        id="only-charter"
        :value="fields.onlyCharter"
        label="Только чартер"
    ></check-box-field-component>

    <check-box-field-component
        v-if="false"
        id="hotel-places-guarantee"
        :value="fields.hotelPlacesGuarantee"
        label="Гарантия мест в отеле"
        class="hotel-places-guarantee-wrapper"
        @update:value="handleChangeHotelPlacesGuarantee"
    ></check-box-field-component>

    <div
        v-if="(showSearchButton && filterSwitcherIsActive) || showSearchButtonAlways"
        class="field-wrapper search-button-wrapper"
    >
      <button
          class="search-button cursor-pointer"
          @click="$emit('search')"
      >{{ searchButtonText }}
      </button>
    </div>
    <div
        v-if="showClearFiltersButton && !filterSwitcherIsActive"
        class="field-wrapper clear-filters-wrapper cursor-pointer"
        @click="handleClickClearAllFilters"
    >
      <img
          :src="require('@/assets/images/common/close-orange.svg')"
          alt="clear filters"
      />
      <span>Сбросить все фильтры</span>
    </div>
  </div>
</template>

<script>


import RatingStar from '@/components/RatingStar.vue'
import MainPageSearchBlockBudget from '@/pages/main-page/MainPageSearchBlockBudget.vue'
import MainPageSearchBlockList from '@/pages/main-page/MainPageSearchBlockList.vue'
import {computed, onMounted, ref} from 'vue'
import CheckBoxFieldComponent from '@/components/forms/CheckBoxFieldComponent.vue'
import {dictionariesStore} from '@/stores/dictionariesStore'
import {tourStore} from '@/stores/tourStore'
import vClickOutside from 'click-outside-vue3'
import CircleIcon from '@/components/icon/CircleIcon.vue'

export default {
  name: 'SearchFormHeaderBottomComponent',
  emits: ['search', 'filters-changed'],
  props: {
    searchCategory: String,
    showSearchButton: Boolean,
    showSearchButtonAlways: Boolean,
    searchButtonText: String,
    showClearFiltersButton: Boolean,
    showHotelTypeFilter: Boolean,
    filterSwitcherIsActive: Boolean,
    locatedOnMainPage: Boolean
  },
  components: {
    CircleIcon, CheckBoxFieldComponent, MainPageSearchBlockList, MainPageSearchBlockBudget, RatingStar
  },
  setup(props, {emit}) {
    const dictionariesStoreInstance = dictionariesStore()
    const defaultValues = {
      hotelStars: 1,
      meal: null,
      hotelType: null,
      rating: null,
      services: [],
      budget: {
        from: 0,
        to: 1000000,
        currency: 'RUB',
      },
      onlyCharter: false,
      hotelPlacesGuarantee: false,
    }
    const fields = ref({})
    const visibleBlocks = ref({
      meals: false,
      ratings: false,
      services: false,
      budget: false,
      hotelTypes: false,
    })
    const meals = ref(new Map())
    const ratings = ref(new Map([
      [3, '<span>3,0</span>&nbsp;и более'],
      [3.5, '<span>3,5</span>&nbsp;и более'],
      [4, '<span>4,0</span>&nbsp;и более'],
      // [4.5, '<span>4,5</span>&nbsp;и более'],
      // [5, '<span>5</span>'],
    ]))
    const services = ref(new Map())
    const hotelTypes = ref(new Map())
    const tourStoreInstance = tourStore()
    const currentSearchFilters = computed(() => tourStoreInstance.currentSearchFilters)
    const selectedMealLabel = computed(() => {
      let label = 'Любое'
      if (fields.value.meal) {
        for (const item of meals.value) {
          if (item[0] === fields.value.meal) {
            label = item[1].russianFull.replace('<span>', '').replace('</span>', '').replace('&nbsp;', ' ')
          }
        }
      }
      return label
    })
    const selectedServicesLabel = computed(() => {
      let label = '...'
      if (fields.value.services && fields.value.services.length) {
        const labels = []
        for (const group of services.value) {
          for (const item of group[1]) {
            if (fields.value.services.includes(item[0])) {
              labels.push(item[1].name)
            }
          }
        }
        label = labels.join(', ')
      }
      return label
    })
    const selectedRatingLabel = computed(() => {
      let label = 'Любой'
      if (fields.value.rating) {
        label = fields.value.rating === 5 ? 5 : `${fields.value.rating}  и более`
      }
      return label
    })

    const handleClickBlockView = blockName => Object.keys(visibleBlocks.value).map(
        key => visibleBlocks.value[key] = key === blockName ? !visibleBlocks.value[key] : false
    )
    const handleChangeHotelStars = star => {
      fields.value.hotelStars = star
      emitFiltersChanged()
    }
    const handleChangeMeals = value => {
      visibleBlocks.value.meals = false
      fields.value.meal = value
      emitFiltersChanged()
    }
    const handleChangeHotelTypes = value => {
      visibleBlocks.value.hotelTypes = false
      fields.value.hotelType = value
      emitFiltersChanged()
    }
    const handleChangeRatings = value => {
      visibleBlocks.value.ratings = false
      fields.value.rating = value
      emitFiltersChanged()
    }
    const handleChangeServices = value => {
      visibleBlocks.value.services = false
      fields.value.services = value
      emitFiltersChanged()
    }
    const handleChangeBudget = value => {
      visibleBlocks.value.budget = false
      fields.value.budget = value
      emitFiltersChanged()
    }
    const handleChangeHotelPlacesGuarantee = value => {
      fields.value.hotelPlacesGuarantee = value
      emitFiltersChanged()
    }
    const emitFiltersChanged = () => emit('filters-changed', fields.value)
    const handleClickOutside = () => Object.keys(visibleBlocks.value).map(block => visibleBlocks.value[block] = false)
    const handleClickClearAllFilters = () => {
      setDefaultFieldsValues()
      tourStoreInstance.setClearFiltersActiveValue(true)
    }
    const setDefaultFieldsValues = () => fields.value = {...defaultValues, budget: {...defaultValues.budget}}
    const handleClickClearBlock = fieldKey => {
      if (fieldKey === 'budget') {
        fields.value[fieldKey].from = null
        fields.value[fieldKey].to = null
      } else {
        fields.value[fieldKey] = defaultValues[fieldKey]
      }
      emitFiltersChanged()
    }

    onMounted(() => {
      setDefaultFieldsValues()

      dictionariesStoreInstance.getMeal()
          .then(({data}) => {
            data.forEach((item) => {
              item.russianFull = item.russianFull.replace(/(.*?)-/, '<span>$1</span>&nbsp;-')
              meals.value.set(item.id, item)
            })
          })

      dictionariesStoreInstance.getServices()
          .then(({data}) => {
            data.forEach((group) => {
              const items = new Map()
              group.items.forEach((item) => {
                items.set(item.id, item)
              })
              services.value.set(group.groupName, items)
            })
          })

      if (currentSearchFilters.value && currentSearchFilters.value.bottomBlockFilters) {
        fields.value = {...currentSearchFilters.value.bottomBlockFilters}
        tourStoreInstance.clearCurrentSearchFilters('bottom')
        emitFiltersChanged()
      } else {
        emitFiltersChanged()
      }
    })

    return {
      fields,
      visibleBlocks,
      meals,
      ratings,
      services,
      hotelTypes,
      selectedMealLabel,
      selectedServicesLabel,
      selectedRatingLabel,

      handleChangeHotelStars,
      handleClickBlockView,
      handleChangeMeals,
      handleChangeRatings,
      handleChangeServices,
      handleChangeBudget,
      handleChangeHotelTypes,
      handleChangeHotelPlacesGuarantee,
      handleClickOutside,
      handleClickClearAllFilters,
      handleClickClearBlock
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.search-form--sidebar {
  display: grid;
  margin-top: 40px;

  .field-wrapper {
    position: relative;
    font-family: GilroyMedium;
    font-size: 14px;
    line-height: normal;
    border: 1px solid transparent;
    border-radius: 6px;
    box-sizing: border-box;
    flex: 1 0 1px;
    padding: 10px 32px 10px 13px;
    display: flex;
    align-items: center;
    height: 60px;
    color: #222222;

    @media screen and (max-width: 1679px) {
      flex-basis: 40%;
    }

    @media screen and (max-width: 767px) {
      flex-basis: unset;
      padding-right: 28px;
      width: 100%;

      &.stars-wrapper {
        margin: 8px 0 !important;
      }

      &.meals-wrapper, &.rating-wrapper, &.services-wrapper, &.budget-wrapper {
        grid-column: span 4 !important;
      }
    }

    + .field-wrapper {
      position: relative;

      &:not(.budget-wrapper) {
        margin-left: 20px;
      }

      @media screen and (max-width: 1679px) {
        margin-left: 30px;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    &.stars-wrapper, &.checkbox-wrapper, &.search-button-wrapper {
      @media screen and (max-width: 1679px) {
        grid-column: span 4;
      }
    }

    &.meals-wrapper, &.services-wrapper, &.checkbox-wrapper {
      @media screen and (max-width: 1679px) {
        margin-left: 0 !important;
      }
    }

    &.rating-wrapper {
      @media screen and (max-width: 1679px) {
        margin-left: 30px !important;
      }

      @media screen and (max-width: 767px) {
        margin-left: 0 !important;
      }
    }

    &.checkbox-wrapper {
      padding-left: 0;

      @media screen and (max-width: 1679px) {
        margin: 5px 0 0 0;
        padding: 0;
        height: auto;

        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }

    &.field-bordered {
      background: #ffffff;
      border-color: #eaeaea;
    }

    &.field-dropdown {
      position: relative;

      &:after {
        content: '';
        position: absolute !important;
        right: 15px;
        display: block;
        width: 14px;
        height: 14px;
        background: url('@/assets/images/common/arrow-down-gray.svg');
      }

      button {
        padding: 0;
        position: absolute !important;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    &.clear-filters-wrapper {
      padding: 0;
      margin: 0;
      height: auto;
      border: unset;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 17px;
        color: #888888;
      }
    }

    &.search-button-wrapper {
      padding: 0;
      margin: 0;

      @media screen and (max-width: 1679px) {
        margin-top: 46px !important;
        margin-left: 0 !important;
        padding: 0;

        &:not(:first-child) {
          margin-top: 15px;
          justify-content: center;

          .search-button {
            width: 264px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        margin-top: 30px !important;
      }

      .search-button {
        height: 100%;
        width: 100%;
        text-align: center;
        font-family: GilroySemibold;
        font-size: 14px;
        line-height: 14px;
        color: #ffffff;
        background: linear-gradient(225deg, #ff8500 0%, #ff4800 100%);
        border: none;
        border-radius: 6px;
      }
    }

    &.meals-wrapper, &.rating-wrapper, &.services-wrapper, &.budget-wrapper {
      padding: 15px 20px;
      height: auto;

      .choose-block {
        top: 90px;
      }

      .label-wrapper {
        width: 100%;
        display: grid;
        flex-flow: column nowrap;
        cursor: pointer;

        label {
          cursor: pointer;
          color: #888888;
          margin-bottom: 10px;
        }

        button {
          position: relative !important;
          padding-right: 15px;
          font-family: GilroySemibold;
          font-size: 16px;
          color: $base-black;
          line-height: 20px;
        }
      }

      .clear {
        margin-right: -5px;
        display: none;
      }

      &.has-value {
        &:after {
          display: none;
        }

        .clear {
          display: flex;
        }
      }
    }

    .stars {
      margin-left: 28px;

      :deep(.rating--star.filled) {
        background-image: url('@/assets/images/common/star-rating-filled2.svg');
      }
    }
  }

  .field-wrapper:first-child {
    @media screen and (max-width: 1679px) {
      flex-basis: 100%;
      margin: 8px 0;
      padding: 0;
      background: none;
      border: none;
    }

    @media screen and (max-width: 767px) {
      margin: 30px 0;
    }
  }

  .field-wrapper:nth-child(2n + 2) {
    @media screen and (max-width: 1679px) {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 1680px) {
    &.located-on-main-page {
      .field-wrapper {
        position: relative;

        &.stars-wrapper, &.search-button-wrapper {
          height: 80px;
        }
      }
    }
  }

  @media screen and (max-width: 1679px) {
    margin-top: 0;
    flex-wrap: wrap;
    row-gap: 0 !important;

    &.active {
      padding: 0 20px;
      margin-bottom: 30px;
      column-gap: 30px;

      .field-wrapper {
        position: relative;

        &.stars-wrapper {
          margin: 0;
          height: 76px;
        }

        &.field-dropdown {
          grid-column: span 2;
          margin-bottom: 30px;
          margin-left: 0 !important;

          @media screen and (max-width: 767px) {
            grid-column: span 4;
            margin-bottom: 20px;
          }
        }

        &.checkbox-wrapper {
          margin: 5px 0 46px 0;
          padding: 0;
          background: unset;
          border: unset;
          border-radius: unset;

          @media screen and (max-width: 767px) {
            margin: 7px 0 30px 0;
          }
        }

        &.search-button-wrapper {
          @media screen and (max-width: 767px) {
            margin-top: 30px;
            margin-bottom: 39px;

            .search-button {
              width: 100%;
            }
          }
        }
      }
    }

    &.located-on-main-page {
      grid-template-columns: 128px calc(25% + (25% - 128px)) 25% 25%;

      &.hotels {
        grid-template-columns: 180px calc(25% + (25% - 180px)) 25% 25%;

        .field-wrapper {
          position: relative;

          &.hotel-places-guarantee-wrapper {
            grid-column: span 2;
            margin-left: 0 !important;
          }
        }
      }

      .field-wrapper {
        position: relative;
        margin-bottom: 20px;

        &.stars-wrapper {
          height: 16px;
          margin: 20px 0;
        }

        &.checkbox-wrapper, &.search-button-wrapper {
          margin-top: 0 !important;
          margin-bottom: 0;
          grid-column: span 1;
        }

        &.checkbox-wrapper {
          grid-column: span 1;
        }

        &.hotel-places-guarantee-wrapper {
          margin-left: 20px !important;
        }

        &.search-button-wrapper {
          margin-left: 30px !important;
          grid-column: 3 / -1;

          .search-button {
            width: 100%;
          }
        }
      }
    }

    .field-wrapper {
      position: relative;

      &.meals-wrapper, &.rating-wrapper, &.services-wrapper, &.budget-wrapper {
        grid-column: span 2;

        .choose-block {
          top: 85px;
        }

        .label-wrapper {
          button {
            font-size: 14px;
            line-height: normal;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-flow: column;

    &.active {
      width: calc(100% - 20px);
    }

    &.located-on-main-page {

      &.hotels {
        grid-template-columns: 25% 25% 25% 25%;

        .field-wrapper {
          position: relative;

          &.search-button-wrapper {
            grid-column: span 4;
          }
        }
      }

      .field-wrapper {
        position: relative;

        &:not(.search-button-wrapper) {
          display: none;
        }

        &.search-button-wrapper {
          margin-top: 12px !important;
          margin-left: 0 !important;
          grid-column: span 4;
        }
      }
    }

    .field-wrapper {
      &.meals-wrapper, &.rating-wrapper, &.services-wrapper, &.budget-wrapper {
        padding: 8px 10px 9px 20px;

        .choose-block {
          top: 70px;
        }

        .label-wrapper {
          label {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}

</style>
