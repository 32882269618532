<template>
  <div class="choose-block">
    <div class="choose-block--header flex-row-start">
      <template v-if="categories">
        Без перелета
        <div class="choose-block--categories">
          <div class="choose-block--categories--item">
            <img src="@/assets/images/common/flag-circle-ru.svg" alt="ru"/>
            Россия
          </div>
        </div>
      </template>

      <div
          class="choose-block--close"
          @click="$emit('close')"
      ></div>
    </div>
    <div
        class="choose-block--list"
        :style="{ gridTemplateRows: `repeat(${rowCount()}, 1fr)` }"
    >
      <div
          v-for="[id, value] of items"
          :key="id"
          class="choose-block--list--item"
          :class="{ highlighted: value.isMain }"
          @click="$emit('change-item', id)"
      >
        <span
            v-if="firstLetter !== (value.name ?? value).substring(0, 1)"
        >
          {{ firstLetter = (value.name ?? value).substring(0, 1) }}
        </span>
        {{ value.name ?? value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPageSearchBlockCountriesCities',
  props: {
    categories: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    items: {
      type: Map,
      required: true,
    }
  },
  emits: ['close', 'change-item'],
  setup(props) {
    const firstLetter = ''

    const rowCount = () => {
      return Math.ceil(props.items.size / 4)
    }

    return {
      firstLetter,
      rowCount,
    }
  },
}
</script>

<style scoped lang="scss">
.choose-block {
  position: absolute !important;
  top: 75px;
  left: 0;
  max-width: 75vw;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  @media screen and (min-width: 768px) {
    &.first {
      left: -20px;
    }
  }

  @media screen and (max-width: 767px) {
    top: 80px;
    max-width: 100%;
  }

  &--header {
    user-select: none;
    align-items: center;
    position: relative;
    padding: 0 55px 0 20px;
    height: 70px;
    color: #222222;
    font-family: GilroyMedium;
    font-size: 14px;
    line-height: 1;
    background: #f5f5f5;
    border-radius: 16px 16px 0 0;
  }

  &--categories {
    margin-left: 20px;

    &--item {
      padding: 8px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background: #ffffff;
      border-radius: 30px;

      img {
        margin-right: 7px;
      }
    }
  }

  &--close {
    position: absolute !important;
    right: 27px;
    width: 14px;
    height: 14px;
    background-image: url('@/assets/images/common/close-orange.svg');
    background-size: cover;
    cursor: pointer;
  }

  &--list {
    padding: 16px 20px 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: column;
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-clip: padding-box;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &--item {
      position: relative;
      margin: 14px 70px 0 0;
      padding-left: 25px;
      text-align: left;
      font-family: GilroyRegular;
      font-size: 14px;
      color: #222222;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;

      &.highlighted {
        font-family: GilroySemibold;
      }

      span {
        position: absolute !important;
        left: 0;
        display: block;
        color: #ff8500;
        font-family: GilroyBold;
      }
    }
  }
}
</style>
