<template>
  <div class="field-wrapper checkbox-wrapper">
    <input
      type="checkbox"
      :id="id"
      v-model="checked"
      :class="{
        'white-border-desktop-only': whiteBorderDesktopOnly
      }"
      :disabled="disabled"
      @change="$emit('update:value', checked)"
    />
    <label
      :for="id"
      :class="{
        'disabled': disabled
      }"
    >
      <span>{{ label }}</span>
    </label>
  </div>
</template>

<script>

import { ref, watch } from 'vue'

export default {
  name: 'CheckBoxFieldComponent',
  emits: ['update:value'],
  props: {
    id: String,
    value: Boolean,
    label: String,
    whiteBorderDesktopOnly: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    const checked = ref(props.value)

    watch(() => props.value, () => checked.value = props.value)

    return {
      checked
    }
  }
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.checkbox-wrapper {
  input[type="checkbox"] {
    display: none;

    &.white-border-desktop-only {
      + label {
        &:before {
          @media screen and (min-width: 1680px) {
            border-color: $base-white;
          }
        }
      }
    }

    + label {
      position: relative;
      display: flex;
      align-items: center;
      font-family: GilroyMedium;
      font-size: 14px;
      line-height: 16.98px;

      &:not(.disabled) {
        cursor: pointer;
      }

      &:before {
        content: '';
        margin-right: 10px;
        display: block;
        width: 20px;
        height: 21px;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        box-sizing: border-box;
      }
    }

    &:checked {
      + label {
        &:after {
          content: '';
          position: absolute !important;
          left: 4px;
          display: block;
          width: 12px;
          height: 10px;
          background: url('@/assets/images/common/checked.svg');
        }
      }
    }
  }
}

</style>
