<template>
  <teleport to="#app-overlay">
    <system-notify
        v-if="hasError"
        :text="systemNotifyText"
        @close="handleCloseSystemNotify"
    ></system-notify>

    <div
        v-else
        class="tour-detailed-card"
        :class="{'hidden': signInFormIsClosed === false}"
    >
      <div
          class="tour-detailed-card--form"
          v-click-outside-block="handleClickOutside"
      >
        <tour-detailed-card-header
            :logo="headerLogo"
            :tour-id="tourId"
            :price="price"
            :currency="currency"
            :entity-payment-category="entityPaymentCategory"
            @hide-modal="$emit('hide-modal')"
        ></tour-detailed-card-header>
        <tour-detailed-card-body
            :logo="headerLogo"
            :tour-id="tourId"
            :meal="meal"
            :hotel-rating="hotelRating"
            :hotel-name="hotelName"
            :placement="placement"
            :price="price"
            :currency="currency"
            :fuel-charge="fuelCharge"
            :hotel-info="hotelInfo"
            :photos="photos"
            :feedbacks="feedbacks"
            :short-info="shortInfo"
            :routes="routes"
            :coords="coords"
            :is-favorite="isFavorite"
            :entity-payment-category="entityPaymentCategory"
        ></tour-detailed-card-body>
      </div>
    </div>
  </teleport>
</template>

<script>

import TourDetailedCardHeader from '@/components/tour/tour-detailed-card/TourDetailedCardHeader.vue'
import TourDetailedCardBody from '@/components/tour/tour-detailed-card/TourDetailedCardBody.vue'
import {tourStore} from '@/stores/tourStore'
import {commonStore} from '@/stores/commonStore'
import {computed, onMounted, ref} from 'vue'
import SystemNotify from '@/components/SystemNotify.vue'
import vClickOutside from 'click-outside-vue3'
import {authStore} from '@/stores/authStore'

export default {
  name: 'TourDetailedCard',
  components: {
    SystemNotify,
    TourDetailedCardBody,
    TourDetailedCardHeader
  },
  emits: ['hide-modal'],
  props: {
    tourId: String,
    meal: String,
    tourLogo: String,
    entityPaymentCategory: String
  },
  setup(props, {emit}) {
    const tourStoreInstance = tourStore()
    const commonStoreInstance = commonStore()
    const signInFormIsClosed = computed(() => authStore().formIsClosed)
    const hotelRating = ref(null)
    const hotelName = ref(null)
    const placement = ref(null)
    const price = ref(null)
    const currency = ref(null)
    const fuelCharge = ref(null)
    const hotelInfo = ref(null)
    const photos = ref([])
    const feedbacks = ref([])
    const shortInfo = ref([])
    const routes = ref([])
    const coords = ref({})
    const systemNotifyText = ref('')
    const hasError = ref(false)
    const headerLogo = ref(null)
    const isFavorite = ref(false)

    const handleCloseSystemNotify = () => {
      commonStoreInstance.changeModalOverlayVisibility(false, true)
      hasError.value = false
      emitHideModal()
    }
    const emitHideModal = () => emit('hide-modal')
    const getTourInfo = () => {
      tourStoreInstance
          .getTour(props.tourId)
          .then(response => parseTourInfo(response.data))
          .catch(({response}) => {
            systemNotifyText.value = response.data && response.data.message ? response.data.message : null
            hasError.value = true
            commonStoreInstance.changeModalOverlayVisibility(true, true)
          })
          .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
    }
    const handleClickOutside = event => {
      if (signInFormIsClosed.value !== false && event.target.classList.contains('tour-detailed-card')) {
        emitHideModal()
      }
    }
    const parseTourInfo = tour => {
      isFavorite.value = !!tour.isFavorite
      hotelRating.value = tour.shortInfo && tour.shortInfo.hotelRating ? tour.shortInfo.hotelRating : null
      hotelName.value = tour.shortInfo && tour.shortInfo.hotelName ? tour.shortInfo.hotelName : null
      placement.value = tour.shortInfo && tour.shortInfo.placement ? tour.shortInfo.placement : null
      price.value = tour.shortInfo && tour.shortInfo.price ? tour.shortInfo.price : null
      fuelCharge.value = tour.shortInfo && tour.shortInfo.fuelCharge
          ? parseFloat(tour.shortInfo.fuelCharge) : 0
      currency.value = tour.shortInfo && tour.shortInfo.currency ? tour.shortInfo.currency : null
      hotelInfo.value = tour.hotel ? tour.hotel : null
      photos.value = hotelInfo.value && hotelInfo.value.photos ? hotelInfo.value.photos : []
      feedbacks.value = tour.reviews ? tour.reviews : []
      shortInfo.value = {
        countNight: tour.shortInfo ? tour.shortInfo.countNight : null,
        countAdult: tour.shortInfo ? tour.shortInfo.countAdult : null,
        countChild: tour.shortInfo ? tour.shortInfo.countChild : null,
        flyDate: tour.shortInfo ? tour.shortInfo.flyDate : null,
        departureCountryName: 'Россия',
        departureCityName: tour.shortInfo ? tour.shortInfo.departureCityName : null,
        meals: props.meal ?? (tour.hotel && tour.hotel.meal ? tour.hotel.meal.join(', ') : '-'),
        roomType: 'Standart',
        services: tour.hotel && tour.hotel.services ? tour.hotel.services : []
      }
      routes.value = tour.flights
          ? tour.flights.reduce((data, fl) => data.concat(fl.forward.concat(fl.backward)), [])
          : []
      coords.value = tour.hotel ? {latitude: tour.hotel.lat, longitude: tour.hotel.lon} : {}
      headerLogo.value = props.tourLogo ? props.tourLogo.toString() : null
      commonStoreInstance.changeModalOverlayVisibility(true, true)
    }

    onMounted(() => {
      if (props.tourId) {
        getTourInfo()
      }
    })

    return {
      hotelRating,
      hotelName,
      placement,
      price,
      currency,
      fuelCharge,
      hotelInfo,
      photos,
      feedbacks,
      shortInfo,
      routes,
      coords,
      hasError,
      systemNotifyText,
      headerLogo,
      isFavorite,
      signInFormIsClosed,

      handleCloseSystemNotify,
      emitHideModal,
      handleClickOutside
    }
  },
  directives: {
    clickOutsideBlock: vClickOutside.directive
  },
}
</script>

<style scoped lang='scss'>

@import '@/assets/css/variables';

.tour-detailed-card {
  width: calc(100% - 240px);
  height: calc(100% - 200px);
  padding: 60px 120px;

  &--form {
    background: $base-white;
    border-radius: 16px;
    height: 100%;
  }

  &.hidden {
    visibility: hidden;
  }

  @media (max-width: 1679px) {
    padding: 60px 24px;
    width: calc(100% - 48px);
  }

  @media (max-width: 767px) {
    padding: 60px 10px;
    width: calc(100% - 20px);
  }
}

</style>
