import { defineStore } from 'pinia'
import { goToMainPage } from '@/composables/router'
import {
  getAuthTokenFromLocalStorage, removeAuthTokenFromLocalStorage, setAuthTokenToLocalStorage
} from '@/composables/auth'

const moduleName = 'auth'

export const authStore = defineStore(moduleName, {
  state: () => ({
    authToken: null,
    formClosed: null,
    showSmallHeader: false,
    vkAuthCode: null,
    googleAuthCode: null,
  }),
  getters: {
    currentVKAuthCode: (state) => state.vkAuthCode,
    currentGoogleAuthCode: (state) => state.googleAuthCode,
    formIsClosed: (state) => state.formClosed,
    currentAuthToken: (state) => {
      let token = state.authToken
      return token ? token : getAuthTokenFromLocalStorage()
    },
    userIsAuthorized: state => {
      let token = state.authToken
      token = token ? token : getAuthTokenFromLocalStorage()
      return !!token
    },
    smallHeaderIsVisible: (state) => state.showSmallHeader,
  },
  actions: {
    setVKAuthCode(code) {
      this.vkAuthCode = code
    },
    setGoogleAuthCode(code) {
      this.googleAuthCode = code
    },
    setAuthToken(token) {
      setAuthTokenToLocalStorage(token)
      this.authToken = token
    },
    logoutUser() {
      removeAuthTokenFromLocalStorage()
      this.authToken = null
      goToMainPage()
    },
    changeFormClosedState(value) {
      this.formClosed = !value
    },
    changeSmallHeaderVisibility(value) {
      this.showSmallHeader = value
    },
    login(data) {
      return this.$http.post(`${moduleName}/login`, data)
    },
    register(data) {
      return this.$http.post(`${moduleName}/register`, data)
    },
    sendRequestRestorePassword(data) {
      return this.$http.post(`${moduleName}/send-request-restore-password`, data)
    },
    checkCodeRestorePassword(data) {
      return this.$http.post(`${moduleName}/check-code-restore-password`, data)
    },
    createNewPassword(data) {
      return this.$http.post(`${moduleName}/create-new-password`, data)
    },
    getUserProfileInfo() {
      return this.$http.get(`${moduleName}/profile`)
    },
    updateUserProfile(id, data) {
      return this.$http.patch(`${moduleName}/profile/${id}`, data)
    },
    removeUserProfile() {
      return this.$http.delete(`${moduleName}/profile`)
    },
    getAuthTokenByGoogleAuthData(params) {
      return this.$http.get(
        `${moduleName}/google/callback?${new URLSearchParams(params).toString()}`
      )
    },
    getAuthTokenByVKAuthCode(code) {
      return this.$http.get(`${moduleName}/vk/callback?code=${code}`)
    },
    getAuthTokenByGoogleAuthCode(code) {
      return this.$http.post(`${moduleName}/google/auth`, {code})
    }
  },
})
