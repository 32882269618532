<template>
  <div class="panel-title text-left">Мой профиль</div>
  <form>
    <div class="field-group flex-row">
      <template
        v-for="(field, index) in fields"
        :key="index"
      >
        <div v-if="index < 2" class="field-wrapper flex-row">
          <span class="placeholder">{{ field.placeholder }}</span>
          <input
            type="text"
            class="w-100"
            v-model="field.value"
          />
        </div>
      </template>
    </div>
    <div class="field-group flex-row">
      <template
        v-for="(field, index) in fields"
        :key="index"
      >
        <div
          v-if="index > 1"
          class="field-wrapper flex-column-start"
          :class="{
            'invalid': field.errors.length > 0
          }"
        >
          <span class="placeholder">{{ field.placeholder }}</span>
          <input
            v-if="field.key === 'phone'"
            type="tel"
            v-model="field.value"
            @keyup="fields[index].errors = []"
            v-maska
            :data-maska="phoneMasks"
          />
          <input
            v-else
            type="text"
            v-model="field.value"
            @keyup="fields[index].errors = []"
          />
          <div
            class="image-valid d-none"
            :class="{'valid' : field.key === 'email'}"
          ></div>
          <div
            v-if="field.errors && field.errors.length"
            class="error flex-column-start text-left"
          >
            <span
              v-for="(error, index) in field.errors"
              :key="index"
            >{{ error }}</span>
          </div>
        </div>
      </template>
    </div>
  </form>

  <div class="buttons-wrapper flex-row-start">
    <button
      class="save"
      @click="onSaveClick"
    >Сохранить</button>
    <button
      class="remove-account"
      @click="onClickRemoveAccount"
    >Удалить аккаунт</button>
  </div>
</template>

<script>

import { computed, onMounted, ref } from 'vue'
import { authStore } from '@/stores/authStore'
import { emailIsValid } from '@/composables/validator'
import { commonStore } from '@/stores/commonStore'
import { phoneToString } from '@/composables/phoneFormatter'

export default {
  name: 'PersonalAccountPageContentProfile',
  emits: ['item-click'],
  props: {
    selectedItemType: String
  },
  setup() {
    const authStoreInstance = authStore()
    const fields = ref([
      {placeholder: 'Имя', key: 'firstName', value: null, errors: []},
      {placeholder: 'Фамилия', key: 'lastName', value: null, errors: []},
      {placeholder: 'Номер телефона', key: 'phone', value: null, errors: []},
      {placeholder: 'Электронная почта', key: 'email', value: null, errors: []},
    ])
    let userProfileId = null
    const phoneMasks = computed(() => commonStore().getPhoneMasks)

    const onSaveClick = () => {
      const data = {}
      fields.value.map(f => {
        if (f.key === 'phone') {
          data[f.key] = f.value ? phoneToString(f.value) : null
        } else {
          data[f.key] = f.value ? f.value.trim() : null
        }
      })
      if (data.email && !emailIsValid(data.email)) {
        fields.value.map(f => {
          if (f.key === 'email') {
            f.errors = ['Неверный формат email']
          }
        })
        return false
      }
      authStoreInstance.updateUserProfile(userProfileId, data).then(response => console.log(response.data))
    }
    const onClickRemoveAccount = () => {
      authStoreInstance.removeUserProfile().then(() => authStoreInstance.logoutUser())
    }
    const fillFormFields = data => fields.value.map(f => f.value = data[f.key] ? data[f.key] : null)
    const getUserInfo = () => authStoreInstance.getUserProfileInfo().then(response => {
      userProfileId = response.data.id
      fillFormFields(response.data)
    })

    onMounted(() => getUserInfo())

    return {
      fields,
      phoneMasks,

      onSaveClick,
      onClickRemoveAccount,
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

form {
  .field-group {
    height: 70px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .field-wrapper {
      width: 100%;
      &:first-child {
        margin-right: 20px;
      }
      .placeholder {
        font-family: GilroyRegular;
        position: absolute;
        top: 15px;
        left: 20px;
        z-index: 1;
        font-size: 13px;
        line-height: 13px;
        color: #888888;
      }
      input {
        width: calc(100% - 20px);
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        padding: 38px 0 16px 20px;
      }
      .image-valid.valid {
        position: absolute;
        top: 21px;
        right: 21px;
        height: 28px;
        width: 28px;
        display: block;
        background-image: url('@/assets/images/common/valid-field.svg');
      }

      &.invalid {
        input {
          border: 1px solid #FF3C3C;
        }

        .image-valid.valid {
          display: none;
        }

        .error {
          span {
            margin: 6px 0 0 4px;
            font-family: GilroyRegular;
            font-size: 14px;
            line-height: 14px;
            color: #FF3C3C;
          }
        }
      }
    }
  }
}
.buttons-wrapper {
  margin-top: 30px;
  button {
    border-radius: 6px;
    width: 235px;
    height: 60px;
    font-family: GilroySemibold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    &.save {
      color: $base-white;
      border: unset;
      background: linear-gradient(225deg, #FF8500 0%, #FF4800 100%);
      margin-right: 15px;
    }
    &.remove-account {
      height: 60px;
      border: solid 1px transparent;
      background:
        linear-gradient(white, white) padding-box, linear-gradient(to right, #FF8500, #FF4800) border-box;
    }
  }
}

</style>
