<template>
  <div class="tour-detailed-card--form--header flex-row-center-space-between">
    <div class="tour-detailed-card--form--header--block flex-row">
      <circle-icon
        icon-size="24px"
        :src="require('@/assets/images/common/share.svg')"
        @click="showShareModal = true"
      ></circle-icon>
      <share-modal
        v-if="showShareModal"
        :logo="logo"
        :price="price"
        :currency="currency"
        :tour-id="tourId"
        :entity-payment-category="entityPaymentCategory"
        @close="showShareModal = false"
      ></share-modal>
    </div>
    <div class="tour-detailed-card--form--header--block flex-row-center">
<!--      <circle-icon-->
<!--        icon-size="14px"-->
<!--        :src="require('@/assets/images/common/arrow-down-black.svg')"-->
<!--        class="arrow-icon"-->
<!--      ></circle-icon>-->
      <img
        v-if="logo"
        :src="logo"
        alt="logo"
        class="logo"
      />
      <img
        :src="require('@/assets/images/common/close-black.svg')"
        alt="close"
        class="close cursor-pointer"
        @click="$emit('hide-modal')"
      />
    </div>
  </div>
</template>

<script>

import CircleIcon from '@/components/icon/CircleIcon.vue'
import { ref } from 'vue'
import ShareModal from '@/components/ShareModal.vue'

export default {
  name: 'TourDetailedCardHeader',
  components: {
    ShareModal,
    CircleIcon
  },
  props: {
    logo: String,
    tourId: String,
    price: Number,
    currency: String,
    entityPaymentCategory: String,
  },
  emits: ['hide-modal'],
  setup() {
    const showShareModal = ref(false)

    return {
      showShareModal
    }
  }
}
</script>

<style lang='scss' scoped>

.tour-detailed-card--form--header {
  padding: 15px 30px;

  &--block {
    .arrow-icon {
      margin-right: 20px;
    }
    img.logo {
      max-height: 50px;
      margin-right: 129px;

      @media (max-width: 767px) {
        display: none;
      }
    }
    img.close {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 1679px) {
    padding: 15px 20px;
  }
}

</style>
