<template>
  <div class="pay flex-column-start">
<!--    <tour-detailed-card-body-tab-pay-start-form-->
<!--      v-if="activeForm === 'start'"-->
<!--      @go-back="onClickGoBack"-->
<!--      @go-to-final-form="onClickGoToFinalForm"-->
<!--    ></tour-detailed-card-body-tab-pay-start-form>-->
    <tour-detailed-card-body-tab-pay-final-form
      v-if="activeForm === 'final'"
      :payment-type="paymentType"
      :amount="amount"
      :meal="meal"
      :fuel-charge="fuelCharge"
      :currency="currency"
      :tour-id="tourId"
      :children-count="childrenCount"
      :adults-count="adultsCount"
      :entity-payment-category="entityPaymentCategory"
      :placement="placement"
      @go-back="onClickGoBack"
    ></tour-detailed-card-body-tab-pay-final-form>
  </div>
</template>

<script>

// import TourDetailedCardBodyTabPayStartForm
//   from '@/components/tour/tour-detailed-card/tabs/pay/TourDetailedCardBodyTabPayStartForm.vue'
import { ref } from 'vue'
import TourDetailedCardBodyTabPayFinalForm
  from '@/components/tour/tour-detailed-card/tabs/pay/TourDetailedCardBodyTabPayFinalForm.vue'

export default {
  name: 'TourDetailedCardBodyTabPay',
  components: {
    TourDetailedCardBodyTabPayFinalForm, /*TourDetailedCardBodyTabPayStartForm*/
  },
  emits: ['go-back'],
  props: {
    paymentType: String,
    amount: Number,
    fuelCharge: Number,
    currency: String,
    tourId: String,
    entityPaymentCategory: String,
    adultsCount: Number,
    childrenCount: Number,
    placement: String,
    meal: String
  },
  setup(props, { emit }) {
    const activeForm = ref('final')

    const onClickGoBack = () => emit('go-back')
    const onClickGoToFinalForm = () => activeForm.value = 'final'
    const onClickGoBackToStartForm = () => activeForm.value = 'start'

    return {
      activeForm,

      onClickGoBack,
      onClickGoToFinalForm,
      onClickGoBackToStartForm
    }
  }
}
</script>

<style lang='scss' scoped>

.tab-content.pay {
  @media (max-width: 1679px) {
    .final-form {
      .info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 50px;
      }

      .go-to-pay {
        margin-top: 50px;
        width: 100%;
      }

      .notice {
        padding: 15px 0 13px 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .start-form {
      .note {
        line-height: 19.6px;
        margin-bottom: 20px;
      }

      .actions {
        flex-direction: column;

        .button {
          margin-right: 0;
          margin-bottom: 15px;
          width: 100%;

          span {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

</style>
