export const translateApiError = text => {
  const keys = {
    'email should not be empty': 'E-mail не должен быть пустым',
    'email must be an email': 'E-mail должен выглядеть как e-mail',
    'email must be a string': 'E-mail должен быть строкой',
    'password should not be empty': 'Пароль не должен быть пустым',
    'password must be a string': 'Пароль должен быть строкой'
  }
  return keys[text] ? keys[text] : text
}
