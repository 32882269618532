<template>
  <teleport to="#app-overlay">
    <div
        class="sign-in-form"
        :class="formClass"
        @click.stop.prevent
        v-click-outside="onCloseFormClick"
    >
      <span
          class="close cursor-pointer"
          title="Закрыть форму"
          @click="onCloseFormClick"
      ></span>
      <div
          class="form-content"
          :style="{'max-height': maxHeight}"
      >
        <div class="title text-center">{{ title }}</div>
        <div class="fields-wrapper">
          <form ref="form">
            <template
                v-for="(field, index) in fields"
                :key="index"
            >
              <base-form-field-component
                  v-if="!field.hidden"
                  :field-key="field.key"
                  :type="field.type"
                  :label="field.label"
                  :placeholder="field.placeholder"
                  :custom-label="field.customLabel"
                  v-model:value="field.value"
                  :show-password-button="field.showPasswordButton"
                  :index="index.toString()"
                  :error-messages="field.errors"
                  :required="field.required"
                  :disabled="field.disabled"
                  :notice="field.notice"
                  @keydown="onFieldKeyDown(field)"
                  @update:value="onUpdateFieldValue(field)"
              ></base-form-field-component>
            </template>
          </form>
        </div>
        <button
            class="action-button cursor-pointer"
            @click="onClickActionButton"
            :disabled="actionButtonDisabled"
            :class="{'disabled': actionButtonDisabled}"
        >{{ actionButtonText }}
        </button>
        <template v-if="isLoginForm">
          <div class="help flex-column-center-center">
            <span
                class="text link cursor-pointer"
                @click="onClickForgotPassword"
            >Забыли пароль?</span>
            <div class="additional-item flex-row-center-center">
              <span class="text">Нет аккаунта?</span>
              &nbsp;
              <span
                  class="text text-underline cursor-pointer"
                  @click="onClickRegistration"
              >Зарегистрируйтесь</span>
            </div>
          </div>
        </template>
        <template v-else-if="!isPasswordRecoveryForm">
          <div class="help flex-row-center-center">
            <span class="text">У Вас есть аккаунт?</span>
            &nbsp;
            <span
                class="text link cursor-pointer"
                @click="onClickLogin"
            >Войти</span>
          </div>
        </template>
        <div
            v-if="!isPasswordRecoveryForm"
            class="socials-wrapper"
        >
          <div
              class="item google flex-row-center-center cursor-pointer"
              @click="onClickGoogleAuth"
          >
            <div class="image"></div>
            <span class="text">Войти через Google</span>
          </div>
          <div
              class="item vk flex-row-center-center cursor-pointer"
              @click="onClickVKAuth"
          >
            <div class="image"></div>
            <span class="text">Войти через VK</span>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>

import {computed, onMounted, ref} from 'vue'
import {authStore} from '@/stores/authStore'
import BaseFormFieldComponent from '@/components/forms/BaseFormFieldComponent.vue'
import {translateApiError} from '@/composables/translateApiError'
import {commonStore} from '@/stores/commonStore'
import {goToAccountPage} from '@/composables/router'
import {emailIsValid} from '@/composables/validator'
import {phoneToString} from '@/composables/phoneFormatter'
import vClickOutside from 'click-outside-vue3'
import {getItemFromLocalStorage, removeItemFromLocalStorage} from '@/composables/localStorage'
import {encodeShareLinkData} from '@/composables/share'


export default {
  name: 'BaseFormComponent',
  components: {
    BaseFormFieldComponent
  },
  emits: ['open-registration-form', 'open-password-recovery-form', 'open-login-form', 'close-form'],
  props: {
    formType: String,
    title: String,
    formClass: String,
    fields: Array,
    actionButtonText: String,
    maxHeight: String,
    redirectToAccountPageAfterSuccessSingIn: {
      type: Boolean,
      default: () => true
    },
  },
  setup(props, {emit}) {
    const form = ref(null)
    const authStoreInstance = authStore()
    const commonStoreInstance = commonStore()
    const isLoginForm = computed(() => props.formType === 'login')
    const isPasswordRecoveryForm = computed(() => props.formType === 'password-recovery')
    const actionButtonDisabled = ref(props.formType === 'registration')
    const currentVKAuthCode = computed(() => authStoreInstance.currentVKAuthCode)
    const currentGoogleAuthCode = computed(() => authStoreInstance.currentGoogleAuthCode)
    const passwordRecoveryStageIndex = ref(1)
    const passwordRecoveryEmail = ref(null)

    const onClickRegistration = () => emit('open-registration-form')
    const onClickForgotPassword = () => emit('open-password-recovery-form')
    const onClickLogin = () => emit('open-login-form')
    const onCloseFormClick = () => emitFormClose()
    const emitFormClose = () => emit('close-form')
    const onClickActionButton = () => {
      if (isLoginForm.value) {
        login()
      } else if (isPasswordRecoveryForm.value) {
        restorePasswordProcessing()
      } else {
        registration()
      }
    }
    const login = async () => {
      const data = {}
      props.fields.map(f => {
        if (f.key) {
          data[f.key] = f.value ? f.value : null
        }
      })

      if (!validateEmail(data.email)) {
        return false
      }

      commonStoreInstance.changeLoaderOverlayVisibility(true)
      setTimeout(() => sendLoginRequest(data), 250)
    }
    const sendLoginRequest = async (data, changeOverlayVisibility = true) => {
      await authStoreInstance.login(data)
          .then(result => {
            if (result.data && result.data.access_token) {
              processLogin(result.data.access_token)
            }
          })
          .catch(err => {
            console.log(err)
            if (err.response) {
              const messages = err.response.data.message
              if (Array.isArray(messages)) {
                props.fields.map(f => {
                  f.errors = []
                  messages.map(text => {
                    if (text.includes(f.key)) {
                      f.errors.push(translateApiError(text))
                    }
                  })
                })
              } else if (messages === 'Пользователь с таким email не найден') {
                props.fields.map(f => {
                  if (f.key === 'email') {
                    f.errors = [messages]
                  }
                })
              } else if (messages === 'Неверный логин или пароль') {
                props.fields.map(f => f.errors = [messages])
              }
            }
          })
          .finally(() => {
            if (changeOverlayVisibility) {
              commonStoreInstance.changeLoaderOverlayVisibility(false)
            }
          })
    }
    const registration = async () => {
      const data = {}
      let formInvalid = false
      let password = null
      props.fields.map(f => {
        if (f.key === 'password') {
          password = f.value
        }
        if (f.key === 'repeatPassword' && f.value !== password) {
          f.errors = []
          f.errors.push('Пароли не совпадают')
          formInvalid = true
        }
        if (f.key === 'phone') {
          data[f.key] = f.value ? phoneToString(f.value) : null
        } else {
          data[f.key] = f.value ? f.value : null
        }
      })
      if (formInvalid) {
        return false
      }
      if (!validateEmail(data.email)) {
        return false
      }

      commonStoreInstance.changeLoaderOverlayVisibility(true)
      setTimeout(() => sendRegistrationRequest(data), 250)
    }
    const restorePasswordProcessing = () => {
      const data = {}
      let formInvalid = false
      let password = null
      props.fields.map(f => {
        if (passwordRecoveryStageIndex.value === 1 && f.key === 'email') {
          if (!f.value) {
            formInvalid = true
            f.errors = []
            f.errors.push('Пожалуйста, укажите E-mail')
          } else {
            data.email = f.value
          }
        }
        if (passwordRecoveryStageIndex.value === 2 && f.key === 'code') {
          if (!f.value) {
            formInvalid = true
            f.errors = []
            f.errors.push('Пожалуйста, укажите код подтверждения')
          } else {
            data.code = f.value
          }
        }
        if (passwordRecoveryStageIndex.value === 3 && ['password', 'repeatPassword'].includes(f.key)) {
          if (f.key === 'password') {
            password = f.value
          }
          if (f.key === 'repeatPassword' && f.value !== password) {
            f.errors = []
            f.errors.push('Пароли не совпадают')
            formInvalid = true
          }
          if (!f.value) {
            formInvalid = true
            f.errors = []
            f.errors.push(f.key === 'password' ? 'Пожалуйста, укажите пароль' : 'Пожалуйста, укажите пароль повторно')
          }
        }
      })
      if (formInvalid) {
        return false
      }
      if (passwordRecoveryStageIndex.value === 1 && !validateEmail(data.email)) {
        return false
      }

      commonStoreInstance.changeLoaderOverlayVisibility(true)
      if (passwordRecoveryStageIndex.value === 1) {
        setTimeout(async () => {
          passwordRecoveryEmail.value = data.email
          await authStoreInstance.sendRequestRestorePassword({email: data.email})
              .then(() => {
                passwordRecoveryStageIndex.value += 1
                props.fields.map(f => {
                  if (f.key === 'email') {
                    f.hidden = true
                  }
                  if (f.key === 'code') {
                    f.hidden = false
                  }
                })
              })
              .catch(err => {
                const messages = err.response.data.message
                if (Array.isArray(messages)) {
                  props.fields.map(f => {
                    f.errors = []
                    messages.map(text => {
                      if (text.includes(f.key)) {
                        f.errors.push(translateApiError(text))
                      }
                    })
                  })
                }
              })
              .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
        }, 250)
      } else if (passwordRecoveryStageIndex.value === 2) {
        setTimeout(async () => {
          await authStoreInstance.checkCodeRestorePassword({code: data.code})
              .then(() => {
                passwordRecoveryStageIndex.value += 1
                props.fields.map(f => {
                  if (f.key === 'code') {
                    f.hidden = true
                  }
                  if (['password', 'repeatPassword'].includes(f.key)) {
                    f.hidden = false
                  }
                })
              })
              .catch(() => {
                props.fields.map(f => {
                  if (f.key === 'code') {
                    f.errors = ['Указан неверный проверочный код']
                  }
                })
              })
              .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
        }, 250)
      } else if (passwordRecoveryStageIndex.value === 3) {
        data.password = password
        setTimeout(async () => {
          await authStoreInstance.createNewPassword(data)
              .then(() => {
                const loginData = {email: passwordRecoveryEmail.value, password: data.password}
                sendLoginRequest(loginData, false)
                    .finally(() => {
                      passwordRecoveryEmail.value = null
                      passwordRecoveryStageIndex.value = 1
                      props.fields.map(f => f.hidden = f.key !== 'email')
                    })
              })
              .catch(err => {
                const messages = err.response.data.message
                if (Array.isArray(messages)) {
                  props.fields.map(f => {
                    f.errors = []
                    messages.map(text => {
                      if (text.includes(f.key)) {
                        f.errors.push(translateApiError(text))
                      }
                    })
                  })
                }
              })
              .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
        }, 250)
      }
    }
    const validateEmail = email => {
      if (email) {
        if (!emailIsValid(email)) {
          props.fields.map(f => {
            if (f.key === 'email') {
              f.errors = ['Неверный формат email']
            }
          })
          return false
        }
        return true
      }

      props.fields.map(f => {
        if (f.key === 'email') {
          f.errors = ['E-mail не должен быть пустым']
        }
      })

      return false
    }
    const sendRegistrationRequest = async data => {
      await authStoreInstance.register(data)
          .then(async () => {
            await authStoreInstance.login({email: data.email, password: data.password})
                .then(result => {
                  if (result.data && result.data.access_token) {
                    processLogin(result.data.access_token)
                  }
                })
                .catch(err => console.log(err))
                .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
          })
          .catch(err => {
            const messages = err.response.data.message
            if (Array.isArray(messages)) {
              props.fields.map(f => {
                f.errors = []
                messages.map(text => {
                  if (text.includes(f.key)) {
                    f.errors.push(translateApiError(text))
                  }
                })
              })
            } else if (messages === 'Такой пользователь уже был зарегистрирован') {
              props.fields.map(f => {
                if (f.key === 'email') {
                  f.errors = [messages]
                }
              })
            }
          })
          .finally(() => commonStoreInstance.changeLoaderOverlayVisibility(false))
    }
    const processLogin = accessToken => {
      authStoreInstance.setAuthToken(accessToken)
      emitFormClose()
      const paymentEntity = getItemFromLocalStorage('payment-entity')
      if (paymentEntity) {
        const json = JSON.parse(paymentEntity)
        const currentDate = new Date()
        if (json && json.time) {
          if (currentDate.getTime() - json.time < 120000) {
            commonStoreInstance.setShareLinkHash(encodeShareLinkData(json))
          }
          removeItemFromLocalStorage('payment-entity')
        } else if (props.redirectToAccountPageAfterSuccessSingIn) {
          goToAccountPage()
        }
      } else if (props.redirectToAccountPageAfterSuccessSingIn) {
        goToAccountPage()
      }
    }
    const onFieldKeyDown = field => {
      if (isLoginForm.value) {
        props.fields.map(f => {
          if (f.errors && f.errors.length) {
            f.errors = []
          }
        })
      } else if (field.errors && field.errors.length) {
        field.errors = []
      } else if (field.key === 'password') {
        props.fields.map(f => {
          if (f.key === 'repeatPassword' && f.value) {
            f.errors = []
          }
        })
      }
    }
    const onUpdateFieldValue = field => {
      if (field.key === 'agree') {
        actionButtonDisabled.value = !field.value
      }
    }
    const onClickGoogleAuth = () => {
      document.location.href = `${process.env.VUE_APP_API_ENDPOINT_URL}auth/google/callback`
    }
    const onClickVKAuth = () => {
      document.location.href = `https://oauth.vk.com/authorize?client_id=%2051755337&display=page&redirect_uri=${process.env.VUE_APP_HOST_URL}auth/vk&scope=email&response_type=code&v=5.131`
    }

    onMounted(() => {
      if (currentVKAuthCode.value) {
        authStoreInstance.getAuthTokenByVKAuthCode(currentVKAuthCode.value)
            .then(({data}) => {
              if (data.access_token) {
                processLogin(data.access_token)
                authStoreInstance.setVKAuthCode(null)
              }
            })
      }
      if (currentGoogleAuthCode.value) {
        authStoreInstance.getAuthTokenByGoogleAuthCode(currentGoogleAuthCode.value).then(({data}) => {
          if (data.token) {
            processLogin(data.token)
            authStoreInstance.setGoogleAuthCode(null)
          }
        })
      }
    })

    return {
      isLoginForm,
      isPasswordRecoveryForm,
      form,
      actionButtonDisabled,

      onClickRegistration,
      onClickForgotPassword,
      onClickLogin,
      onCloseFormClick,
      onClickActionButton,
      onFieldKeyDown,
      onUpdateFieldValue,
      onClickVKAuth,
      onClickGoogleAuth
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<style scoped lang='scss'>

@import '@/assets/css/variables';

.sign-in-form {
  width: 340px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: $base-white;

  .close {
    background-image: url('@/assets/images/common/close.svg');
    height: 14px;
    width: 14px;
    position: fixed;
    right: 20px;
    top: 20px;
    background-position: center;
    z-index: 1;
  }

  .form-content {
    user-select: none;
    overflow-x: unset;
    overflow-y: hidden;

    .title {
      padding: 30px 0;
      height: 25px;
      font-family: 'GilroyBold';
      line-height: 24.76px;
    }

    .fields-wrapper {
      padding: 0 20px;
    }

    .action-button {
      width: calc(100% - 40px);
      margin: 20px 20px 0 20px;
      border: unset;
      border-radius: 8px;
      height: 50px;
      font-family: GilroyMedium;
      font-size: 14px;
      line-height: 14px;
      color: $base-white;
      background: $action-button-bg;

      &.disabled {
        cursor: unset;
        opacity: .7;
      }
    }

    .help {
      margin: 20px 30px;

      .text {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: $help-text-color;

        &.link {
          color: $help-link-color;
          font-family: GilroyMedium;

          &:hover {
            text-decoration: underline;
            text-underline-position: under;
          }
        }
      }

      .additional-item {
        margin-top: 25px;
      }
    }

    .socials-wrapper {
      width: calc(100% - 40px);
      margin: 0 20px 20px 20px;

      .item {
        height: 48px;
        border: 1px solid $border-white;
        border-radius: 8px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &.google {
          .image {
            background-image: url('@/assets/images/common/google-logo.svg');
          }
        }

        &.vk {
          .image {
            background-image: url('@/assets/images/common/vk-logo.svg');
          }
        }

        .image {
          width: 24px;
          height: 24px;
        }

        .text {
          margin-left: 10px;
          font-family: GilroyRegular;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }

  &.position-absolute {
    position: absolute !important;
  }

  &.password-recovery {
    .form-content {
      .action-button {
        margin-bottom: 20px;
      }
    }
  }
}

</style>
