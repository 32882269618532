<template>
  <div class="google-auth-page"></div>
</template>

<script>

import router from '@/router'
import { onMounted } from 'vue'
import { goToMainPage } from '@/composables/router'
import { authStore } from '@/stores/authStore'

export default {
  name: 'GoogleAuthPage',
  setup() {
    const authStoreInstance = authStore()

    const processGoogleAuthCode = () => {
      let code = null
      if (router.currentRoute.value.query) {
        code = router.currentRoute.value.query.code
      }

      if (code) {
        authStoreInstance.setGoogleAuthCode(code)
      }

      router.replace({query: null})

      goToMainPage()
    }

    onMounted(() => processGoogleAuthCode())
  }
}

</script>
