import { defineStore } from 'pinia'

const moduleName = 'files'

export const filesStore = defineStore(moduleName, {
  state: () => ({}),
  getters: {},
  actions: {
    getFile(fileId) {
      return this.$http.get(`${moduleName}/download/${fileId}`, {responseType: 'blob'})
    },
  },
})
