<template>
  <div class="list--item" :id="hotelCode">
    <div class="list--item--preview">
      <div class="list--item--preview--image">
        <carousel ref="carouselRef" wrap-around :items-to-show="1">
          <Slide class="carousel__item" v-for="slide in itemImages" :key="slide">
            <img
                :src="slide"
                alt="hotel image"
                class="cursor-pointer"
            />
          </Slide>

          <template #addons>
            <Navigation v-if="itemImages.length > 1"/>
            <!--            <Pagination v-if="itemImages.length > 1"/>-->
          </template>
        </Carousel>
      </div>
      <div
          class="list--item--preview--info"
          :class="{
          'more-active': moreClicked
        }"
      >
        <div class="info-header flex-row-start-space-between">
          <div class="title">
            <div
                class="name text-left cursor-pointer"
                @click="openHotelCard"
            >{{ hotelName }}
            </div>
            <div class="placement text-left">{{ regionName }}</div>
          </div>
          <div class="price flex-column-end">
            <div class="flex-row-center-end">
              <span class="value">{{ formatAmount(price) }}</span>
              <span class="currency">руб</span>
            </div>
            <span v-if="searchCategory !== 'hot-tours'">стоимость тура</span>
            <span v-else>стоимость тура за <b>1-го</b> чел.</span>
          </div>
          <!--          <circle-icon-->
          <!--            background-variant="beige"-->
          <!--            :src="require('@/assets/images/common/like-gray.svg')"-->
          <!--            size="28px"-->
          <!--            icon-size="16px"-->
          <!--            class="like d-none"-->
          <!--          ></circle-icon>-->
        </div>
        <rating-star :rating="parseFloat(hotelStars)"></rating-star>
        <div
            v-if="searchCategory !== 'hot-tours'"
            class="description text-left"
        >{{ hotelDescription }}
        </div>
        <div class="info-footer flex-row-center-space-between">
          <div
              class="tabs"
              :class="searchCategory"
          >
            <div
                v-if="searchCategory !== 'hot-tours'"
                class="tab rating"
            >
              <span>{{ hotelRating }}</span>
            </div>
            <div
                class="tab about-hotel cursor-pointer"
                :class="{
                'active': activeTab === 'about-hotel'
              }"
                @click="handleTabClick('about-hotel')"
            >
              <span>Об отеле</span>
            </div>
            <div
                class="tab feedbacks cursor-pointer"
                :class="{
                'active': activeTab === 'feedbacks'
              }"
                @click="handleTabClick('feedbacks')"
            >
              <span>Отзывы</span>
            </div>
            <div
                class="tab map cursor-pointer"
                :class="{
                'active': activeTab === 'map'
              }"
                @click="handleTabClick('map')"
            >
              <span>На карте</span>
            </div>
            <!--            <circle-icon-->
            <!--              background-variant="beige"-->
            <!--              :src="require(`@/assets/images/common/like-${likeHovered ? 'orange' : 'gray'}.svg`)"-->
            <!--              size="28px"-->
            <!--              icon-size="16px"-->
            <!--              class="tab like"-->
            <!--            ></circle-icon>-->
          </div>
          <div
              class="more flex-row-center-center cursor-pointer"
              :class="[{'active': moreClicked}, searchCategory]"
              @click="handleMoreClick"
          >
            <span>Купить</span>
            <img
                :src="require('@/assets/images/common/arrow-down-white.svg')"
                alt="more info"
            />
          </div>
          <preview-card-price
              class="d-none"
              :price="price"
              :currency="currency"
          ></preview-card-price>
        </div>
      </div>
      <div
          class="list--item--preview--tab-content"
          :class="{
          'd-none': !activeTab
        }"
      >
        <about-hotel-tab-component
            v-if="activeTab === 'about-hotel'"
            :hotel="hotelInfo"
            @open-hotel-detailed-card="openHotelCard"
        ></about-hotel-tab-component>
        <hotel-feedbacks-tab-component
            v-if="activeTab === 'feedbacks'"
            :hotel="hotelInfo"
        ></hotel-feedbacks-tab-component>
        <hotel-map-tab-component
            v-if="activeTab === 'map'"
            :hotel="hotelInfo"
        ></hotel-map-tab-component>
      </div>
      <search-form-content-list-item-more-component
          v-if="!activeTab && moreClicked"
          :tours="tours"
      ></search-form-content-list-item-more-component>

      <tour-detailed-card
          v-if="tourToOpen"
          :tour-id="tourToOpen.id.toString()"
          :entity-payment-category="tourToOpen.entityPaymentCategory"
          :tour-logo="tourToOpen.logo"
          @hide-modal="onDetailedTourCardModalHide"
      ></tour-detailed-card>
    </div>
    <div class="list--item--line w-100"></div>
  </div>
</template>

<script>
import {formatAmount} from '@/composables/priceFormatter'
import RatingStar from '@/components/RatingStar.vue'
import PreviewCardPrice from '@/components/PreviewCardPrice.vue'
import {computed, onMounted, ref, watch} from 'vue'
import SearchFormContentListItemMoreComponent
  from '@/components/forms/search/SearchFormContentListItemMoreComponent.vue'
import AboutHotelTabComponent
  from '@/components/tour/tabs/AboutHotelTabComponent.vue'
import HotelFeedbacksTabComponent
  from '@/components/tour/tabs/HotelFeedbacksTabComponent.vue'
import HotelMapTabComponent
  from '@/components/tour/tabs/HotelMapTabComponent.vue'
import {tourStore} from '@/stores/tourStore'
import TourDetailedCard from '@/components/tour/tour-detailed-card/TourDetailedCard.vue'
import {commonStore} from '@/stores/commonStore'
import router from '@/router'
import {Carousel, Navigation, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'SearchFormContentListItemComponent',
  methods: {
    formatAmount
  },
  props: {
    hotelCode: Number,
    hotelRating: String,
    hotelStars: String,
    hotelName: String,
    regionName: String,
    pictureLink: String,
    price: Number,
    currency: String,
    hotelDescription: String,
    tours: Array,
    searchCategory: String,
    tourId: String,
    operatorImage: String
  },
  components: {
    TourDetailedCard,
    HotelMapTabComponent,
    HotelFeedbacksTabComponent,
    AboutHotelTabComponent,
    SearchFormContentListItemMoreComponent,
    PreviewCardPrice,
    RatingStar,
    // CircleIcon
    Carousel,
    Navigation,
    // Pagination,
    Slide
  },
  setup(props) {
    const activeTab = ref(null)
    const moreClicked = ref(false)
    const hotelInfo = ref(null)
    const itemInfo = ref(null)
    const carouselRef = ref(null)
    const tourStoreInstance = tourStore()
    const commonStoreInstance = commonStore()
    const likeHovered = ref(false)
    const tourToOpen = ref(null)

    const itemImages = computed(() => {
      const images = itemInfo.value?.photos ?? null
      if (images) {
        return [props.pictureLink, ...images]
      }
      return [props.pictureLink]
    })

    const getTourInfo = async () => {
      const {data} = await tourStoreInstance
          .getShortHotelInfo(props.tourId ?? props.tours.at(0).tourid)
      if (!data) return

      itemInfo.value = data
    }

    watch(() => [props.tourId, props.tours], () => {
      itemInfo.value = null
      carouselRef.value?.slideTo(1)
      getTourInfo()
    })

    onMounted(async () => {
      await getTourInfo()
    })

    const handleMoreClick = async () => {
      if (props.searchCategory === 'hot-tours') {
        tourToOpen.value = {
          id: props.tourId ?? null,
          entityPaymentCategory: 'HOT_TOUR',
          logo: props.operatorImage ?? null
        }
      } else {
        moreClicked.value = !moreClicked.value
        activeTab.value = null
      }
    }
    const handleTabClick = tab => {
      activeTab.value = activeTab.value === tab ? null : tab
      moreClicked.value = false
      if (activeTab.value && props.hotelCode && !hotelInfo.value) {
        getHotelInfo()
      }
    }
    const openHotelCard = async () => {
      if (props.searchCategory === 'hot-tours') {
        tourToOpen.value = {
          id: props.tourId ?? null,
          entityPaymentCategory: 'HOT_TOUR',
          logo: props.operatorImage ?? null
        }
      } else if (props.tours && props.tours.length) {
        tourToOpen.value = {
          id: props.tours[0].tourid ?? null,
          entityPaymentCategory: router.currentRoute.value.name === 'search-tour' ? 'TOUR' : 'HOTEL',
          logo: props.tours[0].operatorImage ?? null
        }
      }
    }
    const onDetailedTourCardModalHide = () => {
      tourToOpen.value = null
      commonStoreInstance.changeModalOverlayVisibility(false, true)
    }
    const getHotelInfo = async () => {
      const response = await tourStoreInstance.searchTourHotel(props.hotelCode).then(({data}) => data)
      hotelInfo.value = response && response.data && response.data.hotel ? response.data.hotel : null
    }

    return {
      activeTab,
      moreClicked,
      hotelInfo,
      tourToOpen,
      likeHovered,
      itemImages,
      handleMoreClick,
      handleTabClick,
      openHotelCard,
      onDetailedTourCardModalHide,
      carouselRef,
      itemInfo
    }
  },
}
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border-radius: 50%;
  background-color: rgba(120, 120, 120, 0.5);
  color: rgba(0, 0, 0, 0.8);
}

.carousel__slide--prev,
.carousel__slide--next {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
  color: white;
  fill: white;
}
</style>

<style lang='scss' scoped>

@import '@/assets/css/variables';


.list--item {
  display: grid;

  &--preview {
    padding: 0 0 30px;
    display: grid;
    grid-template-columns: 240px auto;
    column-gap: 30px;

    &--image {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
      }

      @media screen and (min-width: 1679px) {
        height: 210px;

        img {
          height: 210px;
        }
      }

      @media screen and (min-width: 768px) {
        height: 175px;

        img {
          height: 175px;
        }
      }

      @media screen and (max-width: 767px) {
        height: 214px;

        img {
          height: 214px;
          border-radius: 16px 16px 0 0;
        }
      }
    }

    &--info {
      .info-header {
        .title {
          .name {
            font-family: GilroyBold;
            font-size: 16px;
            line-height: 24px;
            color: $base-black;
            margin-bottom: 5px;

            &:hover {
              text-decoration: underline;
              text-underline-offset: 3px;
            }

            @media screen and (max-width: 1679px) {
              margin-bottom: 2px;
            }

            @media screen and (max-width: 767px) {
              line-height: 16px;
              margin-bottom: 7px;
            }
          }

          .placement {
            font-family: GilroyRegular;
            font-size: 14px;
            line-height: 21px;
            color: #888888;
            margin-bottom: 10px;

            @media screen and (max-width: 1679px) {
              margin-bottom: 8px;
            }

            @media screen and (max-width: 767px) {
              line-height: 14px;
              margin-bottom: 10px;
            }
          }
        }

        .price {
          > div {
            min-width: 81px;
            color: $base-black;

            .value {
              font-family: GilroyBold;
              font-size: 20px;
              line-height: 30px;
            }

            .currency {
              margin-left: 5px;
              margin-top: 3px;
              font-family: GilroyRegular;
              font-size: 14px;
              line-height: 21px;
            }
          }

          > span {
            font-family: GilroyRegular;
            font-size: 14px;
            line-height: 21px;
            color: #888888;

            b {
              color: $base-black;
            }
          }

          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        .like {
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
      }

      .rating {
        margin-bottom: 20px;

        @media screen and (max-width: 1679px) {
          margin-bottom: 15px;
        }
      }

      .description {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
        margin-bottom: 23px;

        @media screen and (max-width: 1679px) {
          margin-bottom: 19px;
        }

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      .info-footer {
        position: relative;

        .tabs {
          display: grid;
          grid-template-columns: repeat(5, auto);

          .tab {
            border-radius: 30px;
            margin-bottom: 0;
            background: $base-beige;
            font-family: GilroyMedium;
            font-size: 12px;
            line-height: 12px;
            color: #666666;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            height: 28px;

            &:not(:last-child) {
              margin-right: 10px;
            }

            &.rating {
              width: 37px;
              background: #50D465;
              font-family: GilroyBold;
              font-size: 12px;
              line-height: 12px;
              color: $base-white;
            }

            &.rating, &.like {
              padding: 0;
            }

            &.like {
              @media screen and (max-width: 767px) {
                display: none;
              }
            }

            &.active {
              background: #777777;
              color: $base-white;
            }
          }

          &.hot-tours {
            margin-top: 51px;
          }

          @media screen and (max-width: 1679px) {
            &.hot-tours {
              margin-top: 61px;
            }
          }

          @media screen and (max-width: 767px) {
            margin-bottom: 20px;

            &.hot-tours {
              margin-top: 0;
            }
          }
        }

        .more {
          background: $base-orange;
          border-radius: 40px;
          padding: 7px 15px;
          width: max-content;

          &.hot-tours {
            margin-top: 44px;

            span {
              margin-right: 0;
            }

            img {
              display: none;
            }

            @media screen and (max-width: 1679px) {
              &.hot-tours {
                margin-top: 54px;
              }
            }

            @media screen and (max-width: 767px) {
              &.hot-tours {
                margin-top: 0;
              }
            }
          }

          span {
            font-family: GilroyMedium;
            font-size: 14px;
            line-height: 21px;
            color: $base-white;
            margin-right: 7px;
          }

          &.active {
            img {
              transform: rotate(180deg);
            }
          }

          @media screen and (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }

        .preview-card-price {
          @media screen and (max-width: 767px) {
            display: block;
            bottom: -20px;
            right: -15px;
            border-bottom-right-radius: 16px;
          }
        }

        @media screen and (max-width: 767px) {
          display: grid;
        }
      }

      @media screen and (max-width: 767px) {
        //border-radius: 0 0 16px 16px;
        border: 1px solid #E0E0E0;
        border-top: 0;
        border-bottom: 0;
        padding: 20px 15px 10px 20px;

        &.more-active {
          @media screen and (max-width: 767px) {
            border-radius: 0;
            border-bottom: 0;
            padding: 20px 15px 15px 20px;

            .preview-card-price {
              display: none;
            }
          }
        }
      }
    }

    &--tab-content:not(.d-none) {
      grid-column: span 2;
      padding-top: 30px;
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: 767px) {
        grid-column: span 1;
        border-left: 1px solid #E0E0E0;
        border-right: 1px solid #E0E0E0;
      }
    }

    @media screen and (max-width: 1679px) {
      column-gap: 20px;
      grid-template-columns: 240px auto;
    }

    @media screen and (max-width: 767px) {
      padding: 0 0 10px 0;
      column-gap: 0;
      grid-template-columns: auto;
      border-bottom: 1px solid #E0E0E0;
      border-radius: 0 0 16px 16px;
    }
  }

  &--line {
    height: 1px;
    background: #E0E0E0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &:not(:first-child) {
    margin-top: 30px;
  }

}

</style>
