<template>
  <div
    class="footer--toolbar--menu--item flex-row-center"
    @click="handleItemClick"
  >
    <div
      v-if="['hot-tours'].includes(type)"
      class="footer--toolbar--menu--item--image"
      :class="[type]"
    ></div>
    <div>{{ text }}</div>
  </div>
</template>

<script>

import { goToMainPage, goToSearchHotelPage, goToSearchHotTourPage, goToSearchTourPage } from '@/composables/router'

export default {
  name: 'FooterMenuItemComponent',
  props: {
    text: String,
    type: String,
  },
  setup(props) {
    const handleItemClick = () => {
      if (props.type === 'about-us') {
        goToMainPage('project-info')
      } else if (props.type === 'search-tours') {
        goToSearchTourPage()
      } else if (props.type === 'search-hotels') {
        goToSearchHotelPage()
      } else if (props.type === 'hot-tours') {
        goToSearchHotTourPage()
      }
    }

    return {
      handleItemClick
    }
  }
}
</script>
<style lang='scss' scoped>

@import '@/assets/css/variables';

.footer--toolbar--menu--item {
  font-family: GilroyMedium;
  font-size: 14px;
  line-height: 17px;
  color: #222222;

  &--image {
    width: 24px;
    height: 24px;
    margin-right: 4px;

    &.hot-tours {
      background-image: url('@/assets/images/common/flame.svg');
    }
  }

  > div:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

</style>
