<template>
  <div
    id="final-pay-form"
    class="final-form w-100"
  >
    <div
      class="go-back cursor-pointer flex-row-center"
      @click="$emit('go-back')"
    >
      <img
        :src="require('@/assets/images/common/arrow-left-gray-bold.svg')"
        alt="link"
      />
      <span>Назад</span>
    </div>
    <div class="title text-left">Покупка тура</div>
    <tour-detailed-card-body-tab-pay-final-form-field-set
      v-for="(set, setIndex) in fieldSets"
      :key="setIndex"
      :id="setIndex"
      :name="set.name"
      :choose="set.choose"
      :fields="set.fields"
      :class="set.class"
      @field-click="handleFieldClick"
      @change-choose="set.choose = $event"
    ></tour-detailed-card-body-tab-pay-final-form-field-set>
    <div class="info flex-row-start">
      <div class="total-price item flex-column-start">
        <div class="row total-price-value flex-row">
          <div
            v-if="promocodeApplied"
            class="sub-value text-left"
          >
            {{ formattedPriceWODiscount }}
          </div>
          <div class="value text-left">{{ formattedPrice }}</div>
          <div class="note text-left">
            {{ currencyLabel }}<br>за {{ entityPaymentCategory === 'HOTEL' ? 'номер' : 'тур' }}
          </div>
        </div>
        <div class="row instant-confirmation flex-row-center">
          <img
            :src="require('@/assets/images/common/flash-orange.svg')"
            alt="flash"
          />
          <span>мгновенное подтверждение</span>
        </div>
      </div>
      <div class="price-info item">
        <div class="row text-left flex-row">
          <div class="title">Стоимость тура:</div>
          <div class="price">{{ formattedPrice }} {{ currencyLabel }}.</div>
        </div>
        <div class="row text-left flex-row">
          <div class="title">Топливный сбор:</div>
          <div class="price">{{ formattedFuelCharge }} {{ currencyLabel }}.</div>
        </div>
      </div>
      <div
        class="agreement item"
        @click="handleClickAgreement"
      >
        <input
          id="checkbox"
          type="checkbox"
          v-model="agreementChecked"
        >
        <label for="checkbox" class="text-left flex-row-start">
          <span>Я ознакомлен с условиями договора оферты о реализации туристического досуга</span>
        </label>
      </div>
    </div>
    <div class="go-to-pay-buttons-wrapper flex-row">
      <div
        class="go-to-pay"
        :class="{
        'active': agreementChecked,
        'loading': isLoadingPay,
      }"
        @click="goToPay"
      >
        Перейти к оплате
      </div>
      <div
        class="go-to-pay-by-credit"
        :class="{
        'active': agreementChecked,
        'loading': isLoadingPayByCredit,
      }"
        @click="goToPayByCredit"
      >
        Перейти к оплате в кредит
      </div>
    </div>
    <div class="notice text-left">
      <span>Покупая тур, Вы подтверждаете</span>
      <span
        class="orange cursor-pointer"
        @click="openDocument('user-agreement')"
      > согласие</span>
      <span> на обработку персональных данных.</span>
      <span
        class="orange cursor-pointer"
        @click="openDocument('privacy-policy')"
      > Политика обработки персональных данных</span>
    </div>

    <system-notify
      v-if="hasError"
      :text="systemNotifyText"
      @close="handleCloseSystemNotify"
      custom-class="position-absolute"
    ></system-notify>
  </div>
</template>

<script>

import TourDetailedCardBodyTabPayFinalFormFieldSet
  from '@/components/tour/tour-detailed-card/tabs/pay/TourDetailedCardBodyTabPayFinalFormFieldSet.vue'
import { computed, onMounted, ref } from 'vue'
import { dateIsValid, emailIsValid } from '@/composables/validator'
import { formatAmount } from '@/composables/priceFormatter'
import { scrollIntoView } from '@/composables/scroll'
import { openDocument } from '@/composables/docs'
import { phoneToString } from '@/composables/phoneFormatter'
import { paymentStore } from '@/stores/paymentStore'
import { promocodeStore } from '@/stores/promocodeStore'
import { setItemToLocalStorage } from '@/composables/localStorage'
import SystemNotify from '@/components/SystemNotify.vue'

export default {
  name: 'TourDetailedCardBodyTabPayFinalForm',
  methods: {
    openDocument
  },
  components: {
    SystemNotify, TourDetailedCardBodyTabPayFinalFormFieldSet
  },
  emits: ['go-back'],
  props: {
    paymentType: String,
    amount: Number,
    fuelCharge: Number,
    currency: String,
    tourId: String,
    adultsCount: Number,
    childrenCount: Number,
    entityPaymentCategory: String,
    placement: String,
    meal: String,
  },
  setup(props) {
    const fieldSets = ref([])
    const isLoadingPay = ref(false)
    const isLoadingPayByCredit = ref(false)
    const agreementChecked = ref(false)
    const formattedPriceWODiscount = computed(() => formatAmount(props.amount))
    const formattedPrice = computed(() => formatAmount(props.amount - promocodeDiscount.value))
    const formattedFuelCharge = computed(() => formatAmount(props.fuelCharge))
    const currencyLabel = computed(() => {
      let label = null
      switch (props.currency) {
        case 'RUB':
          label = 'руб'
          break;
      }
      return label
    })
    const needRussianPassport = computed(
      () => props.placement ? props.placement.toLowerCase().indexOf('россия') > -1 : false
    )
    const hasError = ref(false)
    const systemNotifyText = ref('')
    const promocodeApplied = ref('')
    const promocodeDiscount = ref(0)

    const loadFieldSets = () => {
      if (props.adultsCount && props.adultsCount > 0) {
        for (let i = 0; i < props.adultsCount; i++) {
          addPerson(i, 'Взрослый турист', 'adult')
        }
      }

      if (props.childrenCount && props.childrenCount > 0) {
        for (let i = 0; i < props.childrenCount; i++) {
          addPerson(i, 'Ребенок', 'child')
        }
      }

      fieldSets.value.push({
        name: 'Информация о покупателе',
        choose: 0,
        fields: [[
          {
            key: 'customerFio',
            customLabel: 'ФИО покупателя',
            type: 'text',
            required: true,
            value: null,
            errors: []
          },
          {
            key: 'customerPhoneNumber',
            customLabel: 'Моб. телефон',
            type: 'phone',
            required: true,
            value: null,
            errors: []
          },
          {
            key: 'customerEmail',
            customLabel: 'Электронная почта',
            type: 'text',
            required: true,
            value: null,
            errors: []
          },
          {
            key: 'customerAddress',
            customLabel: 'Адрес',
            type: 'text',
            required: false,
            value: null,
            errors: []
          },
          {
            key: 'promocode',
            customLabel: 'Ввести промокод',
            type: 'text',
            required: false,
            value: null,
            errors: []
          },
          {
            key: 'read-agreement',
            label: 'Ознакомиться с договором',
            type: 'button',
            class: 'cursor-pointer',
            errors: []
          },
        ]],
        type: 'payer'
      })
    }
    const addPerson = (index, touristLabel, personType) => {
      let adultNumber = index + 1
      if (needRussianPassport.value) {
        fieldSets.value.push({
          name: `${touristLabel} ${adultNumber}`,
          class: 'russian-tourist',
          choose: 0,
          fields: [[
            {
              key: 'lastName',
              customLabel: 'Фамилия',
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'firstName',
              customLabel: 'Имя',
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'middleName',
              customLabel: 'Отчество',
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'birthday',
              customLabel: 'Дата рождения',
              placeholder: 'дд.мм.гггг',
              type: 'date',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'citizenship',
              customLabel: 'Гражданство',
              placeholder: null,
              type: 'text',
              required: true,
              value: 'Россия',
              errors: []
            },
            {
              key: 'birthplace',
              customLabel: 'Место рождения (как в паспорте)',
              placeholder: null,
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
          ]],
          type: 'person'
        })
        if (personType === 'adult') {
          fieldSets.value.push({
            name: 'Паспорт РФ',
            class: 'russian-passport',
            choose: 0,
            fields: [
              [
                {
                  key: 'seriesPassport',
                  customLabel: 'Серия',
                  type: 'text',
                  placeholder: '4507',
                  required: true,
                  regexRule: /^\d{4}$/,
                  value: null,
                  dataMaska: ['####'],
                  description: 'Введите 4 цифры',
                  errors: []
                },
                {
                  key: 'numberPassport',
                  customLabel: 'Номер',
                  type: 'text',
                  placeholder: '123456',
                  required: true,
                  regexRule: /^\d{6}$/,
                  value: null,
                  dataMaska: ['######'],
                  description: 'Введите 6 цифр',
                  errors: []
                },
                {
                  key: 'dateOfIssuePassport',
                  customLabel: 'Дата выдачи',
                  type: 'date',
                  placeholder: 'дд.мм.гггг',
                  required: true,
                  value: null,
                  errors: []
                },
                {
                  key: 'issuedBy',
                  customLabel: 'Кем выдан',
                  type: 'text',
                  required: true,
                  value: null,
                  class: 'issued-by',
                  errors: []
                },
                {
                  key: 'dateValidUntilPassport',
                  customLabel: 'Действует до',
                  type: 'date',
                  required: true,
                  value: null,
                  placeholder: 'дд.мм.гггг',
                  errors: []
                },
              ],
            ],
            type: personType
          })
        } else {
          fieldSets.value.push({
            name: [
              'Паспорт РФ',
              'Свидетельство о рождении',
            ],
            class: 'russian-passport',
            choose: 0,
            fields: [
              [
                {
                  key: 'seriesPassport',
                  customLabel: 'Серия',
                  type: 'text',
                  placeholder: '4507',
                  required: true,
                  regexRule: /^\d{4}$/,
                  value: null,
                  dataMaska: ['####'],
                  description: 'Введите 4 цифры',
                  errors: []
                },
                {
                  key: 'numberPassport',
                  customLabel: 'Номер',
                  type: 'text',
                  placeholder: '123456',
                  required: true,
                  regexRule: /^\d{6}$/,
                  value: null,
                  dataMaska: ['######'],
                  description: 'Введите 6 цифр',
                  errors: []
                },
                {
                  key: 'dateOfIssuePassport',
                  customLabel: 'Дата выдачи',
                  type: 'date',
                  placeholder: 'дд.мм.гггг',
                  required: true,
                  value: null,
                  errors: []
                },
                {
                  key: 'issuedBy',
                  customLabel: 'Кем выдан',
                  type: 'text',
                  required: true,
                  value: null,
                  class: 'issued-by',
                  errors: []
                },
                {
                  key: 'dateValidUntilPassport',
                  customLabel: 'Действует до',
                  type: 'date',
                  required: true,
                  value: null,
                  placeholder: 'дд.мм.гггг',
                  errors: []
                },
              ],
              [
                {
                  key: 'seriesCertificate',
                  customLabel: 'Серия',
                  type: 'text',
                  placeholder: 'IV-АИ',
                  required: true,
                  regexRule: /^[IVX]{1,2}-[А-Я]{2}$/,
                  value: null,
                  description: 'Введите серию вида, римское число, дефис, две кириллические буквы',
                  errors: []
                },
                {
                  key: 'numberCertificate',
                  customLabel: 'Номер',
                  type: 'text',
                  placeholder: '123456',
                  required: true,
                  regexRule: /^\d{6}$/,
                  value: null,
                  dataMaska: ['######'],
                  description: 'Введите 6 цифр',
                  errors: []
                },
                {
                  key: 'dateOfIssueCertificate',
                  customLabel: 'Дата выдачи',
                  type: 'date',
                  placeholder: 'дд.мм.гггг',
                  required: true,
                  value: null,
                  errors: []
                },
              ],
            ],
            type: personType
          })
        }
      } else {
        fieldSets.value.push({
          name: `${touristLabel} ${adultNumber}`,
          choose: 0,
          fields: [[
            {
              key: 'firstName',
              customLabel: 'Имя (лат)',
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'lastName',
              customLabel: 'Фамилия (лат)',
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'birthday',
              customLabel: 'Дата рождения',
              placeholder: 'дд.мм.гггг',
              type: 'date',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'citizenship',
              customLabel: 'Гражданство',
              placeholder: null,
              type: 'text',
              required: true,
              value: null,
              errors: []
            },
          ]],
          type: 'person'
        })
        fieldSets.value.push({
          name: 'Заграничный паспорт',
          class: 'foreign-passport',
          choose: 0,
          fields: [[
            {
              key: 'seriesPassport',
              customLabel: 'Серия',
              type: 'text',
              placeholder: '71',
              required: true,
              regexRule: /^\d{2}$/,
              value: null,
              dataMaska: ['##'],
              description: 'Введите 2 цифры',
              errors: []
            },
            {
              key: 'numberPassport',
              customLabel: 'Номер',
              type: 'text',
              placeholder: '1234567',
              required: true,
              regexRule: /^\d{7}$/,
              value: null,
              dataMaska: ['#######'],
              description: 'Введите 7 цифр',
              errors: []
            },
            {
              key: 'dateOfIssuePassport',
              customLabel: 'Дата выдачи',
              type: 'date',
              placeholder: 'дд.мм.гггг',
              required: true,
              value: null,
              errors: []
            },
            {
              key: 'issuedBy',
              customLabel: 'Кем выдан',
              type: 'text',
              required: true,
              value: null,
              class: 'issued-by',
              errors: []
            },
            {
              key: 'dateValidUntilPassport',
              customLabel: 'Действует до',
              type: 'date',
              required: true,
              value: null,
              placeholder: 'дд.мм.гггг',
              errors: []
            },
          ]],
          type: personType
        })
      }
    }
    const goToPay = async () => {
      if (isLoadingPay.value || isLoadingPayByCredit.value) return
      if (!agreementChecked.value && formInvalid()) {
        return
      }

      isLoadingPay.value = true

      const currentDate = new Date()
      setItemToLocalStorage('payment-time', currentDate.getTime())
      paymentStore().payTour(getPayload())
        .then(({ data }) => location.href = data)
        .catch(() => {
          systemNotifyText.value = 'Не удалось перейти к оплате. Пожалуйста, обратитесь в техническую поддержку.'
          hasError.value = true
        })
        .finally(() => isLoadingPay.value = false)
    }
    const goToPayByCredit = async () => {
      if (isLoadingPay.value || isLoadingPayByCredit.value) return
      if (!agreementChecked.value && formInvalid()) {
        return
      }

      isLoadingPayByCredit.value = true

      const currentDate = new Date()
      setItemToLocalStorage('payment-time', currentDate.getTime())
      paymentStore().payTourByCredit(getPayload())
        .then(({ data }) => location.href = data)
        .catch(() => {
          systemNotifyText.value = 'Не удалось перейти к оплате в кредит. Пожалуйста, обратитесь в техническую поддержку.'
          hasError.value = true
        })
        .finally(() => isLoadingPayByCredit.value = false)
    }
    const formInvalid = () => {
      let invalid = false
      fieldSets.value.map(fieldSet => {
        fieldSet.fields[fieldSet.choose].map(field => {
          if (field.required && !field.value) {
            invalid = true
            field.errors = []
            field.errors.push(
              `${field.customLabel ? '"' + field.customLabel + '"' : 'Поле'} не может быть пустым`
            )
          }
          if (field.key === 'customerEmail' && field.value && !emailIsValid(field.value)) {
            invalid = true
            field.errors = []
            field.errors.push('Неверный формат email')
          }
          if (
            ['dateOfIssuePassport', 'dateValidUntilPassport', 'birthday'].includes(field.key)
            && field.value
            && !dateIsValid(field.value.toString().substring(0, 10))
          ) {
            invalid = true
            field.errors = []
            field.errors.push('Неверный формат даты')
          }
          if (field.regexRule && field.value && !field.value.match(field.regexRule)) {
            invalid = true
            field.errors = []
            field.errors.push(field.description)
          }
        })
      })

      return invalid
    }
    const getPayload = () => {
      const payload = {
        tourId: props.tourId,
        meal: props.meal,
        customerFio: null,
        customerPhoneNumber: null,
        customerEmail: null,
        customerAddress: null,
        amount: props.amount ?? 0,
        amountFuel: props.fuelCharge ?? 0,
        tourists: [],
        tourType: props.entityPaymentCategory
      }
      const excludedFieldKeys = ['read-agreement']
      const touristDataTemplate = {
        firstName: null,
        lastName: null,
        middleName: null,
        citizenship: null,
        dateOfIssueCertificate: null,
        seriesCertificate: null,
        numberCertificate: null,
        seriesPassport: null,
        numberPassport: null,
        dateOfIssuePassport: null,
        dateValidUntilPassport: null,
        issuedBy: null,
        birthday: null,
        birthplace: null,
      }
      let touristData = {...touristDataTemplate}
      fieldSets.value.map(fieldSet => {
        const fieldSetData = {}
        fieldSet.fields[fieldSet.choose].map(field => {
          if (!excludedFieldKeys.includes(field.key)) {
            fieldSetData[field.key] = field.value ?? null
          }
        })
        if (fieldSet.type === 'person') {
          touristData.lastName = fieldSetData.lastName ?? null
          touristData.firstName = fieldSetData.firstName ?? null
          touristData.middleName = fieldSetData.middleName ?? null
          touristData.birthday = fieldSetData.birthday ?? null
          touristData.citizenship = fieldSetData.citizenship ?? null
          touristData.birthplace = fieldSetData.birthplace ?? null
        } else if (['adult', 'child'].includes(fieldSet.type)) {
          if (fieldSet.choose === 0) {
            touristData.issuedBy = fieldSetData.issuedBy ?? null
            touristData.dateOfIssuePassport = fieldSetData.dateOfIssuePassport ?? null
            touristData.dateValidUntilPassport = fieldSetData.dateValidUntilPassport ?? null
            touristData.seriesPassport = fieldSetData.seriesPassport ?? null
            touristData.numberPassport = fieldSetData.numberPassport ?? null
          }
          if (fieldSet.choose === 1) {
            touristData.dateOfIssueCertificate = fieldSetData.dateOfIssueCertificate ?? null
            touristData.seriesCertificate = fieldSetData.seriesCertificate ?? null
            touristData.numberCertificate = fieldSetData.numberCertificate ?? null
          }
          payload.tourists.push(touristData)
          touristData = {...touristDataTemplate}
        } else if (fieldSet.type === 'payer') {
          payload.customerFio = fieldSetData.customerFio ?? null
          payload.customerEmail = fieldSetData.customerEmail ?? null
          payload.customerPhoneNumber = fieldSetData.customerPhoneNumber
            ? phoneToString(fieldSetData.customerPhoneNumber) : null
          payload.customerAddress = fieldSetData.customerAddress ?? null
          payload.promocode = promocodeApplied.value !== '' ? promocodeApplied.value : null
        }
      })
      return payload
    }
    const handleFieldClick = fieldKey => {
      if (fieldKey === 'read-agreement') {
        openDocument('legal-information')
      }

      if (fieldKey === 'promocode') {
        fieldSets.value.map((fieldSet) => {
          fieldSet.fields[fieldSet.choose].map((field) => {
            if (field.key === 'promocode') {
              field.errors = []

              if (promocodeApplied.value === '') {
                if (field.value) {
                  promocodeStore().checkPromocode(field.value)
                    .then(({ data }) => {
                      promocodeApplied.value = field.value
                      promocodeDiscount.value = parseInt(data)
                      field.disabled = true
                    })
                    .catch(() => {
                      field.errors.push('')
                    })
                }
              }
            }
          })
        })
      }
    }
    const handleClickAgreement = event => {
      if (!agreementChecked.value && formInvalid()) {
        event.preventDefault()
      }
    }
    const handleCloseSystemNotify = () => {
      hasError.value = false
      systemNotifyText.value = ''
    }

    onMounted(() => {
      loadFieldSets()
      scrollIntoView('final-pay-form')
    })

    return {
      agreementChecked,
      fieldSets,
      formattedPriceWODiscount,
      formattedPrice,
      formattedFuelCharge,
      currencyLabel,
      systemNotifyText,
      hasError,
      promocodeApplied,
      promocodeDiscount,
      isLoadingPay,
      isLoadingPayByCredit,
      goToPay,
      goToPayByCredit,
      handleFieldClick,
      handleClickAgreement,
      handleCloseSystemNotify,
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

@keyframes l13{
  100%{transform: rotate(1turn)}
}

.loading::after {
  content: '';
  position: absolute;
  top: 16px;
  left: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background:
      radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,#ffa516);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.tab-content.pay {
  .final-form {
    max-width: 1045px;

    .go-back {
      margin-top: 20px;
      margin-bottom: 40px;

      img {
        width: 13px;
        height: 13px;
      }

      span {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 14px;
        margin-left: 3px;
        color: #888888;
      }
    }

    >.title {
      font-family: GilroyMedium;
      font-size: 24px;
      line-height: 24px;
      color: $base-black;
      margin-bottom: 40px;
    }

    .info {
      padding-top: 30px;

      .item {
        &:not(:last-child) {
          margin-right: 67px;
        }

        &.total-price {
          .row {
            &.total-price-value {
              margin-bottom: 8px;

              .sub-value {
                align-self: end;
                font-family: GilroySemibold;
                font-size: 18px;
                color: $base-black;
                margin-right: 10px;
                text-decoration: line-through;
              }

              .value {
                font-family: GilroyBold;
                font-size: 36px;
                line-height: 36px;
                color: $base-black;
                margin-right: 10px;
              }

              .note {
                font-family: GilroyMedium;
                font-size: 12px;
                line-height: 14px;
                color: #888888;
              }
            }

            &.instant-confirmation {
              img {
                width: 20px;
                height: 20px;
              }

              span {
                font-family: GilroyMedium;
                font-size: 12px;
                line-height: 14px;
                color: #888888;
                text-transform: uppercase;
              }
            }
          }
        }
        &.price-info {
          .row {
            &:first-child {
              margin-bottom: 10px;
            }

            div {
              font-family: GilroyMedium;
              color: #888888;

              &.title {
                font-size: 12px;
                line-height: 14.4px;
                text-transform: uppercase;
              }

              &.price {
                font-size: 14px;
                line-height: 17px;
                margin-left: 24px;
              }
            }
          }
        }

        &.agreement {
          input[type="checkbox"] {
            display: none;
          }

          input[type="checkbox"] + label {
            min-width: 395px;
            max-width: 425px;
            float: left;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            font-family: GilroyRegular;
            font-size: 14px;
            line-height: 19.6px;
            color: #888888;
          }

          input[type="checkbox"] + label::before {
            float: left;
            content: "";
            vertical-align: -25%;
            height: 18px;
            width: 18px;
            min-width: 18px;
            border: 2px solid $base-black;
            border-radius: 3px;
            padding: 0;
            margin-right: 10px;
          }

          input[type="checkbox"]:checked + label::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: url('@/assets/images/common/checked.svg');
            background-position: center;
            background-repeat: no-repeat;
            top: 1px;
            left: 1px;
          }
        }
      }
    }

    .go-to-pay {
      margin-top: 30px;
      border-radius: 50px;
      padding: 28px 0 26px 0;
      background: $base-gray;
      width: 510px;
      font-family: GilroyMedium;
      font-size: 16px;
      line-height: 16px;
      color: $base-black;
      cursor: default;
      margin-right: 25px;

      &.active {
        background: $base-orange;
        color: $base-white;
        cursor: pointer;
      }
    }


    .go-to-pay-by-credit {
      margin-top: 30px;
      border-radius: 50px;
      padding: 28px 0 26px 0;
      background: $base-gray;
      width: 510px;
      font-family: GilroyMedium;
      font-size: 16px;
      line-height: 16px;
      color: $base-black;
      cursor: default;

      &.active {
        background: #ffdd2d;
        color: $base-black;
        cursor: pointer;
      }
    }

    .notice {
      margin-top: 30px;
      border-radius: 8px;
      padding: 28px 0 26px 20px;
      background: #F8F8F8;

      span {
        font-family: GilroyRegular;
        font-size: 16px;
        line-height: 16px;
        color: #888888;

        &.orange {
          color: #FF8500;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    .final-form {
      .info {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 25px;

        .item {
          &.total-price, &.price-info {
            margin-right: 0;
          }

          &.total-price {
            margin-bottom: 25px;
          }

          &.price-info {
            margin-bottom: 30px;
          }

          &.agreement {
            grid-column: span 2;
            margin-bottom: 10px;
          }
        }
      }

      .go-to-pay {
        width: 100%;
      }

      .go-to-pay-by-credit {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    .final-form {
      .info {
        padding-top: 20px;
        display: flex;
        flex-direction: column;

        .item {
          &.total-price, &.price-info {
            margin-right: 0;
          }

          &.total-price {
            margin-bottom: 25px;
          }

          &.price-info {
            margin-bottom: 30px;
          }

          &.agreement {
            margin-bottom: 10px;

            input[type="checkbox"] + label {
              min-width: unset;
            }
          }
        }
      }

      .go-to-pay-buttons-wrapper {
        flex-direction: column;

        .go-to-pay {
          width: 100%;
        }

        .go-to-pay-by-credit {
          width: 100%;
        }
      }
    }
  }
}

</style>
