<template>
  <div class="tours-list">
    <div class="tours-list--actual">
      <main-page-actual-tours
        :departure-city="departureCity"
        :departure-city-from="departureCityFrom"
      ></main-page-actual-tours>
    </div>
  </div>
  <div
    id="project-info"
    class="project-info"
  >
    <main-page-about-project></main-page-about-project>
    <main-page-advantages></main-page-advantages>
<!--    <mobile-app class="mobile-app"></mobile-app>-->
  </div>
  <questions-answers></questions-answers>

  <tour-detailed-card
    v-if="tourId"
    :tour-id="tourId"
    :tour-logo="logo"
    :entity-payment-category="entityPaymentCategory"
    @hide-modal="onDetailedTourCardModalHide"
  ></tour-detailed-card>
</template>

<script>

import { computed, onMounted, ref, watch } from 'vue'
import MainPageActualTours from '@/pages/main-page/MainPageActualTours.vue'
import { commonStore } from '@/stores/commonStore'
import TourDetailedCard from '@/components/tour/tour-detailed-card/TourDetailedCard.vue'
import { authStore } from '@/stores/authStore'
import { goToAccountPage } from '@/composables/router'
import { accountPageStore } from '@/stores/accountPageStore'
import { getItemFromLocalStorage, removeItemFromLocalStorage } from '@/composables/localStorage'

export default {
  name: 'MainPage',
  components: {
    MainPageActualTours, TourDetailedCard
  },
  setup () {
    const departureCity = ref(1)
    const departureCityFrom = ref('Москвы')
    const accountPageStoreInstance = accountPageStore()
    const commonStoreInstance = commonStore()
    const currentShareLinkHash = computed(() => commonStoreInstance.currentShareLinkHash)
    const tourId = ref(null)
    const logo = ref(null)
    const entityPaymentCategory = ref(null)
    const userIsAuthorized = computed(() => authStore().userIsAuthorized)

    const onDetailedTourCardModalHide = () => {
      tourId.value = null
      logo.value = null
      entityPaymentCategory.value = null
      commonStoreInstance.changeModalOverlayVisibility(false, true)
    }
    const openShareTour = () => {
      const stringJson = atob(currentShareLinkHash.value)
      if (stringJson) {
        const json = JSON.parse(stringJson)
        tourId.value = json.tourId ? json.tourId.toString() : null
        logo.value = json.logo ?? null
        entityPaymentCategory.value = json.entityPaymentCategory ?? null
      }
      commonStoreInstance.setShareLinkHash(null)
    }

    watch(currentShareLinkHash, () => {
      if (currentShareLinkHash.value) {
        openShareTour()
      }
    })

    onMounted(() => {
      if (currentShareLinkHash.value) {
        openShareTour()
      }

      const paymentTime = getItemFromLocalStorage('payment-time')
      const currentDate = new Date()
      if (
        userIsAuthorized.value
        && document.referrer
        && (
          document.referrer.indexOf('securepayments.tinkoff.ru') > -1
          || document.referrer.indexOf('forma.tinkoff.ru') > -1
        )
        && (currentDate.getTime() - paymentTime < 600000)
      ) {
        removeItemFromLocalStorage('payment-time')
        accountPageStoreInstance.selectMenuItem(accountPageStoreInstance.accountMenu.find(m => m.type === 'tours'))
        goToAccountPage()
      } else if (paymentTime) {
        removeItemFromLocalStorage('payment-time')
      }
    })

    return {
      departureCity,
      departureCityFrom,
      tourId,
      logo,
      entityPaymentCategory,

      onDetailedTourCardModalHide
    }
  },
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tours-list {
  padding: 120px 120px 0;
  background: #f8f8f8;

  @media (min-width: 1678px) {
    width: calc(100% - 240px);
  }

  @media screen and (max-width: 1679px) {
    padding: 70px 24px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 80px 10px 0;
  }

  &--actual {
    padding: 0 0 100px;
  }
}

.project-info {
  padding: 120px 120px 150px 120px;

  @media (min-width: 1678px) {
    width: calc(100% - 240px);

    .mobile-app {
      width: 100%;
    }
  }

  @media screen and (max-width: 1679px) {
    padding: 70px 24px;
  }

  @media screen and (max-width: 767px) {
    padding: 70px 10px 50px 10px;
  }
}

</style>
