<template>
  <div class="choose-block">
    <h4>{{ title }}</h4>

    <div class="choose-block--list">
      <template v-if="groups">
        <div
          v-for="[groupName, items] of options"
          :key="groupName"
          class="choose-block--list--group"
        >
          <div class="choose-block--list--group--name">
            {{ groupName }}
          </div>

          <main-page-search-block-list-items
            :id="`${id}-${groupName}`"
            v-model="modelValue"
            :multiple="multiple"
            :options="items"
            :optionValue="optionValue"
            :optionAny="optionAny"
          ></main-page-search-block-list-items>
        </div>
      </template>
      <template v-else>
        <main-page-search-block-list-items
          :id="id"
          v-model="modelValue"
          :multiple="multiple"
          :options="options"
          :optionValue="optionValue"
          :optionAny="optionAny"
          :list="list"
        ></main-page-search-block-list-items>
      </template>
    </div>

    <div
      v-if="useConfirmButton"
      class="choose-block--save"
      :class="{ 'active': isChanged }"
      @click="handleClickSave"
    >Выбрать</div>
  </div>
</template>

<script>
import { ref } from 'vue'
import MainPageSearchBlockListItems from '@/pages/main-page/MainPageSearchBlockListItems.vue'

export default {
  name: 'MainPageSearchBlockList',
  components: { MainPageSearchBlockListItems },
  emits: ['save'],
  props: {
    id: {
      type: String,
      required: true,
    },
    model: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      required: true,
    },
    groups: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    optionValue: {
      type: String,
      default: () => null,
      required: false,
    },
    optionAny: {
      type: String,
      default: () => 'Any',
      required: false,
    },
    list: Boolean,
    useConfirmButton: {
      type: Boolean,
      default: () => true,
      required: false
    }
  },
  setup (props, { emit }) {
    const modelValue = ref(props.model)
    const isChanged = ref(false)

    const handleClickSave = () => emit('save', modelValue.value)

    return {
      modelValue,
      isChanged,
      handleClickSave,
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.isChanged = true
        if (!this.useConfirmButton) {
          this.$emit('save', this.modelValue)
        }
      },
      deep: true
    }
  },
}
</script>

<style scoped lang="scss">
.choose-block {
  position: absolute !important;
  top: 70px;
  left: 0;
  padding: 20px;
  width: 245px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  h4 {
    margin: 10px 0;
    padding-bottom: 10px;
    text-align: left;
    font-family: GilroyBold;
    font-size: 16px;
    font-weight: normal;
    color: #222222;
    line-height: 1;
    text-transform: uppercase;
  }

  &--list {
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &--group {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      &--name {
        text-align: left;
        font-family: GilroyBold;
        font-size: 14px;
        color: #222222;
        line-height: normal;
      }
    }
  }

  &--save {
    margin-top: 20px;
    padding: 12px 0;
    text-align: center;
    font-family: GilroyRegular;
    font-size: 14px;
    color: #222222;
    line-height: 1;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 40px;
    cursor: pointer;

    &.active {
      color: #ffffff;
      background: linear-gradient(225deg, #ff8500 0%, #ff4800 100%);
      border-color: transparent;
    }
  }
}
</style>
