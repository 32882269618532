<template>
  <div class="field-set flex-column-start">
    <div
      v-if="Array.isArray(name)"
      class="title text-left"
    >
      <div
        v-for="(item, index) in name"
        :key="index"
      >
        <input
          v-model="chooseName"
          type="radio"
          :id="`choose_${id}_${index}`"
          :value="index"
          @change="$emit('change-choose', index)"
        >
        <label :for="`choose_${id}_${index}`">{{ item }}</label>
      </div>
    </div>
    <div
      v-else
      class="title text-left"
    >{{ name }}</div>

    <template
      v-for="(group, index) in fields"
      :key="index"
    >
      <div
        v-if="chooseName === index"
        class="form w-100"
      >
        <base-form-field-component
          v-for="(field, index) in group"
          :key="field.key"
          :placeholder="field.placeholder"
          :field-key="field.key"
          :label="field.label"
          :custom-label="field.customLabel"
          :type="field.type"
          :required="field.required"
          :value="field.value"
          :description="field.description"
          :index="`${id}-${index}`"
          :class="field.class"
          :disabled="field.disabled"
          :error-messages="field.errors"
          :data-maska="field?.dataMaska"
          @keydown="field.errors = field.errors && field.errors.length ? [] : field.errors"
          @blur="onBlueField(field)"
          @update:value="field.value = $event"
          @click="$emit('field-click', $event)"
        ></base-form-field-component>
      </div>
    </template>
  </div>
</template>

<script>

import BaseFormFieldComponent from '@/components/forms/BaseFormFieldComponent.vue'
import { ref } from 'vue'

export default {
  name: 'TourDetailedCardBodyTabPayFinalFormFieldSet',
  components: {
    BaseFormFieldComponent,
  },
  emits: ['field-click', 'change-choose'],
  props: {
    id: Number,
    name: [String, Array],
    fields: Array,
    choose: Number,
  },

  setup() {
    const chooseName = ref(0)

    const onBlueField = (field) => {
      if (field.regexRule && field.value && !field.value.match(field.regexRule)) {
        field.errors = []
        field.errors.push(field.description)
      }
    }

    return {
      chooseName,

      onBlueField,
    }
  }
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.field-set {
  margin-bottom: 30px;

  &.russian-tourist {
    .form {
      grid-template-columns: auto auto auto;
    }
  }

  &.russian-passport {
    .form {
      grid-template-columns: auto auto auto;

      .field {
        &.issued-by {
          grid-column: 1 / span 2;
        }
      }
    }
  }

  &.foreign-passport {
    .form {
      grid-template-columns: auto auto auto;

      .field {
        &.issued-by {
          grid-column: 1 / span 2;
        }
      }
    }
  }

  .title {
    font-family: GilroySemibold;
    font-size: 20px;
    line-height: 20px;
    color: $base-black;
    margin-bottom: 30px;

    display: flex;
    flex-flow: row nowrap;
    gap: 30px;

    input[type='radio'] {
      display: none;

      + label {
        position: relative;
        padding-left: 32px;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 22px;
          height: 22px;
          border: 1px solid #D6D6D6;
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 5px;
          left: 5px;
          width: 14px;
          height: 14px;
          background: #F8F8F8;
          border-radius: 50%;
        }
      }

      &:checked + label {
        &:before {
          border-color: #FF8500;
        }

        &:after {
          background: #FF8500;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .title {
      display: block;
    }
  }

  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 25px;
    column-gap: 25px;

    .field {
      display: grid;

      .custom-label {
        position: absolute;
        z-index: 1;
        top: 15px;
        left: 20px;
        font-family: GilroyRegular;
        font-size: 13px;
        line-height: 13px;
        color: #888888;

        .required {
          color: $base-red;
          font-size: 15px;
        }
      }

      input {
        height: 16px;
        padding: 37px 20px 15px 20px;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        color: $base-black;
        font-family: GilroyMedium;
        font-size: 16px;
        line-height: 16px;
        outline: none;

        &::placeholder {
          color: #888888;
        }

        &:disabled {
          background: $base-beige;
        }

        &:hover {
          border-color: #888888;

          &::placeholder {
            color: transparent;
          }
        }

        &:focus {
          border-color: #FF8500;

          &::placeholder {
            color: transparent;
          }
        }

        &[data-key='promocode'] {
          &.invalid {
            color: #FF4800;
            border-color: #E0E0E0 !important;
          }

          &:disabled {
            border-color: #50D465;
            background: none;

            + .apply-link {
              opacity: .4;
              cursor: default;
            }
          }
        }
      }

      .form-button {
        width: 100%;
        padding: 28px 0 26px 0;
        border-radius: 50px;
        border: 1px solid $base-orange;
        font-family: GilroyRegular;
        font-size: 16px;
        line-height: 16px;
        color: $base-black;

        &.disabled {
          cursor: default;
        }
      }

      .apply-link {
        position: absolute !important;
        top: 27px;
        right: 20px;
        color: #FF8500;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 767px) {
    .form {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}

</style>
