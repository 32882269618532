<template>
  <teleport to="#app-overlay">
    <base-sign-in-form-component
      title="Регистрация"
      form-type="registration"
      :form-class="customClass ? `${customClass} registration` : 'registration'"
      :fields="fields"
      :action-button-text="'Зарегистрироваться'"
      :max-height="'700px'"
      :redirect-to-account-page-after-success-sing-in="redirectToAccountPageAfterSuccessSingIn"
      @open-login-form="$emit('open-form', 'login')"
      @close-form="$emit('close-form')"
    ></base-sign-in-form-component>
  </teleport>
</template>

<script>

import BaseSignInFormComponent from '@/components/forms/sign-in/BaseFormComponent.vue'
import { ref } from 'vue'

export default {
  name: 'RegistrationFormComponent',
  emits: ['open-form', 'close-form'],
  components: {
    BaseSignInFormComponent
  },
  props: {
    redirectToAccountPageAfterSuccessSingIn: {
      type: Boolean,
      default: () => true
    },
    customClass: String
  },
  setup() {
    const fields = ref([
      {
        key: 'firstName',
        customLabel: 'Ваше имя',
        placeholder: 'Укажите ваше имя',
        type: 'text',
        value: null,
        disabled: false
      },
      {
        key: 'phone',
        customLabel: 'Номер телефона',
        placeholder: 'Укажите номер телефона',
        type: 'phone',
        value: null,
        disabled: false
      },
      {
        key: 'email',
        customLabel: 'Электронная почта',
        placeholder: 'Укажите электронную почту',
        type: 'text',
        value: null,
        required: true,
        disabled: false
      },
      {
        key: 'password',
        customLabel: 'Пароль',
        placeholder: 'Введите пароль',
        type: 'password',
        showPasswordButton: true,
        value: null,
        required: true,
        disabled: false
      },
      {
        key: 'repeatPassword',
        customLabel: 'Подтвердите пароль',
        placeholder: 'Введите пароль повторно',
        type: 'password',
        showPasswordButton: true,
        value: null,
        required: true,
        disabled: false
      },
      {
        key: 'agree',
        label: 'Согласен на обработку персональных данных',
        type: 'checkbox',
        value: null,
      },
    ])

    return {
      fields
    }
  },
}
</script>
