<template>
  <div
    class="content panel"
    :class="{
      'background-unset': selectedItemType === 'support',
      'h-100': ['tours', 'favorites', 'notifications'].includes(selectedItemType),
    }"
  >
    <personal-account-page-content-profile
      v-if="selectedItemType === 'profile'"
    ></personal-account-page-content-profile>

    <personal-account-page-content-tour
      v-if="selectedItemType === 'tours'"
    ></personal-account-page-content-tour>

    <personal-account-page-content-favorite
      v-if="selectedItemType === 'favorites'"
    ></personal-account-page-content-favorite>

    <personal-account-page-content-notification
      v-if="selectedItemType === 'notifications'"
    ></personal-account-page-content-notification>

    <personal-account-page-content-support
      v-if="selectedItemType === 'support'"
    ></personal-account-page-content-support>
  </div>
</template>

<script>

import PersonalAccountPageContentProfile from '@/pages/personal-account-page/PersonalAccountPageContentProfile.vue'
import PersonalAccountPageContentTour from '@/pages/personal-account-page/PersonalAccountPageContentTour.vue'
import PersonalAccountPageContentFavorite from '@/pages/personal-account-page/PersonalAccountPageContentFavorite.vue'
import PersonalAccountPageContentNotification
  from '@/pages/personal-account-page/PersonalAccountPageContentNotification.vue'
import PersonalAccountPageContentSupport from '@/pages/personal-account-page/PersonalAccountPageContentSupport.vue'

export default {
  name: 'PersonalAccountPageContent',
  components: {
    PersonalAccountPageContentSupport, PersonalAccountPageContentNotification, PersonalAccountPageContentFavorite,
    PersonalAccountPageContentProfile, PersonalAccountPageContentTour
  },
  props: {
    selectedItemType: String
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.content {
  padding: 30px;
  width: 100%;

  &:not(.h-100) {
    height: 316px;
  }

  @media (max-width: 1679px) {
    width: calc(100% - 60px);

    &.background-unset {
      padding-top: 20px;
      width: 100%;

      .contacts-wrapper {
        padding: 24px 20px;
        height: 52px;

        .contact {
          .text-wrapper {
            .label {
              line-height: 22.4px;
            }

            .text {
              font-size: 16px;
              line-height: 25.6px;
            }
          }
        }
      }
    }

    form {
      .field-group {
        .field-wrapper {
          &:not(:last-child) {
            margin-right: 30px;
          }
        }
      }
    }

    .buttons-wrapper {
      button {
        width: 100%;
        padding: 0;

        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px 20px;

    &:not(.h-100) {
      height: 570px;
    }

    &.background-unset {
      width: 100%;
      padding: 0 20px 20px 0;

      .panel-title {
        margin-bottom: 20px;
      }

      .contacts-wrapper {
        flex-direction: column;
        justify-content: unset;
        padding: 20px;
        height: 202px;

        .contact {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          .text-wrapper {
            .label {
              line-height: 22.4px;
            }

            .text {
              font-size: 16px;
              line-height: 25.6px;
            }
          }
        }
      }
    }

    form {
      .field-group {
        flex-direction: column;
        height: unset;
        margin-bottom: 0;

        .field-wrapper {
          width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
    }

    .buttons-wrapper {
      flex-direction: column;
      margin-top: 0;

      button {
        width: 100%;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    .notification-wrapper {
      .notification {
        .info {
          .notification-title {
            margin-top: 13px;
          }

          .date {
            margin-bottom: 12px;
          }

          .text {
            margin-left: -80px;
            margin-top: 15px;
            margin-bottom: 20px;
          }
        }
      }

      hr {
        margin: 0 0 20px 0;
      }
    }
  }
}

</style>
