<template>
  <div
      class="burger cursor-pointer"
      @click.stop.prevent="handleClick"
      :class="{
      'active': show,
      'small-header-active': smallHeader
    }"
  ></div>
  <div
      v-if="show"
      class="burger-menu-wrapper"
      :class="{
      'small-header-active': smallHeader
    }"
      v-click-outside="handleClickOutside"
  >
    <div class="burger-find-tour-help-button toolbar-item flex-row-center-center">
      <span
          class="cursor-pointer"
          @click="onClickFindTourWithSpecialist"
      >Подбор тура со специалистом</span>
    </div>
    <div class="burger-menu flex-column-end">
      <div
          v-for="item in menuItems"
          :key="item.type"
          class="burger-menu--item flex-row-center-end w-100"
          :class="[item.type]"
      >
        <div
            v-if="['hot-tours'].includes(item.type)"
            class="burger-menu--item--image"
            :class="[item.type]"
        ></div>
        <div
            class="burger-menu--item--text cursor-pointer"
            @click="handleItemClick(item.type)"
        >{{ item.text }}
        </div>
      </div>
    </div>
    <socials-component
        orange-icons
        class="burger-socials flex-row-center-end"
    ></socials-component>
    <div class="burger-additional-info flex-column-end d-none">
      <div
          v-for="(item, index) in additionalInfoItems"
          :key="index"
          class="burger-additional-info-item cursor-pointer"
          @click="handFooterDocClick(item.type)"
      >{{ item.text }}
      </div>
    </div>
    <div
        class="burger-search-button cursor-pointer d-none"
        @click="handleClickSearch"
    >
      <span>Поиск тура</span>
    </div>
  </div>
</template>

<script>


import {ref, watch} from 'vue'
import SocialsComponent from '@/components/SocialsComponent.vue'
import {
  goToMainPage, goToSearchHotelPage, goToSearchHotTourPage, goToSearchTourPage
} from '@/composables/router'
import {getTelegram} from '@/composables/contacts'
import vClickOutside from 'click-outside-vue3'
import {openDocument} from '@/composables/docs'

export default {
  name: 'HeaderBurgerComponent',
  components: {
    SocialsComponent
  },
  props: {
    burgerOpened: Boolean,
    smallHeader: Boolean
  },
  emits: ['open', 'close'],
  setup(props, {emit}) {
    const show = ref(false)
    const menuItems = ref([
      {text: 'Горящие туры', type: 'hot-tours'},
      {text: 'Поиск туров', type: 'search-tours'},
      // {text: 'Поиск отелей', type: 'search-hotels'},
      {text: 'О нас', type: 'about-us'},
    ])
    const additionalInfoItems = ref([
      {text: 'Пользовательское соглашение', type: 'user-agreement'},
      {text: 'Политика конфиденциальности', type: 'privacy-policy'},
      {text: 'Правовая информация', type: 'legal-information'},
    ])

    const handleClick = () => {
      show.value = !show.value
      emit(show.value ? 'open' : 'close')
    }
    const handleItemClick = type => {
      hideBurger()
      if (type === 'about-us') {
        goToMainPage('project-info')
      } else if (type === 'search-tours') {
        goToSearchTourPage()
      } else if (type === 'search-hotels') {
        goToSearchHotelPage()
      } else if (type === 'hot-tours') {
        goToSearchHotTourPage()
      }
    }
    const hideBurger = () => {
      show.value = false
      emit('close')
    }
    const handFooterDocClick = type => openDocument(type)
    const onClickFindTourWithSpecialist = () => window.open(getTelegram(), '_blank')
    const handleClickSearch = () => {
      hideBurger()
      goToSearchTourPage()
    }
    const handleClickOutside = event => {
      if (!event.target.classList.contains('burger')) {
        hideBurger()
      }
    }

    watch(() => props.burgerOpened, () => show.value = props.burgerOpened)

    return {
      show,
      menuItems,
      additionalInfoItems,

      handleClick,
      handleItemClick,
      handFooterDocClick,
      onClickFindTourWithSpecialist,
      hideBurger,
      handleClickSearch,
      handleClickOutside
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.burger {
  display: block;
  background-image: url('@/assets/images/common/icon-burger.svg');
  width: 24px;
  height: 16px;
  margin-left: 50px;

  &.small-header-active {
    background-image: url('@/assets/images/common/icon-burger-orange.svg');

    &.active {
      background-image: url('@/assets/images/common/close-orange.svg');
    }
  }

  &.active {
    width: 17px;
    height: 17px;
    margin-left: 57px;
    background-image: url('@/assets/images/common/close-white.svg');
    background-size: cover;
  }

  @media (max-width: 1679px) {
    margin-left: 25px;

    &.active {
      margin-left: 32px;
    }
  }

  @media (max-width: 767px) {
    &.active {
      background-image: url('@/assets/images/common/close-black.svg');
    }

    &.small-header-active {
      background-image: url('@/assets/images/common/icon-burger-black.svg');

      &.active {
        background-image: url('@/assets/images/common/close-black.svg');
      }
    }
  }
}

.burger-menu-wrapper {
  user-select: none;
  position: absolute !important;
  height: max-content;
  background: $base-gray;
  z-index: 3;
  right: 0;
  width: 257px;
  max-width: 257px;
  top: 69px;
  padding: 30px 30px 37px;
  box-shadow: 0 0 20px 0 #0000001A;
  border-radius: 10px;

  .burger-menu {
    margin: 0 0 30px;

    &--item {
      &--image {
        width: 24px;
        height: 24px;
        margin-right: 5px;

        &.hot-tours {
          background-image: url('@/assets/images/common/flame.svg');
        }
      }

      &--text {
        font-family: GilroyRegular;
        font-size: 16px;
        line-height: 19.2px;
        color: $base-black;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }

      &.hot-tours {
        margin-left: -13px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .burger-find-tour-help-button {
    margin-bottom: 40px;

    span {
      width: 100%;
      padding: 18px 15px 19px 17px;
      border-radius: 6px;
      font-family: GilroySemibold;
      font-size: 13px;
      line-height: 13px;
      color: $base-white;
      border: unset;
      background: #FF8500;
    }
  }

  &.small-header-active {
    width: 287px;
    max-width: 287px;
    padding-bottom: 85px;
    height: auto;
  }

  @media screen and (max-width: 1679px) {
    &.small-header-active {
      top: 58px;
    }
  }

  @media screen and (max-width: 767px) {
    left: -130px;
    right: unset;
    width: calc(100% + 120px) !important;
    max-width: unset !important;
    height: 673px;
    box-shadow: unset;
    top: 50px;
    padding: 40px 10px 10px 10px;

    &.small-header-active {
      top: 50px;
      padding-bottom: 10px;
    }

    .burger-find-tour-help-button {
      left: 10px;
      width: calc(100% - 20px);
      margin-bottom: 0;

      span {
        width: 240px;
      }
    }

    .burger-menu {
      align-items: center;
      margin-top: 18px;

      &--item {
        justify-content: center;

        &:not(:first-child) {
          margin-top: 18px;
        }
      }
    }

    .burger-socials {
      margin-top: 105px;
      justify-content: center;
    }

    .burger-additional-info {
      margin-top: 60px;
      display: block;
      align-items: center;

      .burger-additional-info-item {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 17px;
        color: #888888;

        &:not(:first-child) {
          margin-top: 15px;
        }

        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }

    .burger-search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(225deg, #FF8500 0%, #FF4800 100%);
      border-radius: 6px;
      padding: 23px 0;
      margin-top: 124px;

      span {
        font-family: GilroySemibold;
        font-size: 14px;
        line-height: 14px;
        color: $base-white;
      }
    }
  }
}

</style>
