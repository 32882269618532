import { defineStore } from 'pinia'

const moduleName = 'notification'

export const notificationStore = defineStore(moduleName, {
  state: () => ({}),
  getters: {},
  actions: {
    getNotifications (page = 1, perPage = 3) {
      return this.$http.get(`${moduleName}/push/notifications?page=${page}&perPage=${perPage}`)
    },
  },
})
