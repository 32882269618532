<template>
  <feedbacks-component :feedbacks="feedbacks"></feedbacks-component>
</template>

<script>


import {onMounted, ref, watch} from 'vue'
import FeedbacksComponent from '@/components/forms/FeedbacksComponent.vue'

export default {
  name: 'HotelFeedbacksTabComponent',
  components: {
    FeedbacksComponent
  },
  props: {
    hotel: Object
  },
  setup(props) {
    const feedbacks = ref([])

    const loadFeedbacks = () => {
      feedbacks.value = props.hotel && props.hotel.reviews && props.hotel.reviews.review
        ? props.hotel.reviews.review
        : []
    }

    onMounted(() => loadFeedbacks())

    watch(() => props.hotel, () => loadFeedbacks())

    return {
      feedbacks
    }
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.feedbacks {
  @media screen and (max-width: 767px) {
    margin: 0 15px 15px 20px
  }
}

</style>
