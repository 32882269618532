<template>
  <teleport to="#app-overlay">
    <div
      class="share-modal"
      @click.stop.prevent="$emit('close')"
    >
      <div
        class="share-modal--wrapper"
        @click.stop.prevent
      >
        <div class="share-modal--wrapper--header flex-row-center-end">
          <img
            :src="require('@/assets/images/common/close-black.svg')"
            alt="close"
            class="close cursor-pointer"
            @click.stop.prevent="$emit('close')"
          />
        </div>
        <div class="share-modal--wrapper--body flex-column-start-center">
          <div class="title text-left">Поделиться в один клик</div>
          <div
            class="share-item flex-row-center cursor-pointer"
            @click.stop.prevent="handleShareClick"
          >
            <div
              v-if="showNotice"
              class="notice"
            >Скопировано!</div>
            <circle-icon
              size="38px"
              icon-size="24px"
              background-color="#666666"
              :src="require('@/assets/images/common/share-white.svg')"
            ></circle-icon>
            <span>Ссылка и описание</span>
          </div>
<!--          <div class="title text-left two">Поделиться в один клик</div>-->
<!--          <div-->
<!--            v-for="(item, index) in shareSocialItems"-->
<!--            :key="index"-->
<!--            class="share-item flex-row-center"-->
<!--          >-->
<!--            <circle-icon-->
<!--              size="38px"-->
<!--              icon-size="24px"-->
<!--              :background-color="item.color"-->
<!--              :src="item.icon"-->
<!--            ></circle-icon>-->
<!--            <span>{{ item.text }}</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>


import { ref } from 'vue'
import CircleIcon from '@/components/icon/CircleIcon.vue'
import { copyToBuffer } from '@/composables/copy'
import { encodeShareLinkData } from '@/composables/share'

export default {
  name: 'ShareModal',
  components: {CircleIcon},
  emits: ['close'],
  props: {
    logo: String,
    tourId: String,
    price: Number,
    currency: String,
    entityPaymentCategory: String,
  },
  setup(props) {
    const showNotice = ref(false)
    const shareSocialItems = ref([
      // {text: 'Telegram', icon: require('@/assets/images/common/telegram-white.svg'), color: '#44B4E3'},
      // {text: 'WhatsApp', icon: require('@/assets/images/common/whatsapp-white.svg'), color: '#429752'},
      // {text: 'Viber', icon: require('@/assets/images/common/viber-white.svg'), color: '#8A269C'},
      // {text: 'Вконтакте', icon: require('@/assets/images/common/vk-white.svg'), color: '#4480AC'},
    ])

    const handleShareClick = async () => {
      const json = {
        logo: props.logo,
        tourId: props.tourId,
        entityPaymentCategory: props.entityPaymentCategory
      }
      const result = await copyToBuffer(
        `${[window.location.origin, `share?hash=${encodeShareLinkData(json)}`].join('/')}`
      )
      if (result) {
        showNotice.value = true
        setTimeout(() => showNotice.value = false, 700)
      }
    }

    return {
      shareSocialItems,
      showNotice,

      handleShareClick
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.share-modal {
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000080;

  &--wrapper {
    margin: 120px 24px;
    padding: 20px;
    top: calc(50% - 120px);
    left: calc(50% - 24px);
    transform: translate(-50%,-50%);
    background: white;
    max-width: 320px;
    border-radius: 16px;

    &--header {
      margin-bottom: 14px;

      .close {
        width: 16px;
        height: 16px;
      }
    }

    &--body {
      .title {
        font-family: GilroyBold;
        font-size: 16px;
        line-height: 20px;
        color: $base-black;
        margin-bottom: 20px;

        &.two {
          margin-top: 15px;
        }
      }

      .share-item {
        width: 100%;
        text-align: left;
        padding: 3px 0 3px 3px;
        border-radius: 50px;
        background: $base-beige;

        span {
          margin-left: 10px;
          font-family: GilroyMedium;
          font-size: 14px;
          line-height: 17px;
          color: #666666;

          &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .notice {
          cursor: default;
          top: -44px;
          right: 0;
          padding: 8px;
          position: absolute !important;
          background: #666666;
          color: $base-white;
          border-radius: 50px;
          font-family: GilroyMedium;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}

</style>
