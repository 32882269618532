<template>
  <div class="choose-block">
    <h4>Бюджет</h4>

    <div class="field-wrapper">
      <label>Минимальный бюджет ({{ item.currency }})</label>
      <input
          type="text"
          v-model="item.from"
      />
    </div>

    <div class="field-wrapper">
      <label>Максимальный бюджет ({{ item.currency }})</label>
      <input
          type="text"
          v-model="item.to"
      />
    </div>

    <!--    <div class="field-wrapper">
          <label>Валюта</label>

          <div
            v-for="(currency, key) in currencies"
            :key="key"
            class="field-wrapper"
          >
            <input
              type="radio"
              v-model="item.currency"
              :id="`currency-${key}`"
              :value="key"
            >
            <label
              :for="`currency-${key}`"
              v-html="currency"
            ></label>
          </div>
        </div>-->

    <div
        class="choose-block--save"
        :class="{ 'active': isChanged }"
        @click="handleClickSave"
    >Применить
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue'

export default {
  name: 'MainPageSearchBlockBudget',
  props: {
    budget: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const item = ref(props.budget)
    const currencies = {
      RUB: '<span>RUB</span>&nbsp;– российские рубли',
      // USD: '<span>USD</span>&nbsp;– доллар',
      // EUR: '<span>EUR</span>&nbsp;– евро',
      // BYN: '<span>BYN</span>&nbsp;– белорусские рубли',
      // TNG: '<span>TNG</span>&nbsp;– тенге',
      // UAH: '<span>UAH</span>&nbsp;– гривны',
    }
    const isChanged = ref(false)

    const handleClickSave = () => emit('change-budget', item.value)

    watch(item, () => isChanged.value = true, {deep: true})

    return {
      item,
      currencies,
      isChanged,

      handleClickSave,
    }
  },
}
</script>

<style scoped lang="scss">
input[type="radio"] {
  display: none;

  + label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      margin-right: 10px;
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #e0e0e0;
      border-radius: 50%;
      box-sizing: border-box;
    }

    :deep(span) {
      font-family: GilroyBold;
    }
  }

  &:checked {
    + label {
      &:after {
        content: '';
        position: absolute !important;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        background: url('@/assets/images/common/circle-checked.svg');
      }
    }
  }
}

.choose-block {
  position: absolute !important;
  top: 90px;
  left: 0;
  padding: 20px;
  width: 300px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  @media screen and (max-width: 767px) {
    width: 270px;
  }

  h4 {
    margin: 10px 0 20px;
    text-align: left;
    font-family: GilroyBold;
    font-size: 16px;
    font-weight: normal;
    color: #222222;
    line-height: 1;
    text-transform: uppercase;
  }

  .field-wrapper {
    text-align: left;
    position: relative;

    + .field-wrapper {
      margin-top: 30px;
    }

    label {
      font-family: GilroyMedium;
      font-size: 14px;
      color: #888888;
      line-height: 1;
    }

    input[type='text'] {
      margin-top: 10px;
      padding: 10px;
      width: 100%;
      font-family: GilroyRegular;
      font-size: 14px;
      color: #222222;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
    }

    .field-wrapper {
      margin-top: 15px;

      label {
        color: #222222;
      }
    }
  }

  &--save {
    margin-top: 20px;
    padding: 12px 0;
    text-align: center;
    font-family: GilroyRegular;
    font-size: 14px;
    color: #222222;
    line-height: 1;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 40px;
    cursor: pointer;

    &.active {
      color: #ffffff;
      background: linear-gradient(225deg, #ff8500 0%, #ff4800 100%);
      border-color: transparent;
    }
  }
}
</style>
