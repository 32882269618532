<template>
  <div class="socials">
    <div
      v-for="social in socials"
      :key="social.type"
      class="socials--item cursor-pointer"
      :class="[social.type, {'orange': orangeIcons}]"
      @click="onClick(social.type)"
    ></div>
  </div>
</template>

<script>


import { ref } from 'vue'
import { getTelegram } from '@/composables/contacts'

export default {
  name: 'SocialsComponent',
  props: {
    orangeIcons: Boolean
  },
  setup() {
    const linkTarget = 'tourvkaif'
    const socials = ref([
      {type: 'tg'},
      {type: 'vk'},
      {type: 'odn'},
      {type: 'inst'},
      {type: 'ytb'},
    ])

    const onClick = type => {
      if (type === 'tg') {
        window.open(getTelegram(), '_blank')
      } else if (type === 'vk') {
        window.open(`https://vk.com/${linkTarget}`, '_blank')
      } else if (type === 'inst') {
        window.open(
          `https://instagram.com/${linkTarget}?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr`, '_blank'
        )
      }
    }

    return {
      socials,

      onClick
    }
  },
}
</script>

<style lang='scss' scoped>

.socials {
  &--item {
    width: 40px;
    height: 40px;

    &.tg {
      background-image: url('@/assets/images/common/tg-circle-gray.svg');

      &.orange, &:hover {
        background-image: url('@/assets/images/common/tg-circle-orange.svg');
      }
    }

    &.vk {
      background-image: url('@/assets/images/common/vk-circle-gray.svg');

      &.orange, &:hover {
        background-image: url('@/assets/images/common/vk-circle-orange.svg');
      }
    }

    &.odn {
      background-image: url('@/assets/images/common/odn-circle-gray.svg');

      &.orange, &:hover {
        background-image: url('@/assets/images/common/odn-circle-orange.svg');
      }
    }

    &.inst {
      background-image: url('@/assets/images/common/inst-circle-gray.svg');

      &.orange, &:hover {
        background-image: url('@/assets/images/common/inst-circle-orange.svg');
      }
    }

    &.ytb {
      background-image: url('@/assets/images/common/ytb-circle-gray.svg');

      &.orange, &:hover {
        background-image: url('@/assets/images/common/ytb-circle-orange.svg');
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

</style>
