<template>
  <div class="photo-album">
    <template
      v-for="(photoUrl, index) in photos"
      :key="index"
    >
      <img
        v-if="index < minPhotoCount"
        :src="photoUrl"
        alt="image"
        class="cursor-pointer"
        :class="{
          'active': index === activePhotoIndex
        }"
        @click="onPhotoClick(index)"
      />
    </template>
  </div>
  <div
    v-if="minPhotoCount < photosLength"
    class="more-photos w-100 cursor-pointer"
    @click="minPhotoCount = minPhotoCount * 2"
  >Показать больше фото</div>
</template>

<script>

import { computed, ref, watch } from 'vue'

export default {
  name: 'TourDetailedCardBodyTabHotelPhotoAlbum',
  props: {
    photos: Array,
    currentPhotoIndex: Number,
  },
  emits: ['image-clicked'],
  setup(props, { emit }) {
    const minPhotoCount = ref(12)
    const photosLength = computed(() => props.photos.length)
    const activePhotoIndex = ref(0)

    const onPhotoClick = index => {
      activePhotoIndex.value = index
      emit('image-clicked', index)
    }

    watch(() => props.currentPhotoIndex, () => activePhotoIndex.value = props.currentPhotoIndex)

    return {
      minPhotoCount,
      photosLength,
      activePhotoIndex,

      onPhotoClick
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tab-content.hotel {
  .panel {
    .photo-album {
      margin: 60px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 10px;
      column-gap: 10px;
      width: 335px;

      img {
        border-radius: 6px;
        min-width: 105px;
        max-width: 105px;
        min-height: 70px;
        max-height: 70px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &.active {
          min-width: 101px;
          max-width: 101px;
          min-height: 66px;
          max-height: 66px;
          border: 2px solid $base-orange;
        }
      }
    }

    .more-photos {
      background: #F5F5F5;
      border-radius: 50px;
      padding: 15px 0 14px 0;
      font-family: GilroySemibold;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
    }

    @media (max-width: 1679px) {
      .photo-album, .more-photos {
        display: none;
      }
    }
  }
}

</style>
