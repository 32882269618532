<template>
  <div
    class="item flex-row-center"
    :class="{
      'active': active,
      'exit': type === 'logout',
      'hovered': active ? false : hovered
    }"
    @mouseover="hovered = !['profile', 'logout'].includes(type)"
    @mouseout="hovered = false"
  >
    <span>{{ label }}</span>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'PersonalAccountPageMenuItem',
  props: {
    type: String,
    label: String,
    active: Boolean
  },
  setup() {
    const hovered = ref(false)

    return {
      hovered
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.item {
  padding-left: 40px;
  height: 60px;

  span {
    display: block;
    font-family: GilroyMedium;
    font-size: 16px;
    line-height: 16px;
    color: #888888;
  }

  &:not(.active) {
    cursor: pointer;
  }

  &.active {
    text-align: left;
    width: calc(100% - 60px);
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    background-color: $base-white;
    border-radius: 16px;

    span {
      color: $black-text-color;
      font-family: GilroyMedium;
    }
  }

  &.hovered {
    span {
      color: $black-text-color;
    }
  }

  &.exit {
    span {
      color: #FF8500;
    }
  }

  @media (max-width: 1679px) {
    height: 58px;
    margin: 0;
    padding: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border-radius: 6px;
      width: calc(100% - 15px);
      margin-left: 0;
      padding-left: 0;
      height: 60px;
      background: #F5F5F5;
    }

    &:not(.active) {
      border: 1px solid #EAEAEA;
    }

    &:nth-child(1), &:nth-child(3), &:nth-child(5) {
      margin-right: 15px;
    }

    &:nth-child(2), &:nth-child(4), &:nth-child(6) {
      margin-left: 15px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 58px;
    margin: 0;
    padding: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      width: 100%;
      margin-right: 0;
      height: 60px;
      background: #F5F5F5;
    }

    &:not(.active) {
      border: 1px solid #EAEAEA;
    }

    &:nth-child(1), &:nth-child(3), &:nth-child(5) {
      margin-right: 0;
    }

    &:nth-child(2), &:nth-child(4), &:nth-child(6) {
      margin-left: 0;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

</style>
