<template>
  <div
    class="circle flex-row-center-center cursor-pointer"
    :style="{width: size, height: size, minWidth: size, background: backgroundColor}"
    :class="[backgroundVariant]"
  >
    <icon-dots v-if="isDots"></icon-dots>
    <img
      v-if="src"
      :src="src"
      alt="circle-icon"
      class="image"
      :class="{
        'rotate-plus-180': rotatePlus180,
        'rotate-minus-90': rotateMinus90,
        'rotate-plus-90': rotatePlus90,
      }"
      :style="{
        width: iconSize, height: iconSize
      }"
    />
  </div>
</template>

<script>

import IconDots from '@/components/icon/IconDots.vue'

export default {
  name: 'CircleIcon',
  components: {
    IconDots
  },
  props: {
    src: String,
    size: {
      type: String,
      default: () => '50px',
      required: false,
    },
    backgroundVariant: {
      type: String,
      default: () => 'gray',
      required: false,
    },
    backgroundColor: String,
    iconSize: {
      type: String,
      default: () => '16px',
      required: false,
    },
    isDots: Boolean,
    rotatePlus180: Boolean,
    rotateMinus90: Boolean,
    rotatePlus90: Boolean,
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.circle {
  background: $base-white;
  border-radius: 25px;
  &.gray {
    background: $base-gray;
  }
  &.beige {
    background: $base-beige;
  }
  &.white {
    background: $base-white;
  }
  .image {
    &.rotate-plus-180 {
      transform: rotate(180deg);
    }
    &.rotate-minus-90 {
      transform: rotate(-90deg);
    }
    &.rotate-plus-90 {
      transform: rotate(90deg);
    }
  }
}

</style>
