<template>
  <div class="search-header-block-top flex-row">
    <div
        class="top-wrapper flex-row-start w-100"
        :class="{
        'located-on-main-page': locatedOnMainPage
      }"
    >
      <div
          v-if="['hot-tours', 'tours'].includes(searchCategory)"
          class="field-wrapper departure-cities-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('departureCities')"
      >
        <label class="text-left">Город вылета</label>
        <button>
          {{ departureCities.has(fields.departureCity) ? departureCities.get(fields.departureCity).name : '...' }}
        </button>

        <main-page-search-block-countries-cities
            v-show="visibleBlocks.departureCities"
            :items="departureCities"
            categories
            @change-item="handleChangeDepartureCity"
            @close="visibleBlocks.departureCities = false"
            @click.stop.prevent
            class="first"
            v-click-outside-block="handleClickOutside"
        ></main-page-search-block-countries-cities>
      </div>
      <div
          v-if="['hot-tours', 'tours'].includes(searchCategory)"
          class="field-wrapper countries-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('countries')"
      >
        <label>Страна</label>
        <button>{{ countries.get(fields.country) ?? '...' }}</button>

        <main-page-search-block-countries-cities
            v-show="visibleBlocks.countries"
            :items="countries"
            @change-item="handleChangeCountry"
            @close="visibleBlocks.countries = false"
            @click.stop.prevent
        ></main-page-search-block-countries-cities>
      </div>
      <div
          v-if="searchCategory === 'hotels'"
          class="field-wrapper countries-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('countries')"
      >
        <label>Страна</label>
        <button>{{ hotelCountries.get(fields.country) ?? '...' }}</button>

        <main-page-search-block-countries-cities
            v-show="visibleBlocks.countries"
            :items="hotelCountries"
            @change-item="handleChangeAllCountries"
            @close="visibleBlocks.countries = false"
            @click.stop.prevent
        ></main-page-search-block-countries-cities>
      </div>
      <div
          v-if="searchCategory === 'hotels'"
          class="field-wrapper direction-wrapper"
      >
        <label>Направление</label>
        <input
            type="text"
            v-model="fields.directionFilter"
            placeholder="..."
            class="direction-input"
            @keyup="visibleBlocks.direction = !!fields.directionFilter"
            v-click-outside-block="handleClickOutside"
        />

        <main-page-search-block-direction
            v-show="visibleBlocks.direction"
            :direction="fields.directionFilter"
            :country-id="currentCountryId"
            @select="handleDirectionSelection"
        ></main-page-search-block-direction>
      </div>

      <div
          v-if="['hot-tours', 'tours'].includes(searchCategory)"
          class="field-wrapper resort-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('resort')"
      >
        <label class="text-left">Курорт</label>
        <button class="text-left">
          {{ fields.resort && fields.resort.length ? fields.resort.map(r => r.name).join(', ') : '...' }}
        </button>

        <main-page-search-block-list
            v-if="resorts.length && visibleBlocks.resort"
            id="resort"
            :model="fields.resort"
            title="Курорт"
            :options="resorts"
            option-value="name"
            list
            multiple
            @click.stop.prevent
            class="search-block"
            :use-confirm-button="false"
            @save="handleChangeResort"
        ></main-page-search-block-list>
      </div>

      <div
          class="field-wrapper fly-dates-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('flyDates')"
      >
        <label>{{ searchCategory === 'hotels' ? 'Дата проживания' : 'Дата вылета' }}</label>
        <button class="text-left">
          {{ fields.flyDate?.from?.format?.('D MMM') ?? '...' }} - {{ fields.flyDate?.to?.format?.('D MMM') ?? '...' }}
        </button>

        <main-page-search-block-calendar
            v-if="visibleBlocks.flyDates && fields.flyDate?.from && fields.flyDate?.to"
            :from="fields.flyDate.from"
            :to="fields.flyDate.to"
            :available-dates="flyDates"
            @change-date="handleChangeFlyDates"
            @click.stop.prevent
        ></main-page-search-block-calendar>
      </div>
      <div
          v-if="searchCategory === 'tours'"
          class="field-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('nights')"
      >
        <label>Ночей</label>
        <button>{{ fields.nights?.from }} - {{ fields.nights?.to }}</button>

        <main-page-search-block-nights
            v-if="visibleBlocks.nights"
            :from="fields.nights.from"
            :to="fields.nights.to"
            @change-count="handleChangeNights"
            @click.stop.prevent
        ></main-page-search-block-nights>
      </div>
      <div
          v-if="['hotels', 'tours'].includes(searchCategory)"
          class="field-wrapper passengers-wrapper"
          :class="{
          'field-dropdown': locatedOnMainPage
        }"
          @click="handleClickBlockView('passengers')"
      >
        <template v-if="searchCategory === 'hotels'">
          <span class="text-left">Туристы</span>
          <button>{{ plural.noun(adultsCount, '%d взрослый', '%d взрослых', '%d взрослых') }}</button>
        </template>
        <template v-else>
          <button class="text-left">
            {{ plural.noun(countPassengers(), '%d пассажир', '%d пассажира', '%d пассажиров') }}
          </button>
          <span>Эконом</span>
        </template>

        <main-page-search-block-passengers
            v-if="visibleBlocks.passengers"
            :passengers="fields.passengers"
            @change-passengers="handleChangePassengers"
            @click.stop.prevent
        ></main-page-search-block-passengers>
      </div>
    </div>
    <button
        v-if="showSearchButton"
        class="search-button cursor-pointer"
        @click="$emit('search')"
    >{{ searchButtonText }}
    </button>
  </div>
</template>

<script>
import MainPageSearchBlockCountriesCities from '@/pages/main-page/MainPageSearchBlockCountriesCities.vue'
import MainPageSearchBlockCalendar from '@/pages/main-page/MainPageSearchBlockCalendar.vue'
import MainPageSearchBlockPassengers from '@/pages/main-page/MainPageSearchBlockPassengers.vue'
import MainPageSearchBlockNights from '@/pages/main-page/MainPageSearchBlockNights.vue'
import moment from 'moment/moment'
import {computed, onMounted, ref, watch} from 'vue'
import {dictionariesStore} from '@/stores/dictionariesStore'
import * as plural from 'plural-ru'
import MainPageSearchBlockDirection from '@/pages/main-page/MainPageSearchBlockDirection.vue'
import {tourStore} from '@/stores/tourStore'
import MainPageSearchBlockList from '@/pages/main-page/MainPageSearchBlockList.vue'
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'SearchFormHeaderTopComponent',
  emits: ['change-city', 'filters-changed', 'search'],
  props: {
    searchCategory: String,
    flyDatesTitle: String,
    showSearchButton: Boolean,
    searchButtonText: String,
    locatedOnMainPage: Boolean,
  },
  components: {
    MainPageSearchBlockList,
    MainPageSearchBlockDirection,
    MainPageSearchBlockNights,
    MainPageSearchBlockPassengers,
    MainPageSearchBlockCalendar,
    MainPageSearchBlockCountriesCities
  },
  setup(props, {emit}) {
    const dictionariesStoreInstance = dictionariesStore()

    const departureCities = ref(new Map())
    const countries = ref(new Map())
    const hotelCountries = ref(new Map())
    const flyDates = ref([])

    const departureCityDefault = 1
    const countryNameByDefault = 'Турция'

    const visibleBlocks = ref({
      departureCities: false,
      countries: false,
      flyDates: false,
      nights: false,
      passengers: false,
      direction: false,
      resort: false,
    })
    const defaultValues = {
      toursWithFlights: true,
      hotTours: false,
      departureCity: departureCityDefault,
      country: null,
      flyDate: {
        from: null,
        to: null,
      },
      nights: {
        from: 6,
        to: 14,
      },
      passengers: {
        0: 2,
      },
      meal: null,
      rating: null,
      onlyCharter: false,
      hotelPlacesGuarantee: false,
      directionFilter: null,
      direction: null,
      resort: null,
    }
    const storedFilters = props.searchCategory === 'tours'
        ? JSON.parse(localStorage.getItem('userTourFilters')) ?? {}
        : {};

    const fields = ref({...defaultValues, ...storedFilters})
    const resorts = ref([])
    const adultsCount = computed(
        () => fields.value.passengers ? fields.value.passengers[0] : defaultValues.passengers[0]
    )
    const tourStoreInstance = tourStore()
    const currentSearchFilters = computed(() => tourStoreInstance?.currentSearchFilters)
    const clearFiltersIsActive = computed(() => tourStoreInstance.clearFiltersIsActive)
    const selectedCountryIdForSearch = computed(() => tourStoreInstance.selectedCountryIdForSearch)
    const currentCountryId = ref(null)

    async function getFlyDates() {
      if (currentSearchFilters.value) {
        flyDates.value = []
        await dictionariesStoreInstance.getFlydate(fields.value.country, fields.value.departureCity)
            .then(({data}) => {
              data.forEach(date => {
                const momentDate = moment(date, 'DD.MM.YYYY')
                flyDates.value.push(momentDate)
              })
            })
      } else {
        flyDates.value = []
        if (!fields.value.flyDate || (!fields.value.flyDate.from && !fields.value.flyDate.to)) {
          fields.value.flyDate.from = null
          fields.value.flyDate.to = null
        }

        const today = moment();

        await dictionariesStoreInstance.getFlydate(fields.value.country, fields.value.departureCity)
            .then(({data}) => {
              data.forEach(date => {
                const momentDate = moment(date, 'DD.MM.YYYY')

                if (momentDate.isSame(today, 'day')) return

                flyDates.value.push(momentDate)
                if (!fields.value.flyDate.from) {
                  fields.value.flyDate.from = momentDate.clone()
                  fields.value.flyDate.to = momentDate.clone().add(7, 'days')
                  emitFiltersChanged()
                }
              })
            })
      }
    }

    const getResorts = async () => await dictionariesStoreInstance.getResortsByCountry(currentCountryId.value)
        .then(({data}) => resorts.value = data)
    const getCountries = async cityId => {
      countries.value.clear()
      fields.value.country = currentCountryId.value ?? fields.value.country
      currentCountryId.value = currentCountryId.value ?? fields.value.country
      await dictionariesStoreInstance.getCountries(cityId)
          .then(({data}) => {
            if (!currentCountryId.value) {
              let result = null
              if (selectedCountryIdForSearch.value) {
                result = data.find(c => c.id.toString() === selectedCountryIdForSearch.value.toString())
                tourStoreInstance.setSelectedCountryIdForSearch(null)
              } else {
                result = data.find(c => c.name === countryNameByDefault)
              }
              if (result) {
                currentCountryId.value = result.id
                fields.value.country = result.id
              }
            }
            data.forEach(item => countries.value.set(item.id, item.name))
          })
    }
    const handleClickBlockView = blockName => {
      if (blockName === 'flyDates') {
        if (!fields.value.flyDate.from || !fields.value.flyDate.to) {
          return
        }
      }
      Object.keys(visibleBlocks.value).map(
          key => visibleBlocks.value[key] = key === blockName ? !visibleBlocks.value[key] : false
      )
    }
    const handleChangeDepartureCity = cityId => {
      visibleBlocks.value.departureCities = false
      fields.value.departureCity = cityId
      getCountries(cityId)
      emit('change-city', cityId, departureCities.value.has(cityId) ? departureCities.value.get(cityId).nameFrom : '')
      emitFiltersChanged()
    }
    const handleChangeCountry = countryId => {
      visibleBlocks.value.countries = false
      fields.value.resort = null
      fields.value.country = countryId
      currentCountryId.value = countryId
      emitFiltersChanged()
    }
    const handleChangeAllCountries = item => {
      visibleBlocks.value.countries = false
      fields.value.country = item
      currentCountryId.value = item
      emitFiltersChanged()
    }
    const handleChangeFlyDates = (fromDate, toDate) => {
      visibleBlocks.value.flyDates = false
      fields.value.flyDate.from = fromDate.clone()
      fields.value.flyDate.to = toDate.clone()
      emitFiltersChanged()
    }
    const handleChangeNights = (fromNights, toNights) => {
      visibleBlocks.value.nights = false
      fields.value.nights.from = fromNights
      fields.value.nights.to = toNights
      emitFiltersChanged()
    }
    const handleChangePassengers = items => {
      visibleBlocks.value.passengers = false
      fields.value.passengers = items
      emitFiltersChanged()
    }
    const handleDirectionSelection = direction => {
      fields.value.directionFilter = direction ? direction.name : null
      fields.value.direction = direction
      visibleBlocks.value.direction = false
      getFlyDates()
      emitFiltersChanged()
    }
    const handleChangeResort = value => {
      fields.value.resort = value
      emitFiltersChanged()
    }
    const countPassengers = () => fields.value.passengers ? Object.values(fields.value.passengers)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0
    const emitFiltersChanged = () => emit('filters-changed', fields.value)
    const handleClickOutside = event => {
      if (!event.target.closest('.search-header-block-top')) {
        Object.keys(visibleBlocks.value).map(block => visibleBlocks.value[block] = false)
      }
    }
    const setDefaultFieldsValues = () => fields.value = JSON.parse(JSON.stringify(defaultValues))
    const getAllCountries = async () => {
      await dictionariesStoreInstance.getAllCountries()
          .then(() => {
            const result = dictionariesStoreInstance.allCountriesList.find(c => c.name === countryNameByDefault)
            if (!currentCountryId.value) {
              currentCountryId.value = result.id
              fields.value.country = result.id
            }
            dictionariesStoreInstance.allCountriesList.forEach(item => hotelCountries.value.set(item.id, item.name))
          })
    }

    onMounted(async () => {
      if (currentSearchFilters.value && currentSearchFilters.value?.topBlockFilters) {
        fields.value = {...currentSearchFilters.value.topBlockFilters}
        if (['tours', 'hot-tours'].includes(props.searchCategory)) {
          await dictionariesStoreInstance.getDepartureCities()
              .then(async ({data}) => {
                data.forEach(item => departureCities.value.set(item.id, item))
                await getCountries(fields.value.departureCity)
                tourStoreInstance.clearCurrentSearchFilters('top')
                emitFiltersChanged()
              })
        } else {
          currentCountryId.value = fields.value.country
        }
      } else {
        await dictionariesStoreInstance.getDepartureCities()
            .then(async ({data}) => {
              data.forEach(item => departureCities.value.set(item.id, item))
              await getCountries(departureCityDefault)
            })
      }
    })

    watch(fields.value, value => {
      currentCountryId.value = value.country && currentCountryId.value !== value.country
          ? value.country : currentCountryId.value

      if (props.searchCategory === 'tours') {
        const {country, departureCity, passengers, nights} = value
        localStorage.setItem('userTourFilters', JSON.stringify({country, departureCity, passengers, nights}));
      }
    })

    watch(currentCountryId, async () => {
      await getResorts()
      await getFlyDates()
      if (props.searchCategory === 'hotels') {
        await getAllCountries()
        emitFiltersChanged()
      }
    })

    watch(clearFiltersIsActive, () => {
      if (clearFiltersIsActive.value) {
        setDefaultFieldsValues()
        tourStoreInstance.setClearFiltersActiveValue(false)
      }
    })

    watch(() => props.searchCategory, async () => {
      if (props.searchCategory === 'hotels') {
        await getAllCountries()
      }
    })

    return {
      countries,
      hotelCountries,
      visibleBlocks,
      fields,
      departureCities,
      plural,
      flyDates,
      adultsCount,
      resorts,
      currentCountryId,

      handleClickBlockView,
      handleChangeDepartureCity,
      handleChangeCountry,
      handleChangeFlyDates,
      handleChangeNights,
      handleChangePassengers,
      countPassengers,
      handleDirectionSelection,
      handleChangeResort,
      handleClickOutside,
      handleChangeAllCountries,
    }
  },
  directives: {
    clickOutsideBlock: vClickOutside.directive
  },
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.search-header-block-top {

  .top-wrapper {
    padding: 10px 20px;
    border-radius: 10px;

    @media screen and (min-width: 768px) {
      background: #ffffff;

      &.located-on-main-page {
        padding: 15px 20px;
      }
    }

    .field-wrapper {
      position: relative;
      flex: 1 0 1px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2px 0 3px 0;
      height: 45px;
      cursor: pointer;

      &.departure-cities-wrapper, &.countries-wrapper, &.fly-dates-wrapper {

        @media screen and (max-width: 1679px) {
          min-width: unset;
        }

        @media screen and (max-width: 767px) {
          min-width: unset;
        }
      }

      + .field-wrapper {
        padding-left: 20px;

        @media screen and (min-width: 768px) {
          border-left: 1px solid #e0e0e0;
        }

        @media screen and (max-width: 767px) {
          margin-top: 20px;
        }
      }

      &.direction-wrapper {
        width: 100%;
        cursor: default;

        .direction-input {
          border: none;
          font-family: GilroySemibold;
          font-size: 16px;
          line-height: 20px;
          text-align: left;
          color: $base-black;
          padding: 0;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $base-black;
            opacity: 1;
          }

          &::-ms-input-placeholder {
            color: $base-black;
          }

          @media screen and (max-width: 1679px) {
            font-size: 14px;
            line-height: 17.15px;
          }
        }

        @media screen and (min-width: 1680px) {
          min-width: unset;
        }

        @media screen and (max-width: 1679px) {
          width: calc(100% - 30px);
        }
      }

      &.resort-wrapper {
        padding-right: 20px;

        .search-block {
          top: 75px;
        }
      }

      label {
        margin-bottom: 10px;
      }

      label, span {
        font-family: GilroyMedium;
        font-size: 14px;
        line-height: 16.98px;
        color: #888888;
      }

      button {
        padding: 0;
        display: block;
        font-family: GilroySemibold;
        font-size: 16px;
        color: #222222;
        line-height: normal;
        background: none;
        border: none;
        cursor: pointer;

        @media screen and (max-width: 1679px) {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 767px) {
        padding: 8px 10px 9px 20px;
        width: calc(100% - 30px);
        background: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 6px;

        &.field-dropdown {
          padding: 8px 20px 9px;
          width: calc(100% - 40px);

          &:after {
            content: '';
            position: absolute !important;
            right: 15px;
            display: block;
            width: 14px;
            height: 14px;
            top: 20px;
            background: url('@/assets/images/common/arrow-down-gray.svg');
          }
        }

        &.passengers-wrapper {
          button {
            margin-bottom: 7px;
          }
        }

        &.resort-wrapper {
          padding-right: 10px;
        }

        label {
          margin-bottom: 7px;
        }

        button {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      flex-flow: column;
      padding: 0;

      &.located-on-main-page {
        .field-wrapper:not(:first-child) {
          margin-top: 12px;
        }

        .field-wrapper {
          &.resort-wrapper {
            padding-right: 20px;
          }
        }
      }
    }
  }

  .search-button {
    min-width: 250px;
    text-align: center;
    font-family: GilroySemibold;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    background: linear-gradient(225deg, #ff8500 0%, #ff4800 100%);
    border: none;
    border-radius: 6px;
  }

  &.show-item {
    .top-wrapper {
      padding: 15px 20px;

      @media screen and (max-width: 767px) {
        flex-flow: column;
        padding: 0;
      }
    }
  }
}

</style>
