<template>
  <div
    v-if="leftDate && rightDate"
    class="choose-block flex-row-start"
  >
    <div
      class="choose-block--swipe choose-block--swipe--left"
      @click="handleClickSwipeLeft"
    ></div>
    <div
      class="choose-block--swipe choose-block--swipe--right"
      @click="handleClickSwipeRight"
    ></div>

    <div
      v-for="(startDate, index) in [leftDate, rightDate]"
      :key="index"
      class="choose-block--calendar"
    >
      <div class="choose-block--calendar--current">
        <div class="choose-block--calendar--current--month">
          {{ startDate.format('MMMM') }}
        </div>
        <div class="choose-block--calendar--current--year">
          {{ startDate.format('YYYY') }}
        </div>
      </div>

      <div class="choose-block--calendar--main">
        <div class="choose-block--calendar--dw">
          <div
            v-for="(item, index) in dw"
            :key="index"
            class="choose-block--calendar--item"
            :class="{ 'dw-holiday': index === 5 || index === 6 }"
          >
            {{ item }}
          </div>
        </div>

        <div class="choose-block--calendar--dates">
          <div
            v-for="i in (startDate.day() === 0 ? 6 : startDate.day() - 1)"
            :key="i"
            class="choose-block--calendar--item"
          >
            &nbsp;
          </div>
          <div
            v-for="date in datesList(startDate)"
            :key="date"
            class="choose-block--calendar--item"
            :class="{
              available: isAvailable(date),
              'in-range': fromDate.isBefore(date, 'day') && toDate?.isAfter(date, 'day'),
              'choosen': fromDate.isSame(date, 'day') || toDate?.isSame(date, 'day'),
            }"
            @click="isAvailable(date) && handleSelectDate(date)"
          >
            {{ date.date() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment, { Moment } from 'moment'
import { ref } from 'vue'

export default {
  name: 'MainPageSearchBlockCalendar',
  props: {
    from: {
      type: Moment,
      required: true,
    },
    to: {
      type: Moment,
      required: true,
    },
    availableDates: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  emits: ['change-date'],
  setup (props, { emit }) {
    const dw = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

    const fromDate = ref(props.from?.clone())
    const toDate = ref(props.to?.clone())

    const leftDate = ref(fromDate.value?.clone().startOf('month'))
    const rightDate = ref(fromDate.value?.clone().add(1, 'month'))

    const datesList = (startDate) => {
      const list = []

      for (let i = 1; i <= startDate.daysInMonth(); i++) {
        list.push(moment(startDate).clone().date(i))
      }

      return list
    }

    const isAvailable = (current) => {
      for (const date of props.availableDates) {
        if (current.isSame(date, 'day')) {
          return true
        }

        if (current.isBefore(date, 'day')) {
          return false
        }
      }

      return false
    }

    const handleClickSwipeLeft = () => {
      leftDate.value.subtract(1, 'month')
      rightDate.value = leftDate.value.clone().add(1, 'month')
    }

    const handleClickSwipeRight = () => {
      leftDate.value.add(1, 'month')
      rightDate.value = leftDate.value.clone().add(1, 'month')
    }

    const handleSelectDate = date => {
      if (toDate.value) {
        fromDate.value = date
        toDate.value = null
      } else {
        if (fromDate.value.unix() > date.unix()) {
          toDate.value = fromDate.value
          fromDate.value = date
        } else {
          toDate.value = date
        }
        emit('change-date', fromDate.value, toDate.value)
      }
    }

    return {
      dw,
      fromDate,
      toDate,
      leftDate,
      rightDate,
      datesList,
      isAvailable,
      handleClickSwipeLeft,
      handleClickSwipeRight,
      handleSelectDate,
    }
  }
}
</script>

<style scoped lang="scss">
.choose-block {
  position: absolute !important;
  top: 75px;
  left: 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  @media screen and (max-width: 767px) {
    top: 80px;
    padding-left: 8px;
    padding-right: 8px;
  }

  &--swipe {
    position: absolute !important;
    top: 20px;
    width: 44px;
    height: 44px;
    background-color: #f8f8f8;
    background-image: url('@/assets/images/common/arrow-down-black.svg');
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;

    &--left {
      left: 20px;
      transform: scaleX(-1);

      @media screen and (max-width: 767px) {
        left: 8px;
      }
    }

    &--right {
      right: 20px;

      @media screen and (max-width: 767px) {
        right: 8px;
      }
    }
  }

  &--calendar {
    + .choose-block--calendar {
      margin-left: 40px;

      @media screen and (max-width: 1800px) {
        display: none;
      }
    }

    &--current {
      padding: 6px 0 3px;
      text-align: center;

      &--month {
        text-align: center;
        font-family: GilroyBold;
        font-size: 16px;
        color: #222222;
        text-transform: uppercase;
      }

      &--year {
        text-align: center;
        font-family: GilroyMedium;
        font-size: 12px;
        color: #888888;
        text-transform: uppercase;
      }
    }

    &--main {
      margin-top: 20px;
    }

    &--dw {
      display: grid;
      grid-template-columns: repeat(7, 45px);
      grid-template-rows: repeat(1, 45px);
      grid-gap: 5px;
      align-items: center;
      justify-items: center;
      color: #222222;

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(7, 42px);
        grid-template-rows: repeat(1, 42px);
      }

      .dw-holiday {
        color: #ff4800;
      }
    }

    &--dates {
      margin-top: 5px;
      display: grid;
      grid-template-columns: repeat(7, 45px);
      grid-template-rows: repeat(6, 45px);
      grid-gap: 5px;
      color: #888888;

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(7, 42px);
        grid-template-rows: repeat(1, 42px);
      }
    }

    &--item {
      width: 45px;
      text-align: center;
      font-family: GilroySemibold;
      font-size: 14px;
      line-height: 45px;
      text-transform: uppercase;
      border-radius: 5px;

      @media screen and (max-width: 767px) {
        width: 42px;
        line-height: 42px;
      }

      &.available {
        color: #222222;
        background: #fff5eb;
        cursor: pointer;

        &.in-range {
          color: #222222;
          background: #ffeedb;
        }

        &:hover {
          color: #ffffff;
          background: #ff8500;
        }
      }

      &.choosen {
        color: #ffffff;
        background: #ff8500;
      }
    }
  }
}
</style>
