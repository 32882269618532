<template>
  <icon-dots
    wrapper-width="23px"
    :dot-size="3"
    cursor-pointer
    :close="showContextMenu"
    @open="handleOpenContextMenu"
    @close="handleCloseContextMenu"
    class="item more"
  ></icon-dots>
  <div
    class="context-menu"
    :class="showContextMenu ? 'active' : 'd-none'"
  >
    <div
      v-for="item in items"
      :key="item.type"
      class="context-menu-item flex-row-center-end cursor-pointer"
      :class="{
        'active': item.active,
        'exit': item.type === 'logout',
        'hovered': item.active ? false : item.hovered
      }"
      @mouseover="item.hovered = !['profile', 'logout'].includes(item.type)"
      @mouseout="item.hovered = false"
      @click.stop.prevent="onItemClick(item)"
    >
      <span class="text-right">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>

import { computed, onMounted, onUnmounted, ref } from 'vue'
import { accountPageStore } from '@/stores/accountPageStore'
import IconDots from '@/components/icon/IconDots.vue'

export default {
  name: 'PersonalAccountMoreComponent',
  components: {
    IconDots
  },
  setup() {
    const showContextMenu = ref(false)
    const accountPageStoreInstance = accountPageStore()
    const items = computed(() => accountPageStoreInstance.accountMenu)

    const handleOpenContextMenu = () => showContextMenu.value = true
    const handleCloseContextMenu = () => showContextMenu.value = false
    const onDocumentClick = () => {
      if (showContextMenu.value) {
        showContextMenu.value = false
      }
    }

    const onItemClick = item => accountPageStoreInstance.selectMenuItem(item)

    onMounted(() => document.addEventListener('click', onDocumentClick))
    onUnmounted(() => document.removeEventListener('click', onDocumentClick))

    return {
      showContextMenu,
      items,

      handleOpenContextMenu,
      handleCloseContextMenu,
      onItemClick
    }
  },
}
</script>

<style scoped lang='scss'>

.context-menu {
  position: absolute;
  right: 0;
  top: 58px;
  width: 163px;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 #0000000F;
  padding: 20px 20px;
  z-index: 1;
  .context-menu-item {
    margin-right: 0;
    font-family: GilroyRegular;
    font-size: 14px;
    line-height: 14px;
    color: #888888;
    &.active, &.hovered {
      color: #222222;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:last-child {
      color: #FF8500;
      font-family: GilroyMedium;
    }
  }
}

</style>
