import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './assets/css/site.scss'
import axios from 'axios'
import 'moment/locale/ru'
import { authStore } from '@/stores/authStore'
import VueAwesomePaginate from 'vue-awesome-paginate'
import 'vue-awesome-paginate/dist/style.css'
import PersonalAccountPageMenu from '@/pages/personal-account-page/PersonalAccountPageMenu.vue'
import PersonalAccountPageMenuItem from '@/pages/personal-account-page/PersonalAccountPageMenuItem.vue'
import PersonalAccountPageContent from '@/pages/personal-account-page/PersonalAccountPageContent.vue'
import MainPageSearchBlock from '@/pages/main-page/MainPageSearchBlock.vue'
import MainPageAboutProject from '@/pages/main-page/MainPageAboutProject.vue'
import MainPageAdvantages from '@/pages/main-page/MainPageAdvantages.vue'
// import MobileApp from '@/components/MobileApp.vue'
import QuestionsAnswers from '@/components/QuestionsAnswers.vue'
import SearchFormComponent from '@/components/forms/search/SearchFormComponent.vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { vMaska } from 'maska'
import { initYandexMetrika } from 'yandex-metrika-vue3'

const app = createApp(App)
const pinia = createPinia()

app.config.globalProperties.$http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_URL,
  headers: {
    'Content-Type': 'application/json'
  },
})

app.config.globalProperties.$http.interceptors.request.use(
  (config) => {
    const authStoreInstance = authStore()
    if (authStoreInstance.currentAuthToken) {
      config.headers.Authorization = `Bearer ${authStoreInstance.currentAuthToken}`
    }
    config.headers['Content-Type'] = `application/json`
    return config
  },
  error => Promise.reject(error)
)

app.component('PersonalAccountPageMenu', PersonalAccountPageMenu)
app.component('PersonalAccountPageMenuItem', PersonalAccountPageMenuItem)
app.component('PersonalAccountPageContent', PersonalAccountPageContent)
app.component('MainPageSearchBlock', MainPageSearchBlock)
app.component('MainPageAboutProject', MainPageAboutProject)
app.component('MainPageAdvantages', MainPageAdvantages)
// app.component('MobileApp', MobileApp)
app.component('QuestionsAnswers', QuestionsAnswers)
app.component('SearchFormComponent', SearchFormComponent)

app.directive('maska', vMaska)

pinia.use(
  ({ store }) => store.$http = app.config.globalProperties.$http
)
app.use(pinia)
app.use(router)
app.use(VueAwesomePaginate)
app.use(VueViewer)
app.use(initYandexMetrika, {
  id: 96160552,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})
app.mount('#app')
