<template>
  <div
      v-if="$router.currentRoute.value.name === 'main'"
      class="search-block"
      style="position: relative"
  >

    <div class="search-block__tabs">
      <div
          v-for="tab in tabs"
          :key="tab.type"
          class="search-block__tab"
          :class="{'active': tab.active}"
          @click="handleClickTab(tab)"
      >
        <template v-if="tab.icon">
          <div class="flex-row">
            <img
                :src="tab.icon"
                class="search-block__tab-icon"
            />
            <div>{{ tab.name }}</div>
          </div>
        </template>
        <div v-else>{{ tab.name }}</div>
      </div>
    </div>

    <div
        class="search-block__content"
        :class="{
        'left-top-border-radius': activeTab !== 'search-tour'
      }"
    >
      <div
          v-if="activeTab === 'search-tour'"
          class="search-block__content__header flex-row-start"
      >
        <check-box-field-component
            v-for="filter in tourTypeFilters"
            :key="filter.id"
            :label="filter.label"
            :id="filter.id"
            :value="filter.value"
            white-border-desktop-only
        ></check-box-field-component>
      </div>

      <search-form-header-top-component
          class="search-block__content__top"
          :search-category="searchCategory"
          located-on-main-page
          @change-city="handleChangeDepartureCity"
          @search="handleSearch"
          @filters-changed="handleTopBlockFiltersChange"
      ></search-form-header-top-component>

      <search-form-header-bottom-component
          :search-category="searchCategory"
          show-search-button-always
          located-on-main-page
          :search-button-text="searchButtonText"
          class="search-block__content__bottom"
          @search="handleSearch"
          @filters-changed="handleBottomBlockFiltersChange"
      ></search-form-header-bottom-component>
    </div>

  </div>
</template>

<script>
import {computed, ref} from 'vue'
import CheckBoxFieldComponent from '@/components/forms/CheckBoxFieldComponent.vue'
import SearchFormHeaderTopComponent from '@/components/forms/search/SearchFormHeaderTopComponent.vue'
import SearchFormHeaderBottomComponent from '@/components/forms/search/SearchFormHeaderBottomComponent.vue'
import {goToSearchHotelPage, goToSearchHotTourPage, goToSearchTourPage} from '@/composables/router'
import {tourStore} from '@/stores/tourStore'

export default {
  name: 'MainPageSearchBlock',
  emits: ['change-city'],
  components: {
    SearchFormHeaderBottomComponent,
    SearchFormHeaderTopComponent,
    CheckBoxFieldComponent,
  },
  setup(props, {emit}) {
    const tourTypeFilters = ref([
      // {label: 'Туры с перелетом', id: 'tours-with-flights', value: false},
      // {label: 'Горящие туры', id: 'hot-tours', value: false}
    ])
    const activeTab = ref('search-tour')
    const tabs = ref([
      {name: 'Поиск туров', type: 'search-tour', active: true},
      {name: 'Горящие туры', type: 'search-hot-tour', active: false, icon: require('@/assets/images/common/flame.svg')},
      // {name: 'Поиск отелей', type: 'search-hotel', active: false},
    ])
    const filtersData = ref({
      topBlockFilters: null,
      bottomBlockFilters: null
    })
    const tourStoreInstance = tourStore()
    const searchButtonText = computed(() => {
      let text = 'Найти туры'
      if (activeTab.value === 'search-hotel') {
        text = 'Найти отели'
      } else if (activeTab.value === 'search-hot-tour') {
        text = 'Найти горящие туры'
      }
      return text
    })
    const searchCategory = computed(() => {
      let category = 'tours'
      if (activeTab.value === 'search-hotel') {
        category = 'hotels'
      } else if (activeTab.value === 'search-hot-tour') {
        category = 'hot-tours'
      }
      return category
    })

    const handleChangeDepartureCity = (cityId, cityNameFrom) => emit('change-city', cityId, cityNameFrom)
    const handleSearch = () => {
      tourStoreInstance.setCurrentSearchFilters({...filtersData.value})
      if (activeTab.value === 'search-tour') {
        goToSearchTourPage()
      } else if (activeTab.value === 'search-hot-tour') {
        goToSearchHotTourPage()
      } else {
        goToSearchHotelPage()
      }
    }
    const handleTopBlockFiltersChange = data => filtersData.value.topBlockFilters = data
    const handleBottomBlockFiltersChange = data => filtersData.value.bottomBlockFilters = data
    const handleClickTab = tab => {
      activeTab.value = tab.type
      tabs.value.map(t => t.active = t.type === tab.type)
    }

    return {
      tourTypeFilters,
      tabs,
      activeTab,
      searchButtonText,
      searchCategory,

      handleChangeDepartureCity,
      handleSearch,
      handleBottomBlockFiltersChange,
      handleTopBlockFiltersChange,
      handleClickTab,
    }
  },
}
</script>

<style scoped lang="scss">

@import '@/assets/css/variables';

.search-block {
  top: 130px;

  &__tabs {
    display: flex;
  }

  &__tab {
    padding: 16px 20px 14px;
    color: $base-white;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-family: GilroyMedium;

    &.active {
      cursor: default;
      background: rgba(255, 255, 255, .6);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      color: $base-black;
      font-family: GilroySemibold;
    }
  }

  &__tab-icon {
    margin-top: -2px;
    margin-right: 3px;
    width: 20px;
    height: 20px;
  }

  &__content {
    width: 100%;
    border-radius: 0 16px 16px 16px;
    box-sizing: border-box;
    padding: 20px;
    background: rgba(255, 255, 255, .6);

    &__header {
      .field-wrapper {
        + .field-wrapper {
          margin-left: 30px;
        }
      }
    }

    &__top {
      border-radius: 10px;

      @media screen and (min-width: 768px) {
        background: #ffffff;
      }

      @media screen and (max-width: 767px) {
        flex-flow: column;
        padding: 0;
      }

      .field-wrapper {
        position: relative;
        flex: 1 0 1px;
        padding: 2px 20px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
        height: 50px;

        @media screen and (max-width: 1679px) {
          padding: 3px 5px 3px 15px;
          font-size: 14px;
        }

        @media screen and (max-width: 767px) {
          flex-basis: 60px;
          padding: 10px 28px 10px 20px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #eaeaea;
          border-radius: 6px;
        }

        + .field-wrapper {
          @media screen and (min-width: 768px) {
            border-left: 1px solid #e0e0e0;
          }

          @media screen and (max-width: 767px) {
            margin-top: 20px;
          }
        }
      }

      label, span {
        display: block;
        color: #888888;
      }

      button {
        padding: 0;
        display: block;
        font-family: GilroySemibold;
        font-size: 16px;
        color: #222222;
        line-height: normal;
        background: none;
        border: none;
        cursor: pointer;

        @media screen and (max-width: 1679px) {
          font-size: 14px;
        }
      }
    }

    &__bottom {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 20px;

      margin-top: 40px;

      @media screen and (max-width: 1679px) {
        margin-top: 0;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 767px) {
        flex-flow: column;
      }
    }

    &.left-top-border-radius {
      border-top-left-radius: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    top: 80px;

    &__tab {
      padding: 17px 15px;
      font-family: GilroyMedium;
      font-size: 13px;
      line-height: 16px;

      &.active {
        font-size: 13px;
        line-height: 16px;
        font-family: GilroySemibold;
      }
    }

    &__tab-icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
