<template>
  <div class="vk-auth-page"></div>
</template>

<script>

import router from '@/router'
import { onMounted } from 'vue'
import { goToMainPage } from '@/composables/router'
import { authStore } from '@/stores/authStore'

export default {
  name: 'SearchTourPage',
  setup() {
    const authStoreInstance = authStore()

    const processVKAuthCode = () => {
      let code = null
      if (router.currentRoute.value.query) {
        code = router.currentRoute.value.query.code
      }

      if (code) {
        authStoreInstance.setVKAuthCode(code)
      }

      router.replace({query: null})

      goToMainPage()
    }

    onMounted(() => processVKAuthCode())
  }
}

</script>
