<template>
  <div class="questions-answers-wrapper flex-row-start-space-between">
    <div class="info">
      <div class="title text-left">Вопросы и ответы</div>
      <hr class="one">
      <hr class="two">
      <div class="text text-left">Ответы на самые популярные ответы наших пользователей. Если Вы не нашли ответ на свой вопрос, просто напишите нам и мы с радостью подскажем!</div>
      <div
        class="image cursor-pointer"
        @click="handleClickLearnMore"
      ></div>
    </div>
    <div class="questions-answers w-100">
      <div
        v-for="(questionAnswer, index) in questionsAnswers"
        :key="index"
        class="question-answer-item flex-column-start"
        @click="onClickActionButton(index)"
      >
        <div class="title-wrapper flex-row-center-space-between w-100 cursor-pointer">
          <div class="title">{{ questionAnswer.title }}</div>
          <div class="action-button">
            <div
              class="image"
              :class="questionAnswer.show ? 'minus' : 'plus'"
            ></div>
          </div>
        </div>
        <div
          v-if="questionAnswer.show"
          class="text text-left"
          @click.stop.prevent
        >{{ questionAnswer.text }}</div>
      </div>
      <div
        class="learn-more flex-row-center-center d-none cursor-pointer"
        @click="handleClickLearnMore"
      ></div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import { getTelegram } from '@/composables/contacts'

export default {
  name: 'QuestionsAnswers',
  setup() {
    const questionsAnswers = ref([
      {
        title: 'Как найти подходящий тур?',
        text: 'Поиск подходящего тура зависит от ваших предпочтений, интересов и ожиданий от поездки.' +
          ' Вот несколько шагов, которые помогут вам найти подходящий тур:\n\n1. Определите ваши предпочтения:\n' +
          '- Направление: Решите, куда вы бы хотели отправиться. Это может быть пляжный отдых, горнолыжный курорт,' +
          ' культурный тур или приключенческое путешествие.\n' +
          '- Длительность поездки: Определите, сколько времени вы хотели бы провести в путешествии.\n' +
          '- Бюджет: Определите свой бюджет на поездку. Это позволит узнать, какие варианты доступны для вас.\n\n' +
          '2. Отзывы и рейтинги:\n - Посмотрите отзывы других путешественников о турах и отелях, чтобы получить' +
          ' представление о качестве услуг.\n\n3. Консультация с агентами или специалистами:\n' +
          '- Обратитесь к нам по телефону или Telegram для получения профессиональных рекомендаций и помощи в выборе' +
          ' тура, особенно если у вас есть особые требования или пожелания.\n\n4. Учитывайте дополнительные факторы:\n' +
          '- Время года: Некоторые направления лучше посещать в определенное время года из-за погоды или событий.\n\n' +
          'Безопасность и здоровье: Учтите обстановку в стране, визовые требования, медицинскую инфраструктуру' +
          ' и рекомендации по безопасности.\n\nВыберите лучший вариант: Исходя из ваших предпочтений, бюджета и' +
          ' дополнительных факторов, выберите наиболее подходящий для вас тур.\n\nНе стесняйтесь задавать вопросы' +
          ' и уточнять детали у наших специалистов , чтобы сделать правильный выбор и получить наибольшее ' +
          'удовольствие от путешествия.\n\n',
        show: false,
      },
      {
        title: 'Как купить тур на сайте?',
        text: 'Процесс покупки тура на нашем сайте обычно следует примерно такому плану:\n\n- Выбор тура: Первым' +
          ' шагом является выбор интересующего вас тура. Это может быть определенное направление, длительность,' +
          ' тип тура (например, групповой, индивидуальный и т. д.).\n\n- Выбор даты и условий: Выберите даты поездки' +
          ' и дополнительные услуги (например, страховка, трансферы, дополнительные экскурсии).\n\n- Покупка: На' +
          ' странице с описанием тура есть кнопка "Купить". После её нажатия вас попросят заполнить данные пассажиров' +
          ' и выбрать способ оплаты.\n\n- Подтверждение и оплата: После заполнения всех необходимых данных и выбора' +
          ' параметров тура, система предложит вам подтвердить бронь и произвести оплату.\n\n- Получение' +
          ' подтверждения: После успешной оплаты вы должны получить подтверждение бронирования на ваш электронный' +
          ' адрес и в личный кабинет. Обязательно сохраните это подтверждение – оно может понадобиться при' +
          ' регистрации на рейс или при обращении в случае возникновения проблем.\n\nПри бронировании тура важно' +
          ' внимательно читать условия, особенно в отношении отмены, возврата средств и дополнительных опций.' +
          ' Если возникают вопросы или неясности, лучше связаться с поддержкой сайта по телефону или в Telegram для' +
          ' получения помощи и уточнения информации.',
        show: false,
      },
      {
        title: 'После оплаты тура онлайн, когда со мной свяжутся?',
        text: 'После успешной оплаты тура онлайн в течении 24 часов вам должно прийти уведомление о подтверждении' +
          ' бронирования на указанный вами электронный адрес и в Личный кабинет. Это письмо может содержать детали' +
          ' вашего бронирования, подтверждение оплаты, даты поездки, информацию о том, что ожидается дальше, и' +
          ' контактные данные для связи с организаторами тура.\n\nВ зависимости от типа тура (например, групповой' +
          ' или индивидуальный), время до начала поездки и другие факторы, с вами могут связаться в разное время:\n\n' +
          '- Немедленно: В некоторых случаях вы можете получить подтверждение бронирования почти мгновенно после' +
          ' оплаты.\n\n- Через несколько часов или дней: Иногда это может занять некоторое время, особенно если' +
          ' требуется подтверждение оплаты или уточнение дополнительной информации перед тем, как связаться с вами.' +
          '\n\n- Ближе к дате поездки: Как правило, за несколько недель или дней до начала поездки вы можете ожидать' +
          ' получить более подробную информацию о вашем туре: расписание, место встречи, контактную информацию в' +
          ' случае необходимости и т.д.\n\nЕсли вы обеспокоены и хотите подтвердить свою бронь или уточнить' +
          ' информацию о поездке, не стесняйтесь связаться с нами по телефону или Telegram. Имеет смысл также' +
          ' проверить папку "Спам" или "Нежелательная почта" в вашем почтовом ящике, чтобы убедиться, что письмо с' +
          ' подтверждением бронирования не попало туда.\n\n',
        show: false,
      },
      {
        title: 'Как купить горящий тур или тур по минимальной цене?',
        text: 'При покупке горящего тура или путёвки по минимальной цене полезно учитывать несколько моментов:\n\n' +
          '- Используйте специализированные ресурсы: В нашем Telegram постоянно предлагают актуальную информацию о' +
          ' горящих турах и специальных предложениях различных туроператоров. Подписка позволит быстро получать' +
          ' информацию о свежих предложениях.\n- Гибкость в планировании: Если у вас есть гибкость в выборе дат или' +
          ' направлений, вы сможете лучше воспользоваться горящими предложениями. Готовность к отъезду в последний' +
          ' момент или в определенные даты может позволить вам найти тур по более низкой цене.\n- Следите за сезонами' +
          ' и спросом: Иногда цены на туры могут снижаться из-за низкого спроса на определенные направления или вне' +
          ' сезона. Например, путёвки в популярные места могут быть доступнее во второй половине сезона, когда спрос' +
          ' снижается.\n- Групповые скидки: Если вы путешествуете группой, некоторые варианты поездок могут предложить' +
          ' скидки при бронировании нескольких мест сразу.\n- Отслеживайте изменения цен: Иногда цены на туры могут' +
          ' изменяться в зависимости от спроса и заполненности групп. Мониторинг цен поможет вам заметить момент,' +
          ' когда цена на понравившийся вам тур станет наиболее выгодной.\n\nНе забывайте, что при покупке горящего' +
          ' тура важно внимательно читать условия, чтобы избежать неприятных сюрпризов и уточнить, что включено' +
          ' в стоимость тура.',
        show: false,
      },
      {
        title: 'Почему при выборе тура на последнем шаге повышается цена?',
        text: 'Есть несколько причин, по которым цена тура может повыситься на последних этапах бронирования:\n\n' +
          '- Динамическая ценообразование:  Чем больше людей ищут одинаковый тур или отель, тем выше может быть цена' +
          ' из-за ограниченного количества свободных мест или из-за повышения спроса.\n\n- Дополнительные сборы и' +
          ' налоги: Некоторые скрытые сборы или налоги могут быть добавлены на последних этапах оформления' +
          ' бронирования. Это могут быть такие вещи, как туристический сбор, страховка и прочие дополнительные ' +
          'услуги.\n\n- Конверсионные шаги:  Первоначально может быть показана базовая цена, а дополнительные услуги' +
          ' или улучшения могут добавляться на более поздних этапах оформления, что увеличивает общую стоимость.' +
          '\n\n- Колебания валютных курсов: Если оплата производится в другой валюте, колебания курса могут привести' +
          ' к изменению цены тура на последних этапах.\n\n- Комиссии и сборы бронирования: Некоторые платформы могут' +
          ' взимать дополнительные комиссии или сборы за обработку платежей на более поздних этапах бронирования.' +
          'Чтобы избежать таких сюрпризов, полезно внимательно изучить все условия бронирования, чтобы понять, какие' +
          ' именно факторы могут привести к увеличению цены.',
        show: false,
      },
    ])

    const onClickActionButton = index => questionsAnswers.value.map((i, idx) => {
      if (idx === index) {
        i.show = !i.show
      } else {
        i.show = false
      }
    })
    const handleClickLearnMore = () => window.open(getTelegram(), '_blank')

    return {
      questionsAnswers,

      onClickActionButton,
      handleClickLearnMore
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.questions-answers-wrapper {
  padding: 100px 120px;
  background: #F8F8F8;

  .info {
    .title {
      font-family: GilroyBold;
      font-size: 36px;
      line-height: 54px;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 42px;
      }
    }
    hr {
      padding: 0;
      margin: 0 0 0 0;
      height: 1px;
      background: #FF8500;
      border: none;
      &.one {
        width: 50px;
        margin-bottom: 5px;
      }
      &.two {
        width: 30px;
        margin-bottom: 30px;
      }
    }
    .text {
      font-family: GilroyRegular;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 70px;
      color: #222222;

      @media (max-width: 1679px) {
        margin-bottom: 40px;
      }
    }
    .image {
      width: 162px;
      height: 162px;
      background-image: url('@/assets/images/common/learn-more.svg');

      @media (max-width: 1679px) {
        display: none;
      }
    }
  }

  .questions-answers {
    margin-left: 20px;
    min-width: 720px;
    max-width: 720px;

    .question-answer-item {
      padding: 15px 20px;
      border-radius: 10px;
      background: $base-white;
      box-shadow: 0 4px 0 0 #0000000A;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .title-wrapper {
        .title {
          font-family: GilroySemibold;
          font-size: 16px;
          line-height: 24px;
        }

        .action-button {
          .image {
            width: 40px;
            height: 40px;

            &.plus {
              background-image: url('@/assets/images/common/plus.svg');
            }

            &.minus {
              background-image: url('@/assets/images/common/minus.svg');
            }
          }
        }

        @media (max-width: 767px) {
          text-align: left;
          line-height: 21px;
          margin-right: 20px;
        }
      }

      .text {
        margin-top: 20px;
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 22px;
        color: #888888;
        white-space: pre-wrap;

        @media (max-width: 767px) {
          margin-top: 10px;
        }
      }

      @media (max-width: 1679px) {
        width: calc(100% - 48px);
      }
    }
    .learn-more {
      @media (max-width: 1679px) {
        margin-top: 50px;
        display: block;
        width: 162px;
        height: 162px;
        background-image: url('@/assets/images/common/learn-more.svg');
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    @media (max-width: 1679px) {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      max-width: unset;
      min-width: unset;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: unset;
      min-width: unset;
    }
  }

  @media (min-width: 1678px) {
    width: calc(100% - 240px);
  }

  @media (max-width: 1679px) {
    padding: 70px 24px;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    padding: 70px 10px 50px 10px;
  }
}

</style>
