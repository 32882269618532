import { defineStore } from 'pinia'

const moduleName = 'favorites'

export const favoritesStore = defineStore(moduleName, {
  state: () => ({}),
  getters: {},
  actions: {
    getFavorites () {
      return this.$http.get(`${moduleName}?type=FAVORITE`)
    },
    getTours () {
      return this.$http.get(`${moduleName}?type=PAID_TOUR`)
    },
    addToFavorites(tourId) {
      return this.$http.post(`${moduleName}`, {tourId})
    },
    removeFromFavorites(tourId) {
      return this.$http.delete(`${moduleName}/${tourId}`)
    }
  },
})
