<template>
  <div class="search-form--content">
    <div
        v-if="showLoader"
        class="search-form--content--item toolbar"
    >
      <div class="loader-wrapper flex-column-center-center">
        <animated-loader
            container-top-position="20px"
            container-left-position="50%"
            container-size="50px"
            loader-size="50px"
        ></animated-loader>
        <span class="loader-text">Кайфуйте, пока мы ищем лучшие туры для вас</span>
      </div>
    </div>
    <search-form-content-list-component
        :items="items"
        :items-count="itemsCount"
        :show-empty="showEmptyResult"
        :page="currentPage"
        :search-category="searchCategory"
        class="search-form--content--item"
        @page-changed="handlePageChanged"
        @per-page-count-changed="handlePerPageCountChanged"
    ></search-form-content-list-component>
  </div>
</template>

<script>


import SearchFormContentListComponent from '@/components/forms/search/SearchFormContentListComponent.vue'
import AnimatedLoader from '@/components/AnimatedLoader.vue'
import {computed, nextTick, onUnmounted, ref, watch} from 'vue'
import {tourStore} from '@/stores/tourStore'
import {setItemToLocalStorage, getItemFromLocalStorage, removeItemFromLocalStorage} from '@/composables/localStorage'
import {scrollIntoView} from "@/composables/scroll";
import {useRoute} from "vue-router";

export default {
  name: 'SearchFormContentComponent',
  props: {
    searchCategory: String,
    search: Boolean,
    filtersData: Object,
  },
  emits: ['search-finished'],
  components: {
    AnimatedLoader,
    SearchFormContentListComponent
  },
  setup(props, {emit}) {
    const items = ref([])
    const itemsCount = ref(0)
    const departureForSearchByDefault = 99
    const tourStoreInstance = tourStore()
    const searchResultInterval = ref(null)
    const showLoader = ref(false)
    const showEmptyResult = ref(false)
    const localStorageRequestKey = 'searchRequestId'
    const currentPage = ref(1)
    const perPageCount = ref(5)
    const canSearch = ref(true)

    const route = useRoute();

    const isMainPage = computed(() => !!route.query?.all && !items.value?.length)

    const search = () => {
      if (!canSearch.value) {
        return
      }

      if (props.searchCategory === 'hot-tours') {
        searchAsHotTours()
      } else {
        searchAsUsualTours()
      }
    }
    const searchAsUsualTours = () => {
      canSearch.value = false
      const requestIdFromLocalStorage = getItemFromLocalStorage(localStorageRequestKey)
      if (requestIdFromLocalStorage) {
        showLoader.value = true
        showEmptyResult.value = false
        getSearchResult(requestIdFromLocalStorage)
        return
      }
      const bottomBlockData = props.filtersData.bottomBlockFilters
      const topBlockData = props.filtersData.topBlockFilters
      const stars = bottomBlockData && bottomBlockData.hotelStars ? `${bottomBlockData.hotelStars}*` : null
      const rating = bottomBlockData && bottomBlockData.rating ? bottomBlockData.rating : null
      const meal = bottomBlockData && bottomBlockData.meal ? bottomBlockData.meal : null
      let services = bottomBlockData && bottomBlockData.services ? bottomBlockData.services.join(',') : null
      const pricefrom = bottomBlockData && bottomBlockData.budget && bottomBlockData.budget.from
          ? bottomBlockData.budget.from : null
      const priceto = bottomBlockData && bottomBlockData.budget && bottomBlockData.budget.to
          ? bottomBlockData.budget.to : null
      const currency = bottomBlockData && bottomBlockData.budget && bottomBlockData.budget.currency
          ? bottomBlockData.budget.currency : null
      const datefrom = topBlockData && topBlockData.flyDate && topBlockData.flyDate.from
          ? topBlockData.flyDate.from.format('DD.MM.YYYY') : null
      const dateto = topBlockData && topBlockData.flyDate && topBlockData.flyDate.to
          ? topBlockData.flyDate.to.format('DD.MM.YYYY') : null
      let nightsfrom = topBlockData && topBlockData.nights ? topBlockData.nights.from : null
      let nightsto = topBlockData && topBlockData.nights ? topBlockData.nights.to : null
      if (props.searchCategory === 'hotels' && datefrom && dateto && datefrom !== dateto) {
        nightsfrom = topBlockData.flyDate.to.diff(topBlockData.flyDate.from, 'days')
        nightsto = nightsfrom
      }
      let adults = null
      let child = null
      let childAge1 = null
      let childAge2 = null
      let childAge3 = null
      if (topBlockData && topBlockData.passengers) {
        Object.keys(topBlockData.passengers).map(key => {
          key = parseInt(key)
          if (!key) {
            adults = topBlockData.passengers[key]
          } else if (child === null || child < 3) {
            child = child === null ? topBlockData.passengers[key] : child + topBlockData.passengers[key]
            if (child === 1) {
              childAge1 = key
            } else if (child === 2) {
              childAge2 = key
            } else {
              childAge3 = key
            }
          }
        })
      }
      if (bottomBlockData && bottomBlockData.hotelPlacesGuarantee) {
        const hotelPlacesGuaranteeServiceCode = 46
        services = services ? `${services},${hotelPlacesGuaranteeServiceCode}` : hotelPlacesGuaranteeServiceCode
      }
      showLoader.value = true
      showEmptyResult.value = false
      const departure = props.searchCategory === 'tours' ? topBlockData.departureCity : departureForSearchByDefault
      const country = topBlockData.country
      const regions = props.searchCategory === 'tours'
          ? (topBlockData.resort ? topBlockData.resort.map(r => r.id).join(',') : null)
          : (topBlockData.direction ? topBlockData.direction.id : null)
      tourStoreInstance.getSearchRequestId(
          departure,
          country,
          {
            stars, rating, services, pricefrom, priceto, currency, datefrom, dateto, meal, adults, child,
            childAge1, childAge2, childAge3, regions, nightsfrom, nightsto
          }
      )
          .then(({data}) => getSearchResult(data))
          .catch(() => {
            showEmptyResult.value = true
            showLoader.value = false
            canSearch.value = true
            itemsCount.value = 0
            items.value = []
            emitSearchFinished()
          })
    }
    const searchAsHotTours = async () => {
      canSearch.value = false
      const bottomBlockData = props.filtersData.bottomBlockFilters
      const topBlockData = props.filtersData.topBlockFilters
      const departure = topBlockData.departureCity
      const meal = bottomBlockData && bottomBlockData.meal ? bottomBlockData.meal : null
      const stars = bottomBlockData && bottomBlockData.hotelStars ? `${bottomBlockData.hotelStars}*` : null
      const rating = bottomBlockData && bottomBlockData.rating ? bottomBlockData.rating : null
      const datefrom = topBlockData?.flyDate?.from && !isMainPage.value
          ? topBlockData.flyDate.from.format('DD.MM.YYYY') : null
      const dateto = topBlockData?.flyDate?.to && !isMainPage.value
          ? topBlockData.flyDate.to.format('DD.MM.YYYY') : null
      const countries = topBlockData.country
      const regions = topBlockData.resort ? topBlockData.resort.map(r => r.id).join(',') : null
      showLoader.value = true
      showEmptyResult.value = false
      await tourStoreInstance.searchHotToursExtended(
          departure, perPageCount.value, meal, stars, rating, datefrom, dateto, regions, countries
      )
          .then(({data}) => {
            const index = itemsCount.value
            itemsCount.value = data.length
            showEmptyResult.value = !itemsCount.value
            items.value = data.map(item => {
              return {
                picturelink: item.picture,
                price: item.price,
                hotelrating: '0',
                hotelstars: item.hotelStars,
                regionname: item.hotelrRegionName,
                hotelcode: parseFloat(item.hotelCode),
                hoteldescription: null,
                hotelname: item.hotelName,
                currency: item.currency,
                tours: {
                  tour: []
                },
                tourId: item.tourId,
                operatorImage: item.operatorImage
              }
            })
            canSearch.value = true
            showLoader.value = false
            emitSearchFinished()
            goToItem(index)
          })
          .catch(() => {
            showEmptyResult.value = true
            showLoader.value = false
            canSearch.value = true
            itemsCount.value = 0
            items.value = []
            emitSearchFinished()
          })
    }
    const goToItem = async (index) => {
      const scrollIndex = index > 0 ? index - 1 : 0
      await nextTick()
      const id = items.value.at(scrollIndex)?.hotelcode
      if (!id) return
      scrollIntoView(id)
    }
    const setSearchResultInterval = requestId => {
      setItemToLocalStorage(localStorageRequestKey, requestId)
      searchResultInterval.value = setInterval(() => getSearchResult(requestId), 3000)
    }
    const clearSearchResultInterval = () => {
      if (searchResultInterval.value) {
        clearInterval(searchResultInterval.value)
      }
      searchResultInterval.value = null
      showLoader.value = false
      canSearch.value = true
    }
    const getSearchResult = requestId => {
      tourStoreInstance.getSearchRequestResult(requestId, currentPage.value, perPageCount.value)
          .then(({data}) => {
            if (data && data.data && data.data.status) {
              if (['searching', 'finished'].includes(data.data.status.state)) {
                if (!data.data.result && !searchResultInterval.value) {
                  setSearchResultInterval(requestId)
                } else {
                  const index = itemsCount.value
                  items.value = data.data.result && data.data.result.hotel ? data.data.result.hotel : []
                  itemsCount.value = data.data.status.hotelsfound
                  showEmptyResult.value = !items.value.length
                  clearSearchResultInterval()
                  emitSearchFinished()
                  goToItem(index)
                }
              } else {
                items.value = []
                showEmptyResult.value = true
                clearSearchResultInterval()
                emitSearchFinished()
                removeItemFromLocalStorage(localStorageRequestKey)
              }
            } else {
              clearSearchResultInterval()
              showEmptyResult.value = true
              emitSearchFinished()
              removeItemFromLocalStorage(localStorageRequestKey)
            }
          })
          .catch(() => {
            showEmptyResult.value = true
            clearSearchResultInterval()
            emitSearchFinished()
            removeItemFromLocalStorage(localStorageRequestKey)
          })
    }
    const emitSearchFinished = () => emit('search-finished')
    const handlePageChanged = page => {
      currentPage.value = page
      search()
    }
    const handlePerPageCountChanged = count => {
      perPageCount.value = count
      search()
    }

    watch(() => props.search, () => {
      if (props.search) {
        removeItemFromLocalStorage(localStorageRequestKey)
        search()
      }
    })

    onUnmounted(() => {
      removeItemFromLocalStorage(localStorageRequestKey)
      clearSearchResultInterval()
      showEmptyResult.value = true
      showLoader.value = false
      canSearch.value = true
      itemsCount.value = 0
      items.value = []
    })

    return {
      showLoader,
      items,
      itemsCount,
      showEmptyResult,
      currentPage,

      handlePageChanged,
      handlePerPageCountChanged
    }
  },
}
</script>

<style lang='scss' scoped>

.search-form--content {
  &--item {
    &.toolbar {
      margin-top: 40px;
      margin-bottom: 40px;

      .loader-wrapper {
        position: relative;
        height: 30px;
        padding-top: 51px;

        .loader-text {
          font-family: GilroyRegular;
          font-size: 14px;
          line-height: 21px;
          color: #666666;
        }
      }
    }
  }

  @media screen and (max-width: 1679px) {
    grid-column: span 2;
    margin: 0 24px 40px;
  }

  @media screen and (max-width: 767px) {
    margin: 0 10px 40px;
  }

  @media screen and (max-width: 360px) {
    width: calc(100% - 35px);
  }
}

</style>
