<template>
  <div
      v-if="!['auth-vk', 'auth-google'].includes($router.currentRoute.value.name)"
      id="header"
      class="header"
      :class="{
      'small': smallHeaderIsVisible,
      'burger-active': burgerOpened
    }"
  >
    <div class="toolbar flex-row-center-space-between">

      <header-logo-component
          :burger-active="burgerOpened"
          :small-header="smallHeaderIsVisible"
          class="toolbar-item"
      ></header-logo-component>

      <div class="flex-row-center-end w-100">
        <personal-account-component
            :burger-active="burgerOpened"
            :small-header="smallHeaderIsVisible"
            @sign-in-click="burgerOpened = false"
        ></personal-account-component>

        <header-contact-us-component
            v-if="showContactUs"
            :burger-active="burgerOpened"
            :small-header="smallHeaderIsVisible"
            class="toolbar-item"
        ></header-contact-us-component>

        <header-burger-component
            :small-header="smallHeaderIsVisible"
            :burger-opened="burgerOpened"
            @open="burgerOpened = true"
            @close="burgerOpened = false"
        ></header-burger-component>
      </div>
    </div>

    <main-page-search-block
        class="search-block"
        @change-city="handleChangeDepartureCity"
    ></main-page-search-block>

  </div>
</template>

<script>

import PersonalAccountComponent from
      '@/layouts/components/header/personal-account/PersonalAccountComponent.vue'
import {computed, ref} from 'vue'
import {authStore} from '@/stores/authStore'
import HeaderLogoComponent from '@/layouts/components/header/HeaderLogoComponent.vue'
import router from '@/router';
import HeaderBurgerComponent from '@/layouts/components/header/HeaderBurgerComponent.vue'
import HeaderContactUsComponent from '@/layouts/components/header/HeaderContactUsComponent.vue'
import {goToMainPage} from '@/composables/router'
import {getTelegram} from '@/composables/contacts'

export default {
  name: 'HeaderComponent',
  methods: {
    goToMainPage
  },
  components: {
    HeaderContactUsComponent, HeaderBurgerComponent, HeaderLogoComponent, PersonalAccountComponent
  },
  setup() {
    const store = authStore()
    const userIsAuthorized = computed(() => store.userIsAuthorized)
    const smallHeaderIsVisible = computed(() => store.smallHeaderIsVisible)
    const showContactUs = computed(
        () => !store.userIsAuthorized || router.currentRoute.value.name === 'main'
    )
    const burgerOpened = ref(false)
    const departureCity = ref(1)
    const departureCityFrom = ref('Москвы')

    const onClickFindTourWithSpecialist = () => window.open(getTelegram(), '_blank')
    const handleChangeDepartureCity = (cityId, cityNameFrom) => {
      departureCity.value = cityId
      departureCityFrom.value = cityNameFrom
    }

    return {
      userIsAuthorized,
      smallHeaderIsVisible,
      showContactUs,
      burgerOpened,

      onClickFindTourWithSpecialist,
      handleChangeDepartureCity
    }
  }
}
</script>


<style lang='scss' scoped>

@import '@/assets/css/variables';

.header {
  height: 800px;
  background-image: url('@/assets/images/desktop/image.svg');
  background-size: cover;
  background-position: center;
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 240px);

  .toolbar {
    user-select: none;
    position: relative;
    height: 50px;
    top: 30px;
    color: $base-white;

    .toolbar-item {
      height: 100%;

      &.hot-tours {
        height: 44px;
        width: 180px;
        border-radius: 6px;
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 16.8px;
        background: #FFFFFF1F;

        .image {
          margin: 10px 10px 10px 20px;
          width: 24px;
          height: 24px;
          background-image: url('@/assets/images/common/flame.svg');
        }

        .text {
          width: 93px;

          @media (max-width: 1679px) {
            margin-left: 10px;
          }
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      &.find-tour-help-button {
        padding: 0 23px;
        height: 60px;
        border-radius: 6px;
        font-family: GilroySemibold;
        font-size: 14px;
        line-height: 14px;
        color: $base-white;
        border: unset;
        background: linear-gradient(225deg, #FF8500 0%, #FF4800 100%);

        @media (max-width: 1679px) {
          display: none;
        }
      }
    }
  }

  &.small {
    height: 110px;
    background-image: unset;
    background-color: $header-bg-white;
    padding: 0 120px;

    .toolbar {
      .toolbar-item {
        color: $black-text-color;

        &.hot-tours {
          background-color: $base-white;

          @media (max-width: 767px) {
            display: none;
          }
        }

        &.find-tour-help-button {
          color: $base-white;
        }
      }
    }

    .search-block {
      display: none;
    }

    @media (max-width: 1679px) {
      padding: 0 24px;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }

  &.burger-active {

    .toolbar {
      .toolbar-item {
        color: $base-black;

        &.hot-tours {
          background-color: $base-white;
        }
      }
    }
  }

  @media (max-width: 1679px) {
    padding: 0 24px;
    background-image: url('@/assets/images/tablet/image.svg');
    width: calc(100% - 48px);
  }

  @media (max-width: 767px) {
    background-image: url('@/assets/images/mobile/image.svg');
    padding: 0 10px;
    width: calc(100% - 20px);

    &.burger-active {
      background: $base-gray;
      background-image: unset;
    }
  }
}

</style>
