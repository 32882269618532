export const determineRatingColorClass = rating => {
  let colorClass = 'red'
  if (rating) {
    if (rating >= 4.5 && rating <= 5) {
      colorClass = 'green'
    } else if (rating >= 4 && rating <= 4.4) {
      colorClass = 'yellow'
    } else if (rating >= 3.5 && rating <= 3.9) {
      colorClass = 'orange'
    }
  }
  return colorClass
}

export const determineRatingText = rating => {
  let text = ''
  if (rating) {
    if (rating >= 4.5 && rating <= 5) {
      text = 'Супер'
    } else if (rating >= 4 && rating <= 4.4) {
      text = 'Очень хорошо'
    } else if (rating >= 3.5 && rating <= 3.9) {
      text = 'Хорошо'
    }
  }
  return text
}
