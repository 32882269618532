<template>
  <div
    class="icon-dots-wrapper flex-row-center-space-between"
    :class="{
      'active': active,
      'cursor-pointer': cursorPointer
    }"
    :style="{'width': wrapperWidth}"
    @click.stop.prevent="onClick"
  >
    <div
      v-for="index in [1, 2, 3]"
      :key="index"
      class="icon-dots-wrapper--dot"
      :style="{
        'width': `${dotSize}px`,
        'height': `${dotSize}px`,
        'border-radius': `${dotSize / 2}px`,
      }"
    ></div>
  </div>
</template>

<script>

import { ref, watch } from 'vue'

export default {
  name: 'IconDots',
  emits: ['open', 'close'],
  props: {
    wrapperWidth: {
      type: String,
      required: false,
      default: () => '24px'
    },
    dotSize: {
      type: Number,
      required: false,
      default: () => 4
    },
    cursorPointer: Boolean,
    close: Boolean,
  },
  setup(props, { emit }) {
    const active = ref(false)

    const onClick = () => {
      active.value = !active.value
      emit(active.value ? 'open' : 'close')
    }

    watch(() => props.close, () =>  active.value = props.close)

    return {
      active,

      onClick
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.icon-dots-wrapper {
  height: 30px;

  &.active, &:hover {
    .icon-dots-wrapper--dot {
      background: #FF8500;
    }
  }

  &--dot {
    background: #222222;
  }
}

</style>
