<template>
  <div
      id="app-loader-overlay"
      class="overlay loader-overlay"
      :class="isVisible ? 'active' : 'd-none'"
  >
    <animated-loader></animated-loader>
  </div>
</template>

<script>

import AnimatedLoader from '@/components/AnimatedLoader.vue'

export default {
  name: 'AppLoader',
  components: {
    AnimatedLoader
  },
  props: {
    isVisible: Boolean
  },
  setup() {
  },
}
</script>

<style lang='scss' scoped>

.overlay.loader-overlay {
  z-index: 2;
}

</style>
