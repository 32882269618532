<template>
  <div
    class="choose-block"
    :class="{'d-none': !showListItems}"
  >
    <div
      v-for="item in items"
      :key="item.id"
      class="choose-block--item text-left cursor-pointer"
      @click="onSelect(item)"
    >
      <span class="direction-part">{{ direction }}</span>
      <span class="region-part">{{ item.name.substring(direction ? direction.length : 0, item.name.length) }}</span>
    </div>
  </div>
</template>

<script>

import { computed, onMounted, ref, watch } from 'vue'
import { dictionariesStore } from '@/stores/dictionariesStore'

export default {
  name: 'MainPageSearchBlockDirection',
  props: {
    countryId: Number,
    direction: String
  },
  emits: ['select'],
  setup (props, { emit }) {
    const dictionaryStoreInstance = dictionariesStore()
    const countryRegions = computed(() => dictionaryStoreInstance.currentCountryRegions)
    const items = ref([])
    const itemsLength = computed(() => items.value.length)
    const showListItems = ref(false)

    const onSelect = item => {
      showListItems.value = false
      emit('select', item)
    }
    const processCurrentCountryId = (clearDirection = true) => {
      if (props.countryId) {
        if (clearDirection) {
          emit('select', null)
        }
        dictionaryStoreInstance.getRegionsByCountry(props.countryId)
      }
    }
    watch(() => props.direction, async () => {
      if (countryRegions.value.length && props.direction) {
        items.value = countryRegions.value.filter(c => c.name.toLowerCase().startsWith(props.direction.toLowerCase()))
        showListItems.value = !!items.value.length
      }
    })

    watch(() => props.countryId, (newValue, oldValue) => processCurrentCountryId(oldValue !== null))

    onMounted(() => processCurrentCountryId())

    return {
      items,
      itemsLength,
      showListItems,

      onSelect
    }
  },
}
</script>

<style scoped lang='scss'>

@import '@/assets/css/variables';

.choose-block {
  position: absolute !important;
  top: 50px;
  left: -20px;
  padding: 8px 20px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  z-index: 1;

  &--item {
    font-family: GilroySemibold;
    font-size: 16px;
    line-height: 20px;
    color: $base-black;
    margin-bottom: 10px;

    .direction-part {
      text-transform: capitalize;
    }

    .region-part, .country {
      font-family: GilroyRegular;
      line-height: 19px;
    }

    .country {
      margin-left: 6px;
      color: #888888;
    }

    @media screen and (max-width: 1679px) {
      font-size: 14px;
      line-height: 17.15px;
    }
  }

  @media screen and (max-width: 767px) {
    left: 0;
  }
}
</style>
