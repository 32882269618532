<template>
  <div class="advantages flex-row-center-space-between">
    <div
      v-for="(advantage, index) in advantages"
      :key="index"
      class="advantage-item"
    >
      <div class="image-wrapper flex-column-center-center">
        <div
          class="image"
          :class="advantage.imageClass"
        ></div>
      </div>
      <div class="title text-left">{{ advantage.title }}</div>
      <div class="text text-left">{{ advantage.text }}</div>
    </div>
    <div class="advantage-item d-none h-100">
      <div
        class="show-more flex-row-center-center cursor-pointer"
        @click="goToSearchTourPage()"
      >
        <span>Узнать больше</span>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import { goToSearchTourPage } from '@/composables/router'

export default {
  name: 'MainPageAdvantages',
  methods: {
    goToSearchTourPage
  },
  setup() {
    const advantages = ref([
      {
        title: 'Удобный поиск',
        text: 'Интуитивно понятный фильтр дает возможность найти подходящий тур для самых искушенных путешественников',
        imageClass: 'search'
      },
      {
        title: 'Честные цены',
        text: 'Наш сервис отображает реальные цены туроператоров, без каки-либо скрытых доплат и процентов',
        imageClass: 'price'
      },
      {
        title: 'Надежность и гарантии',
        text: 'Мы предлагаем путешественникам только проверенные туроператоры с безупречной репутацией',
        imageClass: 'defense'
      },
    ])

    return {
      advantages
    }
  }
}

</script>

<style lang='scss' scoped>

.advantages {
  margin-top: 100px;
  margin-bottom: 30px;

  .advantage-item {
    .image-wrapper {
      width: 60px;
      height: 60px;
      background: #F8F8F8;
      border-radius: 10px;
      margin-bottom: 20px;

      .image {
        width: 32px;
        height: 32px;

        &.search {
          background-image: url('@/assets/images/common/globe-search.svg');
        }

        &.price {
          background-image: url('@/assets/images/common/valet.svg');
        }

        &.defense {
          background-image: url('@/assets/images/common/defense.svg');
        }
      }
    }

    .title {
      font-family: GilroySemibold;
      font-size: 18px;
      line-height: 27px;
      color: #222222;
      margin-bottom: 7px;
    }

    .text {
      font-family: GilroyRegular;
      font-size: 16px;
      line-height: 24px;
      color: #666666;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    &:last-child {
      @media (max-width: 1679px) {
        display: block;
      }

      .show-more {
        @media (max-width: 1679px) {
          margin-top: 26px;
          border-radius: 80px;
          width: 158px;
          height: 158px;
          border: 1px solid #FF8500;
          font-family: GilroySemibold;
          font-size: 14px;
          line-height: 14px;
        }

        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1679px) {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 71px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-bottom: 70px;
  }
}

</style>
