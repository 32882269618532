<template>
  <div class="tabs flex-row">
    <tour-detailed-card-body-tab
      v-for="tab in tabs"
      :key="tab.type"
      :name="tab.name"
      :type="tab.type"
      :active="tab.active"
      @click="onClickTab(tab)"
    ></tour-detailed-card-body-tab>
  </div>
</template>

<script>

import {ref, watch} from 'vue'
import TourDetailedCardBodyTab from '@/components/tour/tour-detailed-card/tabs/TourDetailedCardBodyTab.vue'

export default {
  name: 'TourDetailedCardBodyTabs',
  emits: ['changed'],
  components: {
    TourDetailedCardBodyTab
  },
  props: {
    activeTab: String
  },
  setup(props, { emit }) {
    const tabs = ref([
      {name: 'Отель', type: 'hotel', active: true},
      {name: 'Фото', type: 'photo', active: false},
      {name: 'Отзывы', type: 'feedback', active: false},
      {name: 'На карте', type: 'map', active: false},
    ])

    const onClickTab = tab => {
      tabs.value.map(t => t.active = t.type === tab.type)
      emit('changed', tab.type)
    }

    watch(() => props.activeTab, () => {
      if (props.activeTab) {
        tabs.value.map(t => t.active = t.type === props.activeTab)
      }
    })
    return {
      tabs,

      onClickTab
    }
  }
}
</script>

<style lang='scss' scoped>

.tabs {
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: grid;
  }
}

</style>
