import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/pages/main-page/MainPage.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import PersonalAccountPage from '@/pages/personal-account-page/PersonalAccountPage.vue'
import SearchTourPage from '@/pages/SearchTourPage.vue'
import SearchHotelPage from '@/pages/SearchHotelPage.vue'
import VKAuthPage from '@/pages/VKAuthPage.vue'
import GoogleAuthPage from '@/pages/GoogleAuthPage.vue'
import { getAuthTokenFromLocalStorage } from '@/composables/auth'
import { authStore } from '@/stores/authStore'
import SharePage from '@/pages/SharePage.vue'
import SearchHotTourPage from '@/pages/SearchHotTourPage.vue'

const routes = [
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'main',
        component: MainPage,
      },
      {
        path: '/account',
        name: 'account',
        component: PersonalAccountPage,
      },
      {
        path: '/search-tour',
        name: 'search-tour',
        component: SearchTourPage,
      },
      {
        path: '/search-hotel',
        name: 'search-hotel',
        component: SearchHotelPage,
      },
      {
        path: '/search-hot-tour',
        name: 'search-hot-tour',
        component: SearchHotTourPage,
      },
      {
        path: '/auth/vk',
        name: 'auth-vk',
        component: VKAuthPage,
      },
      {
        path: '/auth/google',
        name: 'auth-google',
        component: GoogleAuthPage,
      },
      {
        path: '/share',
        name: 'share',
        component: SharePage,
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

router.beforeEach((to, from, next) => {
  const store = authStore()
  const userIsAuthorized = !!getAuthTokenFromLocalStorage()
  let smallHeaderIsVisible = false
  const routesNotRequireAuthorization = [
    'main', 'search-tour', 'search-hotel', 'search-hot-tour', 'auth-vk', 'auth-google', 'share'
  ]
  if (routesNotRequireAuthorization.includes(to.name) || userIsAuthorized) {
    if (to.name !== 'main') {
      smallHeaderIsVisible = true
    }
    next()
  } else {
    next({name: 'main'})
  }
  store.changeSmallHeaderVisibility(smallHeaderIsVisible)
})

export default router
