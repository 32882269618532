<template>
  <div
    class="tabs--tab cursor-pointer"
    :class="{'active': active}"
  >{{ name }}</div>
</template>

<script>

export default {
  name: 'TourDetailedCardBodyTab',
  props: {
    name: String,
    type: String,
    active: Boolean,
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.tabs--tab {
  width: 160px;
  padding: 15px 0;
  background: #F8F8F8;
  border-radius: 50px;
  font-family: GilroyMedium;
  font-size: 14px;
  line-height: 14px;
  color: #666666;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &.active {
    background: #777777;
    color: $base-white;
  }

  @media (max-width: 1679px) {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

</style>
