import router from '@/router'
import {scrollIntoView} from '@/composables/scroll'

export const goToAccountPage = () => {
    router.push({name: 'account'})
}

export const goToMainPage = (scrollToElementId = null) => {
    if (scrollToElementId) {
        if (router.currentRoute.value.name === 'main') {
            router.push({name: 'main'}).then(() => scrollIntoView(scrollToElementId))
        } else {
            router.push({name: 'main', query: {scrollToElementId}})
        }
    } else {
        router.push({name: 'main'})
    }
}

export const goToSearchTourPage = (scrollToElementId = null) => {
    router.push({name: 'search-tour'}).then(() => scrollIntoView(scrollToElementId))
}

export const goToSearchHotelPage = (scrollToElementId = null) => {
    router.push({name: 'search-hotel'}).then(() => scrollIntoView(scrollToElementId))
}

export const goToSearchHotTourPage = (scrollToElementId = null, fromMainPage = false) => {
    if (fromMainPage) {
        router.push({
            name: 'search-hot-tour',
            query: {all: 'true'}
        }).then(() => scrollIntoView(scrollToElementId))
        return
    }
    router.push({name: 'search-hot-tour'}).then(() => scrollIntoView(scrollToElementId))
}
