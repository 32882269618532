<template>
  <div class="preview-card-price">
    {{ formattedPrice }} <span class="currency">{{ currencyIcon }}</span>
  </div>
</template>

<script>

import { computed } from 'vue'
import { formatAmount } from '@/composables/priceFormatter'

export default {
  name: 'PreviewCardPrice',
  components: {},
  props: {
    price: {
      type: Number,
      required: false,
      default: () => null
    },
    currency: String
  },
  setup(props) {
    const formattedPrice = computed(() => formatAmount(props.price))
    const currencyIcon = computed(() => {
      let icon = null
      switch (props.currency) {
        case 'RUB':
          icon = '₽'
          break;
      }
      return icon
    })

    return {
      formattedPrice,
      currencyIcon
    }
  }
}
</script>

<style lang='scss'>

@import '@/assets/css/variables';

.preview-card-price {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 10px 0 7px;
  width: 120px;
  text-align: center;
  color: $base-white;
  background: $base-orange;
  border-top-left-radius: 16px;
  font-family: GilroyBold;
  font-size: 16px;
  line-height: 16px;

  .currency {
    font-family: GilroyRegular;
    font-size: 13px;
    line-height: 1;
  }
}

</style>
