<template>
  <div class="flex-column-start">
    <div class="panel-title">Уведомления</div>
    <div v-if="items.length === 0" class="no-data">Список пуст</div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="notification-wrapper flex-column-start w-100"
    >
      <div class="notification flex-row-start">
        <div class="image flex-row-center-center">
          <div class="image"></div>
        </div>
        <div class="info flex-column-start">
          <div class="notification-title">{{ item.title }}</div>
          <div class="date">{{ item.dateFormat }}</div>
          <div class="text text-left">{{ item.body }}</div>
        </div>
      </div>
      <hr class="w-100">
    </div>
    <div
      v-if="itemsLength"
      class="flex-row-center-center w-100"
    >
      <vue-awesome-paginate
        :total-items="totalItems"
        :items-per-page="itemsPerPage"
        :max-pages-shown="3"
        v-model="currentPage"
        prev-button-content=""
        next-button-content=""
        disabled-back-button-class="d-none"
        disabled-next-button-class="d-none"
        :hide-prev-next-when-ends="true"
        :on-click="getNotifications"
      ></vue-awesome-paginate>
      <show-more-button
        class="d-none"
        @click="getNotifications(currentPage + 1, true)"
      ></show-more-button>
    </div>
  </div>
</template>

<script>

import { VueAwesomePaginate } from 'vue-awesome-paginate'
import {computed, onMounted, ref} from 'vue'
import { commonStore } from '@/stores/commonStore'
import { notificationStore } from '@/stores/notificationStore'
import ShowMoreButton from '@/components/ShowMoreButton.vue'

export default {
  name: 'PersonalAccountPageContentNotification',
  components: {
    ShowMoreButton, VueAwesomePaginate
  },
  setup () {
    const commonStoreInstance = commonStore()
    const notificationStoreInstance = notificationStore()
    const items = ref([])
    const currentPage = ref(1)
    const totalItems = ref(0)
    const itemsPerPage = ref(1)
    const itemsLength = computed(() => items.value.length)

    const getNotifications = (page, append = false) => {
      commonStoreInstance.changeLoaderOverlayVisibility(true)
      notificationStoreInstance.getNotifications(page).then(({ data }) => {
        if (append) {
          items.value.push(...data.data)
        } else {
          items.value = data.data
        }

        currentPage.value = parseInt(data.currentPage)
        totalItems.value = parseInt(data.totalItems)
        itemsPerPage.value = parseInt(data.itemsPerPage)
        commonStoreInstance.changeLoaderOverlayVisibility(false)
      })
    }

    onMounted(() => getNotifications(1))

    return {
      items,
      currentPage,
      totalItems,
      itemsPerPage,
      itemsLength,

      getNotifications,
    }
  }
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.notification-wrapper {
  .notification {
    .image {
      width: 60px;
      min-width: 60px;
      height: 60px;
      background-image: url('@/assets/images/common/ellipse-11-white.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .image {
        width: 30px;
        min-width: 30px;
        height: 30px;
        background-image: url('@/assets/images/common/notification.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .info {
      margin-left: 20px;

      .notification-title {
        font-family: GilroySemibold;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 7px;
        color: #222222;
      }

      .date {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 14px;
        color: #888888;
        margin-bottom: 15px;
      }

      .text {
        font-family: GilroyRegular;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 30px;
        color: #888888;
      }
    }
  }

  hr {
    height: 1px;
    margin: 0 0 30px 0;
    background: #e0e0e0;
    border: 1px #e0e0e0
  }
}

.no-data {
  font-family: GilroyRegular;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 30px;
}

</style>
