export const setItemToLocalStorage = (key, value) => localStorage.setItem(key, value)

export const getItemFromLocalStorage = key => {
  if (key) {
    const item = localStorage.getItem(key)
    return item ? item : null
  }
  return null
}

export const removeItemFromLocalStorage = key => localStorage.removeItem(key)
