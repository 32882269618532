import { defineStore } from 'pinia'

const moduleName = 'payment'

export const paymentStore = defineStore(moduleName, {
  state: () => ({}),
  getters: {},
  actions: {
    payTour(data) {
      return this.$http.post(`${moduleName}`, data)
    },
    payTourByCredit(data) {
      return this.$http.post(`${moduleName}/order-credit/create`, data)
    }
  },
})
