<template>
  <div
      id="search-hot-tour-page"
      class="search-hot-tour-page"
  >
    <div class="search-hot-tour-page--title text-left">Поиск горящих туров</div>
    <search-form-component
        search-category="hot-tours"
        search-button-text="Найти горящие туры"
        class="search-hot-tour-page--form"
    ></search-form-component>
    <!--    <mobile-app class="search-hot-tour-page&#45;&#45;mobile-app"></mobile-app>-->
    <questions-answers class="search-hot-tour-page--questions-answers"></questions-answers>
  </div>
</template>

<script>

export default {
  name: 'SearchHotTourPage',
}

</script>

<style lang='scss' scoped>

@import '@/assets/css/variables';

.search-hot-tour-page {
  display: grid;

  &--title {
    margin: 50px 120px;
    font-family: GilroyBold;
    font-size: 44px;
    line-height: 66px;
    color: $base-black;

    @media screen and (max-width: 1679px) {
      margin: 50px 0 30px 24px;
    }

    @media screen and (max-width: 767px) {
      margin: 30px 10px;
    }
  }

  &--form {
    display: grid;

    @media screen and (max-width: 1679px) {
      margin: 0;
    }
  }

  &--mobile-app {
    margin: 0 120px 150px 120px;

    @media (max-width: 1679px) {
      margin: 0 24px 70px;
    }

    @media (max-width: 767px) {
      margin: 0 10px 70px;
    }
  }

  @media (max-width: 360px) {
    &--questions-answers, &--mobile-app, &--title, &--form {
      width: calc(100% - 35px);
    }
  }
}

</style>
